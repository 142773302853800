import { ResponsiveLine } from "@nivo/line";
import { useTheme } from "@mui/material";
import { tokens } from "../theme";
import { mockLineData as data } from "../data/mockData";

const LineChart = ({ isCustomLineColors = false, isDashboard = false }) => {
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);

	return (
		<ResponsiveLine
			data={data}
			theme={{
				axis: {
					domain: {
						line: {
							stroke: colors.grey[800],
						},
					},
					legend: {
						text: {
							fill: colors.grey[800],
						},
					},
					ticks: {
						line: {
							stroke: colors.grey[800],
							strokeWidth: 1,
						},
						text: {
							fill: colors.grey[800],
						},
					},
				},
				legends: {
					text: {
						fill: colors.grey[800],
					},
				},
				tooltip: {
					container: {
						color: colors.primary[500],
					},
				},
			}}
			// colors={{ scheme: "dark2" }}
			colors={{
				datum: "color",
			}}
			//colors={({ id, data }) => String(data["color"])}
			margin={{ top: 30, right: 110, bottom: 50, left: 60 }}
			xScale={{ type: "point" }}
			yScale={{
				type: "linear",
				min: 0,
				max: 12,
				stacked: false,
				reverse: false,
			}}
			yFormat=" >-.2f"
			// curve="catmullRom"
			axisTop={null}
			axisRight={null}
			axisBottom={{
				orient: "bottom",
				tickSize: 0,
				tickPadding: 5,
				tickRotation: -45,
				legend: isDashboard ? "Estrutura" : "", // added
				legendOffset: 40,
				legendPosition: "end",
			}}
			axisLeft={{
				orient: "left",
				tickValues: 5, // added
				tickSize: 3,
				tickPadding: 5,
				tickRotation: 0,
				legend: isDashboard ? "CV" : "", // added
				legendOffset: -40,
				legendPosition: "middle",
			}}
			enableGridX={false}
			enableGridY={false}
			pointSize={8}
			pointColor={{ theme: "background" }}
			pointBorderWidth={2}
			pointBorderColor={{ from: "serieColor" }}
			pointLabelYOffset={-12}
			useMesh={true}
			legends={[
				{
					anchor: "bottom-right",
					direction: "column",
					justify: false,
					translateX: 100,
					translateY: 0,
					itemsSpacing: 0,
					itemDirection: "left-to-right",
					itemWidth: 80,
					itemHeight: 20,
					itemOpacity: 0.75,
					symbolSize: 12,
					symbolShape: "circle",
					symbolBorderColor: "rgba(0, 0, 0, .5)",
					effects: [
						{
							on: "hover",
							style: {
								itemBackground: "rgba(0, 0, 0, .03)",
								itemOpacity: 1,
							},
						},
					],
				},
			]}
		/>
	);
};

export default LineChart;
