import Base from "./base";
import Ligacao from "./ligacao";
import Rolamento from "./rolamento";
import Subbase from "./subBase";
import ReforcoSubleito from "./reforcoSubleito";
import Subleito from "./subleito";
import { connect } from "react-redux";
import { useEffect } from "react";

const CamadaSelecionada = (props) => {
	const retornaDadosDaCamada = (objeto, camada) => Object.values(objeto).filter((element) => element.camada === camada);

	const retornaIdsDaCamada = (array, camada) => array.find((value) => (value[0] === camada ? value[1] : ""));
	switch (props.camada) {
		case "Camada de Rolamento":
			return (
				<Rolamento
					dadosCamadaUnica={retornaDadosDaCamada(props.dados, "Camada de Rolamento")}
					medicao={props.idmedicao}
					ensaio={props.idensaio}
					dadosIdCamadaMedicaoIdCamada={
						retornaIdsDaCamada(props.idCamadaIdParametro, 1) ||
						retornaDadosDaCamada(props.dados, "Camada de Rolamento")?.[0]?.idcamadaMedicao ||
						[]
					}
					efeito={(e) => props.efeitoAtualizar(e)}
					//dadosIdCamadaMedicaoIdCamada={
					//     retornaIdsDaCamada(props.idCamadaIdParametro, 1) ||
					//     (
					//         Array.isArray(retornaDadosDaCamada(props.dados, "Camada de Rolamento")) &&
					//         retornaDadosDaCamada(props.dados, "Camada de Rolamento").length > 0 ?
					//         retornaDadosDaCamada(props.dados, "Camada de Rolamento")[0].idcamadaMedicao :
					//         []
					//     )
					// }
				/>
			);
		case "Camada de Ligação":
			return (
				<Ligacao
					dadosCamadaUnica={retornaDadosDaCamada(props.dados, "Camada de Ligação")}
					medicao={props.idmedicao}
					ensaio={props.idensaio}
					dadosIdCamadaMedicaoIdCamada={
						retornaIdsDaCamada(props.arrayDados, 2) ||
						retornaDadosDaCamada(props.dados, "Camada de Ligação")?.[0]?.idcamadaMedicao ||
						[]
					}
					efeito={(e) => props.efeitoAtualizar(e)}
				/>
			);
		case "Base":
			return (
				<Base
					dadosCamadaUnica={retornaDadosDaCamada(props.dados, "Base")}
					medicao={props.idmedicao}
					ensaio={props.idensaio}
					dadosIdCamadaMedicaoIdCamada={
						retornaIdsDaCamada(props.arrayDados, 3) || retornaDadosDaCamada(props.dados, "Base")?.[0]?.idcamadaMedicao || []
					}
					efeito={(e) => props.efeitoAtualizar(e)}
				/>
			);
		case "Sub-base":
			return (
				<Subbase
					dadosCamadaUnica={retornaDadosDaCamada(props.dados, "Sub-base")}
					medicao={props.idmedicao}
					ensaio={props.idensaio}
					dadosIdCamadaMedicaoIdCamada={
						retornaIdsDaCamada(props.arrayDados, 4) || retornaDadosDaCamada(props.dados, "Sub-base")?.[0]?.idcamadaMedicao || []
					}
					efeito={(e) => props.efeitoAtualizar(e)}
				/>
			);
		case "Reforço do Subleito":
			return (
				<ReforcoSubleito
					dadosCamadaUnica={retornaDadosDaCamada(props.dados, "Reforço do Subleito")}
					medicao={props.idmedicao}
					ensaio={props.idensaio}
					dadosIdCamadaMedicaoIdCamada={
						retornaIdsDaCamada(props.arrayDados, 5) ||
						retornaDadosDaCamada(props.dados, "Reforço do Subleito")?.[0]?.idcamadaMedicao ||
						[]
					}
					efeito={(e) => props.efeitoAtualizar(e)}
				/>
			);
		case "Subleito":
			return (
				<Subleito
					dadosCamadaUnica={retornaDadosDaCamada(props.dados, "Subleito")}
					medicao={props.idmedicao}
					ensaio={props.idensaio}
					dadosIdCamadaMedicaoIdCamada={
						retornaIdsDaCamada(props.arrayDados, 6) || retornaDadosDaCamada(props.dados, "Subleito")?.[0]?.idcamadaMedicao || []
					}
					efeito={(e) => props.efeitoAtualizar(e)}
				/>
			);
		default:
			break;
	}
};

function mapStateToProps(state) {
	return { camadasSelecionadas: state.camadasSelecionadas, idCamadaIdParametro: state.camadaMedicaoParametro };
}

export default connect(mapStateToProps)(CamadaSelecionada);
