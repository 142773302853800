import { useTheme } from "@mui/material";
import { Circle, MapContainer, TileLayer } from "react-leaflet";
import "../../../node_modules/leaflet/dist/leaflet.css";
import { tokens } from "../../theme";

const Maphead = (props) => {
	// const markers = [
	// 	{
	// 		geoPosition, [],
	// 		popUp:
	// 	},
	// ]

	const theme = useTheme();
	const colors = tokens(theme.palette.mode);

	const color1 = { color: "#008000" };
	const color2 = { color: "#FFFF00" };
	const color3 = { color: "#F24F00" };
	const color4 = { color: "#FF0000" };

	//CE 065
	const c1 = [-3.863566, -38.658015];
	const c2 = [-3.867369, -38.662674];
	const c3 = [-3.872965, -38.667764];
	const c4 = [-3.88017, -38.674535];
	const c5 = [-3.89593, -38.673406];
	const c6 = [-3.908088, -38.679048];
	const c7 = [-3.920082, -38.688198];
	const c32 = [-3.925634, -38.691284];
	const c33 = [-3.932375, -38.693893];
	const c34 = [-3.942995, -38.695498];
	const c35 = [-3.956443, -38.693665];
	const c36 = [-3.964835, -38.69938];
	const c37 = [-3.971613, -38.704665];
	const c38 = [-3.984201, -38.7134];
	const c39 = [-3.991194, -38.719763];
	const c40 = [-3.995052, -38.722748];
	const c41 = [-4.003255, -38.727143];
	const c42 = [-4.0116, -38.729837];
	const c43 = [-4.022066, -38.729695];
	const c44 = [-4.0314, -38.729269];
	const c45 = [-4.040309, -38.73253];
	const c46 = [-4.051765, -38.736358];
	const c47 = [-4.051765, -38.736358];
	const c48 = [-4.068594, -38.758759];
	const c49 = [-4.079907, -38.762445];
	const c50 = [-4.091221, -38.769251];
	const c51 = [-4.103948, -38.779884];
	const c52 = [-4.109039, -38.800016];
	const c53 = [-4.119503, -38.801576];

	// CE 178
	const c10 = [-3.270005, -40.123937];
	const c11 = [-3.270005, -40.123937];
	const c12 = [-3.303313, -40.135208];
	const c13 = [-3.328743, -40.140393];
	const c14 = [-3.356423, -40.149185];
	const c15 = [-3.394369, -40.162679];
	const c16 = [-3.408798, -40.172804];
	const c17 = [-3.408798, -40.172804];
	const c18 = [-3.424993, -40.183477];
	const c19 = [-3.440335, -40.196712];
	const c20 = [-3.46837, -40.211686];
	const c21 = [-3.25154, -40.119936];
	const c22 = [-3.23582, -40.120691];
	const c23 = [-3.224299, -40.111848];
	const c24 = [-3.223653, -40.088769];
	const c25 = [-3.203194, -40.087582];
	const c26 = [-3.178302, -40.086526];
	const c27 = [-3.156836, -40.085505];
	const c28 = [-3.138375, -40.084973];
	const c29 = [-3.122702, -40.084175];
	const c30 = [-3.106099, -40.083244];
	const c31 = [-3.095074, -40.082978];

	//CE 060
	const c54 = [-3.928345, -38.60832];
	const c55 = [-3.937022, -38.608806];
	const c56 = [-3.944201, -38.610042];
	const c57 = [-3.952613, -38.611455];
	const c58 = [-3.955423, -38.611802];
	const c59 = [-3.965923, -38.612415];
	const c60 = [-3.979582, -38.611699];
	const c61 = [-3.992222, -38.616604];
	const c62 = [-4.001294, -38.620385];
	const c63 = [-4.012609, -38.623757];
	const c64 = [-4.022598, -38.627538];
	const c65 = [-4.032997, -38.629762];
	const c66 = [-4.056905, -38.634747];
	const c67 = [-4.078708, -38.643759];
	const c68 = [-4.096495, -38.647594];
	const c69 = [-4.115046, -38.653921];
	const c70 = [-4.129581, -38.665809];
	const c71 = [-4.142011, -38.674245];
	const c72 = [-4.15731, -38.679614];
	const c73 = [-4.167828, -38.692653];
	const c74 = [-4.183318, -38.693995];
	const c75 = [-4.200146, -38.697446];
	const c76 = [-4.220607, -38.696487];
	const c77 = [-4.263752, -38.723305];
	const c78 = [-4.282944, -38.729934];
	const c79 = [-4.296519, -38.735878];
	const c80 = [-4.31105, -38.740863];
	const c81 = [-4.322905, -38.748916];
	const c82 = [-4.33495, -38.759653];
	const c83 = [-4.34623, -38.770583];
	const c84 = [-4.352348, -38.79014];
	const c85 = [-4.363628, -38.802603];
	const c86 = [-4.379879, -38.826954];
	const c87 = [-4.391349, -38.846704];
	const c88 = [-4.407026, -38.856099];
	const c89 = [-4.416829, -38.861551];
	const c90 = [-4.428226, -38.866835];
	const c91 = [-4.436721, -38.876972];
	const c92 = [-4.445, -38.885169];
	const c93 = [-4.458117, -38.895414];
	const c94 = [-4.469407, -38.895953];
	const c95 = [-4.482309, -38.898865];
	const c96 = [-4.491555, -38.899081];
	const c97 = [-4.501231, -38.901993];
	const c98 = [-4.510585, -38.904042];
	const c99 = [-4.521658, -38.904904];
	const c100 = [-4.532302, -38.908248];
	const c101 = [-4.5366, -38.910111];
	const c102 = [-4.539344, -38.911097];
	const c103 = [-4.542676, -38.912499];
	const c104 = [-4.547622, -38.914009];
	const c105 = [-4.552459, -38.915626];
	const c106 = [-4.55805, -38.917567];
	const c107 = [-4.564392, -38.920911];
	const c108 = [-4.572265, -38.918039];
	const c109 = [-4.581751, -38.917219];
	const c110 = [-4.593035, -38.918367];
	const c111 = [-4.60154, -38.920828];
	const c112 = [-4.610044, -38.924602];
	const c113 = [-4.618384, -38.928868];
	const c114 = [-4.62607, -38.934118];
	const c115 = [-4.632448, -38.938548];
	const c116 = [-4.638172, -38.94265];
	const c117 = [-4.644386, -38.94708];
	const c118 = [-4.653053, -38.954299];
	const c119 = [-4.659431, -38.963979];
	const c120 = [-4.666299, -38.973495];
	const c121 = [-4.674475, -38.98137];
	const c122 = [-4.686412, -38.983831];
	const c123 = [-4.696878, -38.985144];
	const c124 = [-4.706972, -38.981854];
	const c125 = [-4.719454, -38.972793];
	const c126 = [-4.728217, -38.979988];
	const c127 = [-4.736716, -38.986117];
	const c128 = [-4.743355, -38.994111];
	const c129 = [-4.756367, -38.999707];
	const c130 = [-4.764599, -39.004237];
	const c131 = [-4.777877, -39.002106];
	const c132 = [-4.789561, -38.99651];
	const c133 = [-4.801245, -38.992246];
	const c134 = [-4.813725, -38.989315];
	const c135 = [-4.826725, -38.994187];
	const c136 = [-4.833587, -39.004859];
	const c137 = [-4.842681, -39.015917];
	const c138 = [-4.849118, -39.028191];
	const c139 = [-4.863065, -39.051878];
	const c140 = [-4.87933, -39.049574];
	const c141 = [-4.893356, -39.04559];
	const c142 = [-4.905529, -39.045059];
	const c143 = [-4.917173, -39.041075];
	const c144 = [-4.929875, -39.037622];
	const c145 = [-4.245811, -38.705025];

	return (
		<>
			<MapContainer center={[-4.433586, -39.536847]} zoom={props.zoom}>
				<TileLayer
					attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
					url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
				></TileLayer>
				{/* CE 065 */}
				<Circle center={c1} pathOptions={color1} radius={600} />
				<Circle center={c2} pathOptions={color1} radius={600} />
				<Circle center={c3} pathOptions={color1} radius={600} />
				<Circle center={c4} pathOptions={color1} radius={600} />
				<Circle center={c5} pathOptions={color1} radius={600} />
				<Circle center={c6} pathOptions={color1} radius={600} />
				<Circle center={c32} pathOptions={color1} radius={1000} />
				<Circle center={c33} pathOptions={color1} radius={1000} />
				<Circle center={c34} pathOptions={color2} radius={1000} />
				<Circle center={c35} pathOptions={color2} radius={1000} />
				<Circle center={c36} pathOptions={color1} radius={1000} />
				<Circle center={c37} pathOptions={color1} radius={1000} />
				<Circle center={c38} pathOptions={color1} radius={1000} />
				<Circle center={c39} pathOptions={color1} radius={1000} />
				<Circle center={c40} pathOptions={color1} radius={1000} />
				<Circle center={c41} pathOptions={color3} radius={1000} />
				<Circle center={c42} pathOptions={color3} radius={1000} />
				<Circle center={c43} pathOptions={color2} radius={1000} />
				<Circle center={c44} pathOptions={color2} radius={1000} />
				<Circle center={c45} pathOptions={color2} radius={1000} />
				<Circle center={c46} pathOptions={color2} radius={1000} />
				<Circle center={c47} pathOptions={color1} radius={1000} />
				<Circle center={c48} pathOptions={color1} radius={1000} />
				<Circle center={c49} pathOptions={color1} radius={1000} />
				<Circle center={c50} pathOptions={color1} radius={1000} />
				<Circle center={c51} pathOptions={color3} radius={1000} />
				<Circle center={c52} pathOptions={color3} radius={1000} />
				<Circle center={c53} pathOptions={color3} radius={1000} />

				{/* CE 178 */}
				<Circle center={c10} pathOptions={color2} radius={1000} />
				<Circle center={c11} pathOptions={color2} radius={1000} />
				<Circle center={c12} pathOptions={color2} radius={1000} />
				<Circle center={c13} pathOptions={color2} radius={1000} />
				<Circle center={c14} pathOptions={color2} radius={1000} />
				<Circle center={c15} pathOptions={color2} radius={1000} />
				<Circle center={c16} pathOptions={color4} radius={1000} />
				<Circle center={c17} pathOptions={color4} radius={1000} />
				<Circle center={c18} pathOptions={color4} radius={1000} />
				<Circle center={c19} pathOptions={color4} radius={1000} />
				<Circle center={c20} pathOptions={color4} radius={1000} />
				<Circle center={c21} pathOptions={color4} radius={1000} />
				<Circle center={c22} pathOptions={color2} radius={1000} />
				<Circle center={c23} pathOptions={color2} radius={1000} />
				<Circle center={c24} pathOptions={color2} radius={1000} />
				<Circle center={c25} pathOptions={color4} radius={1000} />
				<Circle center={c26} pathOptions={color4} radius={1000} />
				<Circle center={c27} pathOptions={color4} radius={1000} />
				<Circle center={c28} pathOptions={color2} radius={1000} />
				<Circle center={c29} pathOptions={color2} radius={1000} />
				<Circle center={c30} pathOptions={color2} radius={1000} />
				<Circle center={c31} pathOptions={color2} radius={1000} />

				<Circle center={c54} pathOptions={color3} radius={1000} />
				<Circle center={c55} pathOptions={color3} radius={1000} />
				<Circle center={c56} pathOptions={color3} radius={1000} />
				<Circle center={c57} pathOptions={color3} radius={1000} />
				<Circle center={c58} pathOptions={color3} radius={1000} />
				<Circle center={c59} pathOptions={color3} radius={1000} />
				<Circle center={c60} pathOptions={color3} radius={1000} />
				<Circle center={c61} pathOptions={color3} radius={1000} />
				<Circle center={c62} pathOptions={color3} radius={1000} />
				<Circle center={c63} pathOptions={color3} radius={1000} />
				<Circle center={c64} pathOptions={color3} radius={1000} />
				<Circle center={c65} pathOptions={color3} radius={1000} />
				<Circle center={c66} pathOptions={color3} radius={1000} />
				<Circle center={c67} pathOptions={color3} radius={1000} />
				<Circle center={c68} pathOptions={color3} radius={1000} />
				<Circle center={c69} pathOptions={color3} radius={1000} />
				<Circle center={c70} pathOptions={color3} radius={1000} />
				<Circle center={c71} pathOptions={color3} radius={1000} />
				<Circle center={c72} pathOptions={color3} radius={1000} />
				<Circle center={c73} pathOptions={color3} radius={1000} />
				<Circle center={c74} pathOptions={color3} radius={1000} />
				<Circle center={c75} pathOptions={color3} radius={1000} />
				<Circle center={c76} pathOptions={color3} radius={1000} />
				<Circle center={c77} pathOptions={color3} radius={1000} />
				<Circle center={c78} pathOptions={color3} radius={1000} />
				<Circle center={c79} pathOptions={color3} radius={1000} />
				<Circle center={c80} pathOptions={color3} radius={1000} />
				<Circle center={c81} pathOptions={color3} radius={1000} />
				<Circle center={c82} pathOptions={color3} radius={1000} />
				<Circle center={c83} pathOptions={color3} radius={1000} />
				<Circle center={c84} pathOptions={color3} radius={1000} />
				<Circle center={c85} pathOptions={color3} radius={1000} />
				<Circle center={c86} pathOptions={color3} radius={1000} />
				<Circle center={c87} pathOptions={color3} radius={1000} />
				<Circle center={c88} pathOptions={color3} radius={1000} />
				<Circle center={c89} pathOptions={color3} radius={1000} />
				<Circle center={c90} pathOptions={color3} radius={1000} />
				<Circle center={c91} pathOptions={color3} radius={1000} />
				<Circle center={c92} pathOptions={color3} radius={1000} />
				<Circle center={c93} pathOptions={color3} radius={1000} />
				<Circle center={c94} pathOptions={color3} radius={1000} />
				<Circle center={c95} pathOptions={color3} radius={1000} />
				<Circle center={c96} pathOptions={color3} radius={1000} />
				<Circle center={c97} pathOptions={color3} radius={1000} />
				<Circle center={c98} pathOptions={color2} radius={1000} />
				<Circle center={c99} pathOptions={color2} radius={1000} />
				<Circle center={c100} pathOptions={color2} radius={1000} />
				<Circle center={c101} pathOptions={color2} radius={1000} />
				<Circle center={c102} pathOptions={color2} radius={1000} />
				<Circle center={c103} pathOptions={color2} radius={1000} />
				<Circle center={c104} pathOptions={color2} radius={1000} />
				<Circle center={c105} pathOptions={color2} radius={1000} />
				<Circle center={c106} pathOptions={color3} radius={1000} />
				<Circle center={c107} pathOptions={color3} radius={1000} />
				<Circle center={c108} pathOptions={color3} radius={1000} />
				<Circle center={c109} pathOptions={color3} radius={1000} />
				<Circle center={c110} pathOptions={color3} radius={1000} />
				<Circle center={c111} pathOptions={color3} radius={1000} />
				<Circle center={c112} pathOptions={color3} radius={1000} />
				<Circle center={c113} pathOptions={color3} radius={1000} />
				<Circle center={c114} pathOptions={color3} radius={1000} />
				<Circle center={c115} pathOptions={color3} radius={1000} />
				<Circle center={c116} pathOptions={color3} radius={1000} />
				<Circle center={c117} pathOptions={color3} radius={1000} />
				<Circle center={c118} pathOptions={color3} radius={1000} />
				<Circle center={c119} pathOptions={color3} radius={1000} />
				<Circle center={c120} pathOptions={color3} radius={1000} />
				<Circle center={c121} pathOptions={color3} radius={1000} />
				<Circle center={c122} pathOptions={color3} radius={1000} />
				<Circle center={c123} pathOptions={color1} radius={1000} />
				<Circle center={c124} pathOptions={color1} radius={1000} />
				<Circle center={c125} pathOptions={color1} radius={1000} />
				<Circle center={c126} pathOptions={color1} radius={1000} />
				<Circle center={c127} pathOptions={color1} radius={1000} />
				<Circle center={c128} pathOptions={color1} radius={1000} />
				<Circle center={c129} pathOptions={color1} radius={1000} />
				<Circle center={c130} pathOptions={color1} radius={1000} />
				<Circle center={c131} pathOptions={color1} radius={1000} />
				<Circle center={c132} pathOptions={color1} radius={1000} />
				<Circle center={c133} pathOptions={color1} radius={1000} />
				<Circle center={c134} pathOptions={color1} radius={1000} />
				<Circle center={c135} pathOptions={color1} radius={1000} />
				<Circle center={c136} pathOptions={color3} radius={1000} />
				<Circle center={c137} pathOptions={color3} radius={1000} />
				<Circle center={c138} pathOptions={color3} radius={1000} />
				<Circle center={c139} pathOptions={color3} radius={1000} />
				<Circle center={c140} pathOptions={color3} radius={1000} />
				<Circle center={c141} pathOptions={color3} radius={1000} />
				<Circle center={c142} pathOptions={color3} radius={1000} />
				<Circle center={c143} pathOptions={color3} radius={1000} />
				<Circle center={c144} pathOptions={color3} radius={1000} />
				<Circle center={c145} pathOptions={color3} radius={1000} />
				{/* <Circle center={c146} pathOptions={color3} radius={1000} />
				<Circle center={c147} pathOptions={color3} radius={1000} />
				<Circle center={c148} pathOptions={color3} radius={1000} />
				<Circle center={c149} pathOptions={color3} radius={1000} />
				<Circle center={c150} pathOptions={color3} radius={1000} /> */}
			</MapContainer>
		</>
	);
};

export default Maphead;
