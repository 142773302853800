import { INFO_MEDICAO_ALTERADO } from "../actions/actionTypes";

const initialState = { teste: "Teste" };

function infoMedicaoReducer(state = initialState, action) {
	switch (action.type) {
		case INFO_MEDICAO_ALTERADO:
			state = action.payload;
			return state;
		default:
			return state;
	}
}

export default infoMedicaoReducer;
