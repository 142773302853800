import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useState, useEffect } from "react";
import "../../../node_modules/dayjs/locale/pt-br";
import Header from "../../components/Header";
import { tokens } from "../../theme";
import ModalMedicoes from "./modal-medicoes";
import axios from "axios";
import dayjs from "dayjs";
import ModalProjetos from "../../components/ModalProjetos";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

const Projetos = () => {
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);
	const [openNewProject, setOpenNewProject] = useState(false);
	const [listaProjetos, setListaProjetos] = useState([]);
	const [modalData, setModalData] = useState(null);
	//const label = { inputProps: { "aria-label": "Switch demo" } };
	const [openMesure, setOpenMesure] = useState(false);

	//Eventos dos estados
	const handleOpenNewProject = () => setOpenNewProject(true);
	//const handleOpenNewProject = () => <ModalProjetos />; //Abre modal novo projeto
	const handleCloseNewProject = () => setOpenNewProject(false); //Fecha modal novo projeto
	const handleClose = () => setOpenMesure(false); //Fecha modal das medições'

	useEffect(() => {
		fetchListaProjetos();
	}, []);

	async function fetchListaProjetos() {
		await axios.get(`${process.env.REACT_APP_URL_BASE}/projetos`).then((response) => setListaProjetos(response.data));
	}

	const addInfoListProjetos = (event) => listaProjetos.push(event);

	const [openSnackBar, setOpenSnackBar] = useState(false);

	const handleClickSnackBar = () => {
		setOpenSnackBar(true);
	};

	const handleCloseSnackBar = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}
		setOpenSnackBar(false);
	};

	//const ultimoIdInserido = (event) => console.log(event);

	return (
		<>
			<Box m="20px">
				<Header title="Projetos" />
				<Box display="flex" justifyContent="right" mb={2}>
					<div>
						<Button
							onClick={handleOpenNewProject}
							color="primary"
							variant="outlined"
							startIcon={<AddCircleOutlineOutlinedIcon />}
						>
							Novo Projeto
						</Button>
					</div>
				</Box>
				<TableContainer component={Paper} sx={{ maxHeight: "75vh" }}>
					<Table aria-label="simple table" size="small" stickyHeader>
						<TableHead>
							<TableRow
								sx={{
									backgroundColor: colors.grey[200],
								}}
							>
								<TableCell>
									<Typography variant="h5" fontWeight="600" color={colors.grey[900]}>
										Nome
									</Typography>
								</TableCell>
								<TableCell align="right">
									<Typography variant="h5" fontWeight="600" color={colors.grey[900]}>
										Data Inicial
									</Typography>
								</TableCell>
								<TableCell align="right">
									<Typography variant="h5" fontWeight="600" color={colors.grey[900]}>
										Prazo Final
									</Typography>
								</TableCell>
								<TableCell align="right">
									<Typography variant="h5" fontWeight="600" color={colors.grey[900]}>
										Atraso (dias)
									</Typography>
								</TableCell>
								<TableCell align="right">
									<Typography variant="h5" fontWeight="600" color={colors.grey[900]}>
										Valor Contrato
									</Typography>
								</TableCell>
								<TableCell align="right">
									<Typography variant="h5" fontWeight="600" color={colors.grey[900]}>
										Valor Aditivo
									</Typography>
								</TableCell>
								<TableCell align="right">
									<Typography variant="h5" fontWeight="600" color={colors.grey[900]}></Typography>
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody p={0}>
							{listaProjetos.map((value) => (
								<TableRow
									key={value.idprojeto}
									sx={{
										"&:last-child td, &:last-child th": {
											border: 0,
										},
										backgroundColor: colors.grey[100],
									}}
								>
									<TableCell component="th" scope="row">
										<Typography variant="h6" fontWeight="500" color={colors.grey[700]}>
											{value.nomeProjeto}
										</Typography>
									</TableCell>
									<TableCell align="right">
										<Typography variant="h6" fontWeight="500" color={colors.grey[700]}>
											{dayjs(value.dataInicialPrevia).format("DD/MM/YYYY")}
										</Typography>
									</TableCell>
									<TableCell align="right">
										<Typography variant="h6" fontWeight="500" color={colors.grey[700]}>
											{dayjs(value.dataFinalPrevia).format("DD/MM/YYYY")}
										</Typography>
									</TableCell>
									<TableCell align="right">
										<Typography variant="h6" fontWeight="500" color={colors.grey[700]}>
											{}
										</Typography>
									</TableCell>
									<TableCell align="right">
										<Typography variant="h6" fontWeight="500" color={colors.grey[700]}>
											R$ {new Intl.NumberFormat("pt-BR").format(value.valorContrato)}
										</Typography>
									</TableCell>
									<TableCell align="right">
										<Typography variant="h6" fontWeight="500" color={colors.grey[700]}>
											R$ {new Intl.NumberFormat("pt-BR").format(value.valorAditivo)}
										</Typography>
									</TableCell>
									<TableCell align="right">
										<Typography variant="h6" fontWeight="500" color={colors.grey[700]}>
											<Box display="flex" justifyContent="center">
												<IconButton
													onClick={() => {
														setModalData(value.idprojeto);
														setOpenMesure(true);
													}}
												>
													<FolderOpenIcon color="primary" />
												</IconButton>
												<IconButton>
													<EditIcon color="primary" />
												</IconButton>
												<IconButton>
													<DeleteIcon color="primary" />
												</IconButton>
											</Box>
										</Typography>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
				<Modal open={openMesure} onClose={handleClose}>
					<ModalMedicoes idprojeto={modalData} />
				</Modal>
				<Modal open={openNewProject} onClose={handleCloseNewProject}>
					<ModalProjetos
						onClose={handleCloseNewProject}
						fetchListaProjetos={fetchListaProjetos}
						setProjetos={addInfoListProjetos}
						listaProjetos={listaProjetos}
						handleClickSnackBar={handleClickSnackBar}
						//retornaIdProjeto={ultimoIdInserido}
					/>
				</Modal>
			</Box>
			<Snackbar
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
				open={openSnackBar}
				autoHideDuration={1500}
				onClose={handleCloseSnackBar}
			>
				<Alert onClose={handleCloseSnackBar} severity="success" sx={{ width: "100%" }}>
					Projeto cadastrado!
				</Alert>
			</Snackbar>
		</>
	);
};

export default Projetos;
