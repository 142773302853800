import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import SaveIcon from "@mui/icons-material/Save";
import { Button, Grid, TextField, Typography, Box } from "@mui/material";
import MultipleSelectCheckmarksPeneiras from "../components/MultipleSelectCheckmarksPeneiras";
import VPLILS from "./VPLILS";
import { useEffect, useState } from "react";
import Divider from "@mui/material/Divider";
import {
	preencherArrayDosValoresDeEntrada,
	retornaArrayComValoresEQuantidadeDeAmostras,
	montaArrayComUmaGranulometria,
	retornaIdDaPeneira,
} from "../aux/functions/functions";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import axios from "axios";

const listaTodasAsPeneiras = [
	{ id: 1, nome: "Peneira ASTM 2” (50,8 mm)" },
	{ id: 2, nome: "Peneira ASTM 1 1/2” (38,1 mm)" },
	{ id: 3, nome: "Peneira ASTM 1” (25,4 mm)" },
	{ id: 4, nome: 'Peneira ASTM 3/4" (19,1 mm)' },
	{ id: 5, nome: 'Peneira ASTM 1/2" (12,7 mm)' },
	{ id: 6, nome: "Peneira ASTM 3/8” (9,5 mm)" },
	{ id: 7, nome: 'Peneira ASTM 1/4" (6,3 mm)' },
	{ id: 8, nome: "Peneira ASTM N° 4 (4,75 mm)" },
	{ id: 9, nome: "Peneira ASTM N° 8 (2,36 mm)" },
	{ id: 10, nome: "Peneira ASTM N° 10 (2,0 mm)" },
	{ id: 11, nome: "Peneira ASTM N° 16 (1,18 mm)" },
	{ id: 12, nome: "Peneira ASTM N° 30 (0,6 mm)" },
	{ id: 13, nome: "Peneira ASTM N° 40 (0,425 mm)" },
	{ id: 14, nome: "Peneira ASTM N° 50 (0,33 mm)" },
	{ id: 15, nome: "Peneira ASTM N° 80 (0,18 mm)" },
	{ id: 16, nome: "Peneira ASTM N° 100 (0,15 mm)" },
	{ id: 17, nome: "Peneira ASTM N° 200 (0,075 mm)" },
];

const AccordionGranulometria = (props) => {
	const [rowsData, setRows] = useState(() => []);
	const [alertaSnackbar, setAlertaSnackbar] = useState();
	const [camposVazios, setCamposVazios] = useState(false);
	const [arrayPeneiras, setArrayPeneiras] = useState([]);

	// const handleChange = (panel) => (event, isExpanded) => {
	// 	setExpanded(isExpanded ? panel : false);
	// 	props.fechaAccordion();
	// };

	const [openSnackBar, setOpenSnackBar] = useState(false);

	const handleClickSnackBar = () => {
		setOpenSnackBar(true);
	};

	const handleCloseSnackBar = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}
		setOpenSnackBar(false);
	};

	function verificaVPLILS(value) {
		if (camposVazios) {
			setAlertaSnackbar("Preencher campos vazios");
			handleClickSnackBar();
			setCamposVazios(false);
		} else {
			props.handleVisibleGranulometria({
				valoresEntrada: value.valoresEntrada,
				numeroAmostras: value.numeroAmostras,
			});
		}
	}

	// useEffect(() => {
	// 	setArrayPeneiras(() => {
	// 		props.dados.forEach((value) => {
	// 			if (value.nomePeneira !== null) {
	// 				arrayPeneiras.push(value.nomePeneira);
	// 			}
	// 		});
	// 	});
	// }, [arrayPeneiras]);

	function handlePeneirasAccordionGranulometria() {
		//const arrayIdPeneiras = arrayPeneiras.map((value) => listaTodasAsPeneiras.find((e) => value === e.nome).id).sort((a, b) => a - b);
		//const qualipav_camadamedicao_idcamadaMedicao = props.dados[0].idcamadaMedicao;

		props.handlePeneirasAccordionGeral(arrayPeneiras);
		// props.atualizaAccordionGeral(true);
	}

	function retornaArrayDePeneiras() {
		let array = [];
		props.dados.forEach((value) => {
			if (value.nomePeneira !== null) {
				array.push(value.nomePeneira);
			}
		});
		return array;
	}

	// async function cadastraPeneiras() {
	// 	try {
	// 		await axios.post(`${process.env.REACT_APP_URL_BASE}/peneiras`, { params: {} }).then((response) => {});
	// 	} catch (error) {
	// 		console.log("Erro ao adicionar uma medição:", error);
	// 	}
	// }

	return (
		<>
			<Grid item xs={12} lg={7} sx={{ width: "100%" }}>
				<MultipleSelectCheckmarksPeneiras
					name={"Peneiras"}
					values={listaTodasAsPeneiras}
					width="42em"
					height="3.85em"
					valuesSelect={retornaArrayDePeneiras}
					valoresPeneira={(e) => setArrayPeneiras(e)}
				/>
			</Grid>
			<Grid item xs={12} lg={0.5} display="flex" justifyContent="end" alignItems="center">
				<IconButton color="primary" variant="outlined" onClick={handlePeneirasAccordionGranulometria}>
					<SaveIcon />
				</IconButton>
			</Grid>
			<Grid item mt={2} xs={12} lg={12}>
				<Divider />
				<Box sx={{ width: "100%", maxHeight: "40vh", overflow: "auto" }} p={1}>
					{retornaArrayDePeneiras().length !== 0 &&
						props.dados
							.filter((e) => e.nomePeneira !== null)
							.map(
								(value) =>
									value.nomePeneira !== null && (
										<Grid container my={1} spacing={1}>
											<Grid item xs={12} lg={4.5} display="flex" justifyContent="end" alignItems="center">
												<Typography variant="h6" fontWeight="700">
													{value.nomePeneira}
												</Typography>
											</Grid>
											<VPLILS
												//onVisible={(e) => verificaVPLILS(e)}
												onVisible={(objeto) =>
													props.handleVisibleGranulometria({
														valoresEntrada: objeto.valoresEntrada,
														trechoEntrada: objeto.trechoEntrada,
														dataEntrada: objeto.dataEntrada,
														numeroAmostras: objeto.numeroAmostras,
														parametro: value.nomePeneira,
														valorDoProjeto: objeto.valorDoProjeto,
														limiteInferior: objeto.limiteInferior,
														limiteSuperior: objeto.limiteSuperior,
														idPeneira: retornaIdDaPeneira(value.nomePeneira),
														idparametroValores: value.idparametroValores,
													})
												}
												idparametroValores={value.idparametroValores}
												salvaInformacoes={(objeto) =>
													props.handleSalvaInformacoes({
														valoresEntrada: objeto.valoresEntrada,
														numeroAmostras: objeto.numeroAmostras,
														parametro: value.nomePeneira,
														valorDoProjeto: objeto.valorDoProjeto,
														limiteInferior: objeto.limiteInferior,
														limiteSuperior: objeto.limiteSuperior,
														idPeneira: retornaIdDaPeneira(value.nomePeneira),
														nomePeneira: value.nomePeneira,
													})
												}
												parametro={value.parametro.replace(/[^A-Z0-9]+/gi, "_")}
												vp={value.valorProjeto}
												li={value.limiteInferior}
												ls={value.limiteSuperior}
												quantidadeDeAmostras={value.quantidadeDeAmostras}
												//valoresDeEntrada={preencherArrayDosValoresDeEntrada(value.valorEntrada, rowsData)}
												valoresDeEntrada={value.valorEntrada}
												trechoEntrada={value.trechoEntrada}
												dataEntrada={value.dataEntrada}
												// setNumeroDeAmostras={setNumeroDeAmostras}
												// setValorDoProjeto={setValorDoProjeto}
												// setLimiteInferior={setLimiteInferior}
												// setLimiteSuperior={setLimiteSuperior}
												//verificaCamposVazios={(e) => setCamposVazios(e)}
											/>
										</Grid>
									)
							)}
				</Box>
			</Grid>
			<Snackbar
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
				open={openSnackBar}
				autoHideDuration={1500}
				onClose={handleCloseSnackBar}
			>
				<Alert onClose={handleCloseSnackBar} severity="warning" sx={{ width: "100%" }}>
					{alertaSnackbar}
				</Alert>
			</Snackbar>
		</>
	);
};

export default AccordionGranulometria;
