const listaTodasAsPeneiras = [
	{ id: 1, nome: "Peneira ASTM 2” (50,8 mm)" },
	{ id: 2, nome: "Peneira ASTM 1 1/2” (38,1 mm)" },
	{ id: 3, nome: "Peneira ASTM 1” (25,4 mm)" },
	{ id: 4, nome: 'Peneira ASTM 3/4" (19,1 mm)' },
	{ id: 5, nome: 'Peneira ASTM 1/2" (12,7 mm)' },
	{ id: 6, nome: "Peneira ASTM 3/8” (9,5 mm)" },
	{ id: 7, nome: 'Peneira ASTM 1/4" (6,3 mm)' },
	{ id: 8, nome: "Peneira ASTM N° 4 (4,75 mm)" },
	{ id: 9, nome: "Peneira ASTM N° 8 (2,36 mm)" },
	{ id: 10, nome: "Peneira ASTM N° 10 (2,0 mm)" },
	{ id: 11, nome: "Peneira ASTM N° 16 (1,18 mm)" },
	{ id: 12, nome: "Peneira ASTM N° 30 (0,6 mm)" },
	{ id: 13, nome: "Peneira ASTM N° 40 (0,425 mm)" },
	{ id: 14, nome: "Peneira ASTM N° 50 (0,33 mm)" },
	{ id: 15, nome: "Peneira ASTM N° 80 (0,18 mm)" },
	{ id: 16, nome: "Peneira ASTM N° 100 (0,15 mm)" },
	{ id: 17, nome: "Peneira ASTM N° 200 (0,075 mm)" },
];

export function preencherArrayDosValoresDeEntrada(array, rows) {
	if (array === null) {
		return 0;
	} else {
		rows = [];
		let a = array.split(",");
		//console.log(a);
		for (let i = 0; i < a.length; i++) {
			if (i % 2 !== 0) {
				rows.push({ id: i + 1, data: "", trecho: "", valor: a[i], idBanco: a[i - 1] });
			}
		}
		return rows;
	}
}

export function retornaArrayComValoresEQuantidadeDeAmostras(values, qte) {
	let array = [];
	for (let i = 0; i < qte; i++) {
		array[i] = { id: i + 1, data: "", trecho: "", valor: parseFloat(values[i]) };
		// if (typeof values[i] != "undefined") {
		// 	array[i] = { id: i + 1, data: "", trecho: "", valor: parseFloat(values[i]) };
		// } else {
		// 	array[i] = { id: i + 1, data: "", trecho: "", valor: null };
		// }
	}
	return array;
}

// export function montaArrayComUmaGranulometria(array) {
// 	let arrayInput = [];
// 	let countGranulometria = true;
// 	array.forEach((value) => {
// 		if (value.parametro === "Granulometria" && countGranulometria === true && value.nomeSegmento !== null) {
// 			countGranulometria = false;
// 			arrayInput.push(value);
// 		} else if (value.parametro !== "Granulometria") {
// 			arrayInput.push(value);
// 		}
// 	});
// 	return arrayInput;
// }

export function montaArrayComUmaGranulometria(array) {
	let arrayInput = [];
	let countGranulometria = true;
	array.forEach((value) => {
		if (value.parametro === "Granulometria" && countGranulometria === true) {
			countGranulometria = false;
			arrayInput.push(value);
		} else if (value.parametro !== "Granulometria") {
			arrayInput.push(value);
		}
	});
	return arrayInput;
}

export function montaArrayPeneiras(array, id, value) {
	let arrayOutput = [];
	array.map((e) => arrayOutput.push("('" + value + "'," + e + "," + id + ",1)"));
	return arrayOutput;
}

export function montaArrayPeneirasDB(array, id, value) {
	let arrayOutput = [];
	array.map((e) => arrayOutput.push([value, e, id, 1]));
	return arrayOutput;
}

export function retornaIdDaPeneira(value) {
	return listaTodasAsPeneiras.find((e) => value === e.nome).id;
}

export function hasNullOrEmpty(target, excepetions) {
	for (var member in target) {
		if (excepetions.includes(member)) {
		} else {
			if (target[member] == null || target[member] === "0" || target[member] === "") return true;
		}
	}
	return false;
}

export function hasEmptyOrZeroOrUndefined(target, excepetions) {
	for (var member in target) {
		if (excepetions.includes(member)) {
		} else {
			if (target[member] === null || target[member] === "0" || target[member] === undefined || isNaN(target[member])) return true;
		}
	}
	return false;
}

export function arrayTrechoDataValorEntrada(trecho, data, valoresEntrada, numeroAmostras) {
	let arrayTrecho = trecho.split(",");
	let arrayData = data.split(",");
	let arrayValorEntrada = valoresEntrada.split(",");
	let arrayOutput = [];

	for (let index = 0; index < numeroAmostras; index++) {
		arrayOutput.push({
			id: index + 1,
			data: arrayData[index] === "0000-00-00" ? null : arrayData[index],
			trecho: arrayTrecho[index] === "" || arrayTrecho[index] === "null" ? "" : arrayTrecho[index],
			valor: parseFloat(arrayValorEntrada[index] ?? null),
		});
	}

	return arrayOutput;
}
