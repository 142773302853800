import { Box } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import MultipleSelectCheckmarksCamadas from "../scenes/projetos/checkmarks-camadas";
import { connect } from "react-redux";
import AddCircleOutlineOutlined from "@mui/icons-material/AddCircleOutlineOutlined";
import { alterarArrayCamadas } from "../store/actions/camadas";
import { alterarCamadaMedicaoParametro } from "../store/actions/camadaMedicaoParametro";
import axios from "axios";
import { estrutura } from "../data/sistemaPavimentacao/estrutura-material-parametro";
import Alert from "@mui/material/Alert";
import { useState, useEffect } from "react";
import Snackbar from "@mui/material/Snackbar";

function DialogCamadas(props) {
	let camadasComponenteFilho;

	const [openSnackBar, setOpenSnackBar] = useState(false);

	const handleClickSnackBar = () => {
		setOpenSnackBar(true);
	};

	const handleCloseSnackBar = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}
		setOpenSnackBar(false);
	};

	async function fetchCadastraCamadasDaMedicao(inserir, excluir) {
		let response = await axios.post(`${process.env.REACT_APP_URL_BASE}/sistemapavimentacao/camadasmedicao`, { inserir, excluir });

		if (response.statusText === "OK") {
			handleClickSnackBar();
		}
		//conversão do resultado para o formtado de array com os valores do id_camadaMedicao  e id_camada
		let b = response.data[2].map((value) => value.split(",")[1]);
		let c = b.map((value) => value.replace(/[&/\\#,+()$~%.'":*?<>{}]/g, ""));
		let d = [];
		for (let index = 0; index < c.length; index++) {
			d.push([parseInt(c[index]), response.data[0] + index]);
		}

		props.cmp(d);

		//console.log(d);

		//props.alterarIdCamadaParametro(d);

		return d;
		//props.arrayIdCamdaMedicaoIdCamda(d);
		// if (!d.length === 0) {
		// 	props.alterarIdCamadaParametro(d);
		// }
	}

	async function defineCamadasSelecionadas() {
		props.alteraArrayCamada(camadasComponenteFilho);

		let arrayCamadasAntigo = props.camadasSelecionadas;
		let arrayCamadasNovo = camadasComponenteFilho;
		let arrayCamadasCompleto = props.camadas;
		let arrayInseriParenteses = [];
		let arrayExcluiParenteses = [];

		//constroi o array no padrão js
		let arrayCamadasInserir = arrayCamadasNovo.filter((value) => !arrayCamadasAntigo.includes(value));

		//constroi o array no padrão js
		let arrayCamadasExcluir = arrayCamadasCompleto
			.filter((value) => !arrayCamadasNovo.includes(value))
			.filter((value) => arrayCamadasAntigo.includes(value));

		//constroi o array no padrão sql com ()
		let arrayInseri = arrayCamadasInserir.map((value) => [parseInt(props.idensaio), estrutura.indexOf(`${value}`)]);
		arrayInseri.forEach((element) => {
			arrayInseriParenteses.push("(" + element[0] + "," + element[1] + ")");
		});

		//constroi o array no padrão sql com ()
		let arrayExclui = arrayCamadasExcluir.map((value) => [parseInt(props.idensaio), estrutura.indexOf(`${value}`)]);
		arrayExclui.forEach((element) => {
			arrayExcluiParenteses.push("(" + element[0], element[1] + ")");
		});

		//console.log({ Inserir: arrayCamadasInserir }, "\n", { Excluir: arrayCamadasExcluir });

		props.alterarIdCamadaParametro(await fetchCadastraCamadasDaMedicao(arrayInseriParenteses, arrayExcluiParenteses));

		props.onClose();

		props.efeitoAtualizar(true);
	}

	return (
		<div>
			<Dialog open={props.open} onClose={props.onClose}>
				<Box display="flex" justifyContent="center" alignItems="center" m={1}>
					<DialogContent>
						<MultipleSelectCheckmarksCamadas
							name={"Camadas"}
							values={props.camadas}
							width={180}
							getFromChild={(e) => (camadasComponenteFilho = e)}
							valuesSelect={props.camadasSelecionadas}
						/>
					</DialogContent>
					<Button
						color="primary"
						variant="outlined"
						startIcon={<AddCircleOutlineOutlined />}
						onClick={defineCamadasSelecionadas}
					></Button>
				</Box>
			</Dialog>
			<Snackbar
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
				open={openSnackBar}
				autoHideDuration={1500}
				onClose={handleCloseSnackBar}
			>
				<Alert onClose={handleCloseSnackBar} severity="success" sx={{ width: "100%" }}>
					Salvo!
				</Alert>
			</Snackbar>
		</div>
	);
}

function mapStateToProps(state) {
	return { camadasSelecionadas: state.camadasSelecionadas, idCamadaIdParametro: state.camadaMedicaoParametro };
}

function mapDispatchToProps(dispatch) {
	return {
		alteraArrayCamada(array) {
			dispatch(alterarArrayCamadas(array));
		},
		alterarIdCamadaParametro(array) {
			dispatch(alterarCamadaMedicaoParametro(array));
		},
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(DialogCamadas);
