import { Box, Typography, useTheme, Grid } from "@mui/material";
import { tokens } from "../theme";

const Header = ({ title, subtitle, secondsubtitle, thirdsubtitle }) => {
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);
	return (
		<Box mb="10px">
			<Grid container spacing={2}>
				<Grid item xs="auto" sx={{ display: "flex", alignItems: "center", m: "0 0 5px 0" }}>
					<Typography variant="h2" color={colors.grey[900]} fontWeight="bold">
						{title}
					</Typography>
				</Grid>
			</Grid>
			<Grid>
				<Grid item xs="auto" sx={{ display: "inline-flex", alignItems: "center" }}>
					<Typography variant="h4" color={colors.grey[900]} fontWeight="bold">
						{subtitle && subtitle + " - "}
					</Typography>
				</Grid>
				<Grid item xs="auto" sx={{ display: "inline-flex", alignItems: "center", m: "0 0 0 0.5em" }}>
					<Typography variant="h4" color={colors.grey[900]}>
						{secondsubtitle && secondsubtitle} {thirdsubtitle && "(" + thirdsubtitle + ")"}
					</Typography>
				</Grid>
			</Grid>
		</Box>
	);
};

export default Header;
