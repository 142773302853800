import { Box, Chip } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import React, { useState } from "react";
import { connect } from "react-redux";

const MultipleSelectCheckmarksCamadas = (props) => {
	const [nomeCamada, setNomeCamada] = useState(props.valuesSelect);

	const handleChange = (event) => {
		const {
			target: { value },
		} = event;
		setNomeCamada(typeof value === "string" ? value.split(",") : value);
	};

	if (typeof props.getFromChild === "function") {
		props.getFromChild(nomeCamada);
	}

	return (
		<div>
			<FormControl size="small">
				<InputLabel id="multi-select-camadas">{props.name}</InputLabel>
				<Select
					labelId="multi-select-camadas"
					id="multi-select-camadas"
					multiple
					value={nomeCamada}
					onChange={handleChange}
					input={<OutlinedInput label={props.name} />}
					sx={{
						width: props.width,
						minHeight: props.height,
					}}
					renderValue={(selected) => (
						<Box
							sx={{
								display: "flex",
								margin: 0,
								padding: 0,
								gap: 1,
								flexWrap: "wrap",
							}}
						>
							{selected.map((value) => (
								<Chip key={value} label={value} size="small" />
							))}
						</Box>
					)}
				>
					{props.values.map((name, index) => (
						<MenuItem key={index} value={name}>
							{name}
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</div>
	);
};

function mapStateToProps(state) {
	return { camadasSelecionadas: state.camadasSelecionadas };
}

export default connect(mapStateToProps)(MultipleSelectCheckmarksCamadas);
