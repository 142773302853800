import { Box } from "@mui/material";

import Header from "../../components/Header";

const Medicoes = () => {
	// const theme = useTheme();

	return (
		<Box m="20px" variant="h6">
			<Header title="Medições" subtitle="Lista das medições" />
			{/* <Box
				m="40px 0 0 0"
				height="75vh"
				sx={{
					"& .MuiDataGrid-root": {
						border: "none",
					},
					"& .MuiDataGrid-cell": {
						borderBottom: "none",
					},
					"& .name-column--cell": {
						color: colors.greenAccent[300],
					},
					"& .MuiDataGrid-columnHeaders": {
						backgroundColor: colors.primary[700],
						borderBottom: "none",
					},
					"& .MuiDataGrid-virtualScroller": {
						backgroundColor: colors.primary[400],
					},
					"& .MuiDataGrid-footerContainer": {
						borderTop: "none",
						backgroundColor: colors.primary[700],
					},
					"& .MuiCheckbox-root": {
						color: `${colors.greenAccent[200]} !important`,
					},
					"& .MuiDataGrid-toolbarContainer .MuiButton-text": {
						color: `${colors.grey[100]} !important`,
					},
				}}
			>
				<DataGrid rows={mockDataContacts} columns={columns} components={{ Toolbar: GridToolbar }} />
			</Box> */}
		</Box>
	);
};

export default Medicoes;
