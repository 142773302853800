import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { Box, Typography, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
import BarChart from "../../components/BarChart";
import Header from "../../components/Header";
import LineChart from "../../components/LineChart";
import StatBox from "../../components/StatBox";
import { mockTransactions } from "../../data/mockData";
import { tokens } from "../../theme";
import Maphead from "../geography/MapHead";

const Dashboard = () => {
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);

	return (
		<Box
			// m="20px"
			sx={{ flexGrow: 1 }}
			p={1}
		>
			<Grid container spacing={1}>
				<Grid
					display="flex"
					justifyContent="space-between"
					alignItems="center"
					item
					xs={12}
				>
					<Header title="DASHBOARD" />
				</Grid>

				<Grid item xs={12} lg={4}>
					<Box
						// gridColumn="span 4"
						backgroundColor={colors.grey[100]}
						display="flex"
						alignItems="center"
						justifyContent="center"
						borderRadius="5px"
						border="1px solid black"
						//height={100}
					>
						<StatBox
							title="6"
							subtitle="Projetos"
							progress="0.75"
							increase="75%"
						/>
					</Box>
				</Grid>

				<Grid item xs={12} lg={4}>
					<Box
						// gridColumn="span 4"
						backgroundColor={colors.grey[100]}
						display="flex"
						alignItems="center"
						justifyContent="center"
						borderRadius="5px"
						border="1px solid black"
						// height={100}
					>
						<StatBox
							title="34"
							subtitle="Medições"
							progress="0.50"
							increase="50%"
						/>
					</Box>
				</Grid>

				<Grid item xs={12} lg={4}>
					<Box
						// gridColumn="span 4"
						backgroundColor={colors.grey[100]}
						display="flex"
						alignItems="center"
						justifyContent="center"
						borderRadius="5px"
						border="1px solid black"
						// height={100}
					>
						<StatBox
							title="100"
							subtitle="Ensaios"
							progress="0.35"
							increase="35%"
						/>
					</Box>
				</Grid>

				<Grid item lg={8} xs={12}>
					<Box
						// gridColumn="span 8"
						// gridRow="span 2"
						// backgroundColor={colors.grey[100]}
						borderRadius="5px"
						border="1px solid black"
					>
						<Box
							mt="0px"
							p="7px 30px 7px 10px"
							display="flex"
							justifyContent="space-between"
							alignItems="center"
							backgroundColor={colors.grey[200]}
							sx={{
								borderTopLeftRadius: "5px",
								borderTopRightRadius: "5px",
							}}
						>
							<Box>
								<Typography
									variant="h5"
									fontWeight="600"
									color={colors.grey[900]}
								>
									Coeficiente de Variação
								</Typography>
							</Box>
						</Box>
						<Box height={250} m={0} p={0}>
							<BarChart isDashboard={true} />
						</Box>
					</Box>
				</Grid>
				<Grid item lg={4} xs={12}>
					<Box
						backgroundColor={colors.grey[100]}
						borderRadius="5px"
						border="1px solid black"
					>
						<Box
							colors={colors.grey[100]}
							mt="0px"
							p="7px 30px 7px 10px"
							backgroundColor={colors.grey[200]}
							display="flex"
							justifyContent="space-between"
						>
							<Typography
								color={colors.grey[900]}
								variant="h5"
								fontWeight="600"
							>
								Medições
							</Typography>
							{/* <Typography
								color={colors.grey[900]}
								variant="h7"
								fontWeight="600"
								display="flex"
								justifyContent="center"
							>
								Última data
							</Typography> */}
						</Box>
						<Box overflow="auto" height={250}>
							{mockTransactions.map((transaction, i) => (
								<Box
									key={`${transaction.txId}-${i}`}
									display="flex"
									justifyContent="space-between"
									alignItems="center"
									borderBottom={`1px solid ${colors.grey[900]}`}
									p="15px"
								>
									<Box>
										<Typography
											color={colors.grey[700]}
											variant="h4"
											fontWeight="600"
										>
											{transaction.txId}
										</Typography>
										<Typography color={colors.grey[700]}>
											{transaction.user}
										</Typography>
									</Box>
									<Box color={colors.grey[700]}>
										<Typography color={colors.grey[700]} variant="h5">
											{transaction.date}
										</Typography>
										<Typography
											color={colors.grey[700]}
											variant="h7"
											display="flex"
											justifyContent="center"
										>
											Última medição
										</Typography>
									</Box>
									<Box display="flex">
										<Box
											backgroundColor={colors.grey[700]}
											p="3px"
											width="45px"
											borderRadius="4px"
											display="flex"
											justifyContent="center"
											marginRight={1}
										>
											<Typography color="white">
												{transaction.cost}
											</Typography>
										</Box>

										{transaction.txId === "CE 060" ? (
											<Link to="/projetos/projeto-CE-060">
												<RemoveRedEyeIcon
													fontSize="large"
													color="primary"
												/>
											</Link>
										) : (
											<RemoveRedEyeIcon
												fontSize="large"
												color="primary"
											/>
										)}
									</Box>
								</Box>
							))}
						</Box>
					</Box>
				</Grid>
				<Grid item lg={8} xs={12}>
					<Box
						gridColumn="span 4"
						height="100%"
						backgroundColor={colors.grey[100]}
						borderRadius="5px"
						border="1px solid black"
					>
						<Box
							mt="0px"
							p="7px 30px 7px 10px"
							display="flex"
							justifyContent="space-between"
							alignItems="center"
							backgroundColor={colors.grey[200]}
							sx={{
								borderTopLeftRadius: "5px",
								borderTopRightRadius: "5px",
							}}
						>
							<Box>
								<Typography
									variant="h5"
									fontWeight="600"
									color={colors.grey[900]}
								>
									Análise
								</Typography>
							</Box>
						</Box>
						<Box height="350px" mt={3}>
							<LineChart isDashboard={true} />
						</Box>
						{/* <Box height="170px" mt={1}>
						<LineChart isDashboard={true} />
					</Box> */}
					</Box>
				</Grid>
				<Grid item lg={4} xs={12}>
					<Box
						gridColumn="span 4"
						height="100%"
						backgroundColor={colors.grey[100]}
						borderRadius="5px"
						border="1px solid black"
					>
						<Box
							mt="0px"
							p="7px 30px 7px 10px"
							display="flex"
							justifyContent="space-between"
							alignItems="center"
							backgroundColor={colors.grey[200]}
							sx={{
								borderTopLeftRadius: "5px",
								borderTopRightRadius: "5px",
							}}
						>
							<Box>
								<Typography
									variant="h5"
									fontWeight="600"
									color={colors.grey[900]}
								>
									Mapa
								</Typography>
							</Box>
						</Box>
						<Box height={370} p={2}>
							<Maphead zoom={7}></Maphead>
						</Box>
					</Box>
				</Grid>
			</Grid>
		</Box>
	);
};

export default Dashboard;
