import { ResponsiveBoxPlot } from "@nivo/boxplot";

import { useTheme } from "@mui/material";
import { tokens } from "../theme";

const BoxPlotChart = ({
	data,
	min,
	max,
	avg,
	LI,
	LS,
	rejected1,
	rejected2,
	rejected3,
	rejected4,
	rejected5,
	rejected6,
	rejected7,
	rejected8,
	rejected9,
	rejected10,
	rejected11,
	legends = false,
	parameter,
}) => {
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);
	let subtitle = {};

	legends
		? (subtitle = [
				{
					anchor: "right",
					direction: "column",
					justify: false,
					translateX: 50,
					translateY: -90,
					itemWidth: 60,
					itemHeight: 20,
					itemsSpacing: 3,
					itemTextColor: "#999",
					itemDirection: "left-to-right",
					symbolSize: 20,
					symbolShape: "square",
				},
		  ])
		: (subtitle = []);

	return (
		<>
			{typeof LS != "undefined" ? (
				<ResponsiveBoxPlot
					data={data}
					theme={{
						axis: {
							domain: {
								line: {
									stroke: colors.grey[800],
								},
							},
							legend: {
								text: {
									fill: colors.grey[800],
								},
							},
							ticks: {
								line: {
									stroke: colors.grey[800],
									strokeWidth: 1,
								},
								text: {
									fill: colors.grey[800],
								},
							},
						},
						legends: {
							text: {
								fill: colors.grey[900],
							},
						},
						tooltip: {
							wrapper: {},
							container: {
								background: "#ffffff",
								color: "#333333",
								fontSize: 12,
							},
							basic: {},
							chip: {},
							table: {},
							tableCell: {},
							tableCellValue: {},
						},
					}}
					margin={{ top: 40, right: 60, bottom: 60, left: 60 }}
					minValue={min}
					maxValue={max}
					subGroupBy="subgroup"
					padding={0.12}
					enableGridX={true}
					// axisTop={{
					// 	tickSize: 5,
					// 	tickPadding: 5,
					// 	tickRotation: 0,
					// 	legend: "",
					// 	legendOffset: 36,
					// 	truncateTickAt: 0,
					// }}
					// axisRight={{
					// 	tickSize: 5,
					// 	tickPadding: 5,
					// 	tickRotation: 0,
					// 	legend: "",
					// 	legendOffset: 0,
					// 	truncateTickAt: 0,
					// }}
					axisBottom={{
						tickSize: 5,
						tickPadding: 5,
						tickRotation: 0,
						legend: "Medição",
						legendPosition: "middle",
						legendOffset: 32,
						truncateTickAt: 0,
					}}
					axisLeft={{
						tickSize: 5,
						tickPadding: 5,
						tickRotation: 0,
						legend: parameter,
						legendPosition: "middle",
						legendOffset: -45,
						truncateTickAt: 0,
					}}
					borderRadius={2}
					borderWidth={2}
					borderColor={{
						from: "color",
						modifiers: [["darker", 0.3]],
					}}
					medianWidth={2}
					medianColor={{
						from: "color",
						modifiers: [["darker", 0.3]],
					}}
					whiskerEndSize={0.6}
					whiskerColor={{
						from: "color",
						modifiers: [["darker", 0.3]],
					}}
					motionConfig="stiff"
					// legends={[
					// 	{
					// 		anchor: "right",
					// 		direction: "column",
					// 		justify: false,
					// 		translateX: -20,
					// 		translateY: -90,
					// 		itemWidth: 60,
					// 		itemHeight: 20,
					// 		itemsSpacing: 3,
					// 		itemTextColor: "#999",
					// 		itemDirection: "left-to-right",
					// 		symbolSize: 20,
					// 		symbolShape: "square",
					// 		effects: [
					// 			{
					// 				on: "hover",
					// 				style: {
					// 					itemTextColor: "#000",
					// 				},
					// 			},
					// 		],
					// 	},
					// ]}
					defs={[
						{
							id: "rejected",
							type: "patternLines",
							background: "inherit",
							color: "#ee6060",
							rotation: -45,
							lineWidth: 5,
							spacing: 10,
						},
						{
							id: "subgroup2",
							background: "inherit",
							color: "#ee6060",
						},
					]}
					fill={[
						{
							match: {
								group: { rejected1 },
							},
							id: "rejected",
						},
						{
							match: {
								group: { rejected2 },
							},
							id: "rejected",
						},
						{
							match: {
								group: { rejected3 },
							},
							id: "rejected",
						},
						{
							match: {
								subgroup: "2",
							},
							id: "subgroup2",
						},
					]}
					colors={{ scheme: "accent" }}
					markers={[
						{
							axis: "y",
							value: LS,
							lineStyle: {
								stroke: "#3d3d3d",
								strokeWidth: 2,
								strokeDasharray: "3",
							},
							legend: "LS",
							legendOrientation: "horizontal",
							legendPosition: "right",
						},
						{
							axis: "y",
							value: LI,
							lineStyle: {
								stroke: "#3d3d3d",
								strokeWidth: 2,
								strokeDasharray: "3",
							},
							legend: "LI",
							legendOrientation: "horizontal",
							legendPosition: "right",
						},
						{
							axis: "y",
							value: avg,
							lineStyle: {
								stroke: "#db4f4a",
								strokeWidth: 2,
								strokeDasharray: "3",
							},
							legend: "Média",
							legendOrientation: "horizontal",
							legendPosition: "right",
						},
					]}
				/>
			) : (
				<ResponsiveBoxPlot
					data={data}
					theme={{
						axis: {
							domain: {
								line: {
									stroke: colors.grey[800],
								},
							},
							legend: {
								text: {
									fill: colors.grey[800],
								},
							},
							ticks: {
								line: {
									stroke: colors.grey[800],
									strokeWidth: 1,
								},
								text: {
									fill: colors.grey[800],
								},
							},
						},
						legends: {
							text: {
								fill: colors.grey[900],
							},
						},
						tooltip: {
							wrapper: {},
							container: {
								background: "#ffffff",
								color: "#333333",
								fontSize: 12,
							},
							basic: {},
							chip: {},
							table: {},
							tableCell: {},
							tableCellValue: {},
						},
					}}
					margin={{ top: 40, right: 60, bottom: 60, left: 60 }}
					minValue={min}
					maxValue={max}
					subGroupBy="subgroup"
					padding={0.12}
					enableGridX={true}
					// axisTop={{
					// 	tickSize: 5,
					// 	tickPadding: 5,
					// 	tickRotation: 0,
					// 	legend: "",
					// 	legendOffset: 36,
					// 	truncateTickAt: 0,
					// }}
					// axisRight={{
					// 	tickSize: 5,
					// 	tickPadding: 5,
					// 	tickRotation: 0,
					// 	legend: "",
					// 	legendOffset: 0,
					// 	truncateTickAt: 0,
					// }}
					axisBottom={{
						tickSize: 5,
						tickPadding: 5,
						tickRotation: 0,
						legend: "Medição",
						legendPosition: "middle",
						legendOffset: 32,
						truncateTickAt: 0,
					}}
					axisLeft={{
						tickSize: 5,
						tickPadding: 5,
						tickRotation: 0,
						legend: parameter,
						legendPosition: "middle",
						legendOffset: -45,
						truncateTickAt: 0,
					}}
					colors={{ scheme: "accent" }}
					//colors={{ scheme: "nivo" }}
					borderRadius={2}
					borderWidth={2}
					borderColor={{
						from: "color",
						modifiers: [["darker", 0.3]],
					}}
					medianWidth={2}
					medianColor={{
						from: "color",
						modifiers: [["darker", 0.3]],
					}}
					whiskerEndSize={0.6}
					whiskerColor={{
						from: "color",
						modifiers: [["darker", 0.3]],
					}}
					motionConfig="stiff"
					defs={[
						{
							id: "rejected",
							type: "patternLines",
							background: "inherit",
							color: "#ee6060",
							rotation: -45,
							lineWidth: 5,
							spacing: 10,
						},
					]}
					fill={[
						{
							match: {
								group: rejected1,
							},
							id: "rejected",
						},
						{
							match: {
								group: rejected2,
							},
							id: "rejected",
						},
						{
							match: {
								group: rejected3,
							},
							id: "rejected",
						},
						{
							match: {
								group: rejected4,
							},
							id: "rejected",
						},
						{
							match: {
								group: rejected5,
							},
							id: "rejected",
						},
						{
							match: {
								group: rejected6,
							},
							id: "rejected",
						},
						{
							match: {
								group: rejected7,
							},
							id: "rejected",
						},
						{
							match: {
								group: rejected8,
							},
							id: "rejected",
						},
						{
							match: {
								group: rejected9,
							},
							id: "rejected",
						},
						{
							match: {
								group: rejected10,
							},
							id: "rejected",
						},
						{
							match: {
								group: rejected11,
							},
							id: "rejected",
						},
					]}
					legends={subtitle}
					markers={[
						{
							axis: "y",
							value: LI,
							lineStyle: {
								stroke: "#3d3d3d",
								strokeWidth: 2,
								strokeDasharray: "3",
							},
							legend: "LI",
							legendOrientation: "horizontal",
							legendPosition: "right",
						},
						{
							axis: "y",
							value: avg,
							lineStyle: {
								stroke: "#db4f4a",
								strokeWidth: 2,
								strokeDasharray: "3",
							},
							legend: "Média",
							legendOrientation: "horizontal",
							legendPosition: "right",
						},
					]}
				/>
			)}
		</>
	);
};

export default BoxPlotChart;
