import { ARRAY_CAMADAS_ALTERADA } from "./actionTypes";

function alterarArrayCamadas(novoArrayCamadas) {
	return {
		type: ARRAY_CAMADAS_ALTERADA,
		payload: novoArrayCamadas,
	};
}

export { alterarArrayCamadas };
