import { INFO_MEDICAO_ALTERADO } from "./actionTypes";

function alterarInfoMedicoes(infoMedicoes) {
	return {
		type: INFO_MEDICAO_ALTERADO,
		payload: infoMedicoes,
	};
}

export { alterarInfoMedicoes };
