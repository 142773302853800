import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";

const ProgressCircle = ({
	progress = "0.75",
	size = "40",
	color = "#292929",
	legend = false,
}) => {
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);
	const angle = progress * 360;
	return (
		<Box
			sx={{
				background: `radial-gradient(${colors.grey[100]} 55%, transparent 56%),
            conic-gradient(transparent 0deg ${angle}deg, ${colors.grey[200]} ${angle}deg 360deg),
            ${color}`,
				borderRadius: "50%",
				width: `${size}px`,
				height: `${size}px`,
			}}
			display="flex"
			justifyContent="center"
			alignItems="center"
		>
			<Typography color={colors.grey[900]} variant="h4" fontWeight={700}>
				{legend ? Math.round(progress * 100) + "%" : ""}
			</Typography>
		</Box>
	);
};

export default ProgressCircle;
