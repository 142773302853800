import { INFO_MEDICAOPARAMETRO } from "../actions/actionTypes";

const initialState = [];

function camadaMedicaoParametro(state = initialState, action) {
	switch (action.type) {
		case INFO_MEDICAOPARAMETRO:
			state = action.payload;
			return state;
		default:
			return state;
	}
}

export default camadaMedicaoParametro;
