import { useTheme } from "@mui/material";
import { ResponsiveBar } from "@nivo/bar";
import { tokens } from "../theme";
import { mockBarData as data } from "../data/mockData";

const BarChart = ({ isDashboard = false }) => {
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);

	return (
		<ResponsiveBar
			data={data}
			theme={{
				// added
				axis: {
					domain: {
						line: {
							stroke: colors.grey[800],
						},
					},
					legend: {
						text: {
							fill: colors.grey[800],
						},
					},
					ticks: {
						line: {
							stroke: colors.grey[800],
							strokeWidth: 1,
						},
						text: {
							fill: colors.grey[800],
						},
					},
				},
				legends: {
					text: {
						fill: colors.grey[900],
					},
				},
				tooltip: {
					container: {
						color: colors.primary[500],
					},
				},
			}}
			keys={["CV"]}
			indexBy="Rodovia"
			margin={{ top: 50, right: 50, bottom: 50, left: 60 }}
			padding={0.3}
			valueScale={{ type: "linear" }}
			indexScale={{ type: "band", round: true }}
			// colors={{ scheme: "accent" }}
			colors={({ id, data }) => String(data["color"])}
			// defs={[
			// 	{
			// 		id: "dots",
			// 		type: "patternDots",
			// 		background: "inherit",
			// 		color: "#38bcb2",
			// 		size: 4,
			// 		padding: 1,
			// 		stagger: true,
			// 	},
			// 	{
			// 		id: "lines",
			// 		type: "patternLines",
			// 		background: "inherit",
			// 		color: "#eed312",
			// 		rotation: -45,
			// 		lineWidth: 6,
			// 		spacing: 5,
			// 	},
			// ]}
			borderColor={{
				from: "color",
				modifiers: [["darker", "1.6"]],
			}}
			axisTop={null}
			axisRight={null}
			axisBottom={{
				tickSize: 5,
				tickPadding: 5,
				tickRotation: -45,
				legend: isDashboard ? "Rodovias" : "country", // changed
				legendPosition: "end",
				legendOffset: 20,
			}}
			axisLeft={{
				tickSize: 5,
				tickPadding: 5,
				tickRotation: 0,
				legend: isDashboard ? "CV" : "CV", // changed
				legendPosition: "middle",
				legendOffset: -40,
			}}
			// labelSkipWidth={22}
			// labelSkipHeight={12}
			// labelTextColor={{
			// 	from: "color",
			// 	modifiers: [["darker", 1.6]],
			// }}
			enableLabel={false}
			enableTotals={true}
			// legends={[
			// 	{
			// 		dataFrom: "keys",
			// 		anchor: "bottom-right",
			// 		direction: "column",
			// 		justify: false,
			// 		translateX: 200,
			// 		translateY: -150,
			// 		itemsSpacing: 2,
			// 		itemWidth: 200,
			// 		itemHeight: 20,
			// 		itemDirection: "left-to-right",
			// 		// symbolSize: 20,
			// 		effects: [
			// 			{
			// 				on: "hover",
			// 				style: {
			// 					itemOpacity: 1,
			// 				},
			// 			},
			// 		],
			// 	},
			// ]}
			role="application"
			barAriaLabel={function (e) {
				return e.id + ": " + e.formattedValue + " in CV: " + e.indexValue;
			}}
		/>
	);
};

export default BarChart;
