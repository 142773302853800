import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";
import ProgressCircle from "./ProgressCircle";

const StatBox = ({ title, subtitle, icon, progress, increase }) => {
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);

	return (
		<Box width="100%" m="0 25px" p={0.5}>
			<Box display="flex" justifyContent="space-between">
				<Box marginBottom="">
					{icon}
					<Typography
						variant="h2"
						fontWeight="bold"
						sx={{ color: colors.grey[800] }}
					>
						{title}
					</Typography>
				</Box>
				<Box display="flex" justifyContent="center" alignItems="center">
					<ProgressCircle progress={progress} />
				</Box>
			</Box>
			<Box display="flex" justifyContent="space-between" mt="2px">
				<Typography variant="h5" sx={{ color: colors.grey[700] }}>
					{subtitle}
				</Typography>
				<Typography
					variant="h5"
					fontStyle="italic"
					sx={{ color: colors.grey[700] }}
					marginRight={0.65}
				>
					{increase}
				</Typography>
			</Box>
		</Box>
	);
};

export default StatBox;
