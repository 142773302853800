import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import { Box, IconButton } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useState } from "react";

const Topbar = () => {
	// const theme = useTheme();
	// const colors = tokens(theme.palette.mode);
	// const colorMode = useContext(ColorModeContext);

	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
		localStorage.clear();
		window.location.reload();
	};

	return (
		<Box display="flex" justifyContent="right" px={2}>
			{/* SEARCH BAR */}
			{/* <Box
				display="flex"
				backgroundColor={colors.primary[400]}
				borderRadius="3px"
			>
				<InputBase sx={{ ml: 2, flex: 1 }} placeholder="Search" />
        <IconButton type="button" sx={{ p: 1 }}>
          <SearchIcon />
        </IconButton>
			</Box> */}

			{/* ICONS */}
			<Box display="flex">
				{/* <IconButton onClick={colorMode.toggleColorMode}>
					{theme.palette.mode === "dark" ? (
						<DarkModeOutlinedIcon />
					) : (
						<LightModeOutlinedIcon />
					)}
				</IconButton> */}
				{/* <IconButton onClick={null}>
					<NotificationsOutlinedIcon sx={{ color: "black" }} />
				</IconButton> */}
				<IconButton onClick={handleClick}>
					<PersonOutlinedIcon id="basic-button" sx={{ color: "black" }} />
				</IconButton>
				<Menu
					id="basic-menu"
					anchorEl={anchorEl}
					open={open}
					onClose={handleClose}
					MenuListProps={{
						"aria-labelledby": "basic-button",
					}}
				>
					<MenuItem onClick={handleClose}>Perfil</MenuItem>
					<MenuItem onClick={handleClose}>Segurança</MenuItem>
					<MenuItem onClick={handleClose}>Sair</MenuItem>
				</Menu>
			</Box>
		</Box>
	);
};

export default Topbar;
