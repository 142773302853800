import { Box, IconButton, Typography, useTheme } from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useState, useEffect, useMemo } from "react";
import "../../node_modules/dayjs/locale/pt-br";
import { tokens } from "../theme";
import axios from "axios";
import dayjs from "dayjs";
import { connect } from "react-redux";
import { alterarInfoProjeto } from "../store/actions/projetos";
import Alert from "@mui/material/Alert";

let validacaoErros = {
	erroNomeProjeto: true,
	erroDataInicial: true,
	erroDataFinal: true,
	erroValorContrato: true,
	erroValorAditivo: true,
};

const ModalProjetos = (props) => {
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);
	const [listaProjetos, setListaProjetos] = useState([]);
	//const label = { inputProps: { "aria-label": "Switch demo" } };
	const [nomeProjeto, setNomeProjeto] = useState(props.infoProjeto.nomeProjeto);
	const [dataInicialComp, setDataInicialComp] = useState(dayjs(props.infoProjeto.dataInicialPrevia));
	const [dataFinalComp, setDataFinalComp] = useState(dayjs(props.infoProjeto.dataFinalPrevia));
	const [valorContrato, setValorContrato] = useState(props.infoProjeto.valorContrato);
	const [valorAditivo, setValorAditivo] = useState(props.infoProjeto.valorAditivo);
	const [checked, setChecked] = useState(true);
	const initialStateFormData = { nomeProjeto: "", dataInicialPrevia: null, dataFinalPrevia: null, valorContrato: "", valorAditivo: "" };
	const [formData, setformData] = useState({
		nomeProjeto: props.infoProjeto.nomeProjeto,
		valorContrato: parseFloat(props.infoProjeto.valorContrato),
		valorAditivo: parseFloat(props.infoProjeto.valorAditivo),
		dataInicialPrevia: props.infoProjeto.dataInicialPrevia,
		dataFinalPrevia: props.infoProjeto.dataFinalPrevia,
	});
	const [error, setError] = useState(null);
	const [mensagemErro, setMensagemErro] = useState();
	const [mensagemErroNomeProjeto, setMensagemErroNomeProjeto] = useState(false);
	const [mensagemErroDataInicial, setMensagemErroDataInicial] = useState(false);
	const [mensagemErroDataFinal, setMensagemErroDataFinal] = useState(false);
	const [mensagemErroValorContrato, setMensagemErroValorContrato] = useState(false);
	const [mensagemErroValorAditivo, setMensagemErroValorAditivo] = useState(false);

	useEffect(() => {
		setformData({
			nomeProjeto: nomeProjeto,
			valorContrato: parseFloat(valorContrato),
			valorAditivo: parseFloat(valorAditivo),
			dataInicialPrevia: dayjs(dataInicialComp).format("YYYY-MM-DD"),
			dataFinalPrevia: dayjs(dataFinalComp).format("YYYY-MM-DD"),
		});
		//setChecked(false);
		props.alteraInfoProjeto({
			nomeProjeto: nomeProjeto,
			valorContrato: parseFloat(valorContrato),
			valorAditivo: parseFloat(valorAditivo),
			dataInicialPrevia: dayjs(dataInicialComp).format("YYYY-MM-DD"),
			dataFinalPrevia: dayjs(dataFinalComp).format("YYYY-MM-DD"),
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [nomeProjeto, dataInicialComp, dataFinalComp, valorContrato, valorAditivo]);

	const errorMessage = useMemo(() => {
		switch (error) {
			case "invalidDate": {
				return "Digite uma data válida";
			}
			default: {
				return "";
			}
		}
	}, [error]);

	async function cadastarProjeto(e) {
		// formData.dataInicialPrevia === "Invalid Date" ||
		// formData.nomeProjeto === "" ||
		// formData.dataInicialPrevia === "Invalid Date" ||
		// formData.dataFinalPrevia === "Invalid Date" ||
		// formData.valorContrato === "" ||
		// isNaN(formData.valorContrato) ||
		// formData.valorAditivo === "" ||
		// isNaN(formData.valorAditivo)

		e.preventDefault();

		if (formData.nomeProjeto === "") {
			setMensagemErroNomeProjeto(true);
			setMensagemErro("Preencher nome do projeto");
		} else if (formData.dataInicialPrevia === "" || formData.dataInicialPrevia === "Invalid Date") {
			setMensagemErroDataInicial(true);
			setMensagemErro("Preencher data inicial");
		} else if (formData.dataFinalPrevia === "" || formData.dataFinalPrevia === "Invalid Date") {
			setMensagemErroDataFinal(true);
			setMensagemErro("Preencher data final");
		} else if (formData.valorContrato === "" || isNaN(formData.valorContrato)) {
			setMensagemErroValorContrato(true);
			setMensagemErro("Preencher valor do contrato");
		} else if (formData.valorAditivo === "" || isNaN(formData.valorAditivo)) {
			setMensagemErroValorAditivo(true);
			setMensagemErro("Preencher valor do ativo");
		} else if (props.listaProjetos.find((value) => value.nomeProjeto === formData.nomeProjeto)) {
			setMensagemErroNomeProjeto(true);
			setMensagemErro("Projeto já existe!");
		} else {
			try {
				const fetchCadastraProjeto = async () => {
					//eslint-disable-next-line no-unused-vars
					const response = await axios.post(`${process.env.REACT_APP_URL_BASE}/projetos`, formData).then((response) => {
						props.setProjetos(response.data);
						console.log(response.data);
						props.onClose();
						setformData(initialStateFormData);
						props.alteraInfoProjeto(initialStateFormData);
						setDataInicialComp(null);
						setDataFinalComp(null);
					});
					setMensagemErro("");
					setMensagemErroNomeProjeto(false);
					setMensagemErroDataInicial(false);
					setMensagemErroDataFinal(false);
					setMensagemErroValorContrato(false);
					setMensagemErroValorAditivo(false);
				};
				fetchCadastraProjeto();
				props.handleClickSnackBar();
			} catch (error) {
				console.log(error);
			}
		}
	}

	return (
		<Box
			sx={{
				position: "absolute",
				top: "50%",
				left: "50%",
				transform: "translate(-50%, -50%)",
				width: 400,
				backgroundColor: colors.grey[100],
				border: "1px solid #0c101b",
				boxShadow: 2,
				p: 1,
				borderRadius: "5px",
			}}
		>
			<Box display="flex" flexDirection="column" p={2}>
				<Box display="flex" justifyContent="center">
					<Typography id="modal-modal-title" variant="h3" fontWeight="700" mb="10px" color={colors.grey[900]}>
						Novo Projeto
					</Typography>
				</Box>
				<form onSubmit={cadastarProjeto}>
					{mensagemErro ? <Alert severity="error">{mensagemErro}</Alert> : ""}
					<Box borderRadius="3px" my={1}>
						<TextField
							{...(mensagemErroNomeProjeto ? { error: "error" } : "")}
							fullWidth
							name="nomeProjeto"
							label="Nome do Projeto"
							color="primary"
							onChange={(e) => setNomeProjeto(e.target.value)}
							value={nomeProjeto}
						/>
					</Box>
					<Box display="flex" borderRadius="3px" my={1}>
						<Grid container spacing={2}>
							<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
								<Grid item xs={12} lg={6}>
									<DatePicker
										{...(mensagemErroDataInicial ? { onError: (newError) => setError(newError) } : "")}
										name="dataInicial"
										label="Data Inicial"
										value={dataInicialComp}
										onChange={(value) => setDataInicialComp(value)}
										{...(mensagemErroDataInicial ? { slotProps: { textField: { helperText: errorMessage } } } : "")}
									/>
								</Grid>
								<Grid item xs={12} lg={6}>
									<DatePicker
										{...(mensagemErroDataFinal ? { onError: (newError) => setError(newError) } : "")}
										name="dataFinal"
										label="Prazo Final"
										value={dataFinalComp}
										onChange={(value) => setDataFinalComp(value)}
										{...(mensagemErroDataFinal ? { slotProps: { textField: { helperText: errorMessage } } } : "")}
									/>
								</Grid>
							</LocalizationProvider>
						</Grid>
					</Box>
					<Box display="flex" borderRadius="3px" my={1}>
						<TextField
							{...(mensagemErroValorContrato ? { error: "error" } : "")}
							fullWidth
							name="valorContrato"
							label="Valor do Contrato"
							variant="outlined"
							color="primary"
							onChange={(e) => setValorContrato(e.target.value)}
							value={valorContrato}
						/>
					</Box>
					<Box display="flex" borderRadius="3px" my={1}>
						<TextField
							{...(mensagemErroValorAditivo ? { error: "error" } : "")}
							fullWidth
							name="valorAditivo"
							label="Valor do Aditivo"
							variant="outlined"
							color="primary"
							onChange={(e) => setValorAditivo(e.target.value)}
							value={valorAditivo}
						/>
					</Box>
					<Box display="flex" justifyContent="right">
						<Switch checked={checked} inputProps={{ "aria-label": "controlled" }} />
					</Box>
					<Box display="flex" justifyContent="right" mt={1}>
						<Button type="submit" variant="outlined" color="primary">
							Cadastrar
						</Button>
						{/* <Button onClick={props.handleClickSnackBar}>Snackbar</Button> */}
					</Box>
				</form>
			</Box>
		</Box>
	);
};

function mapStateToProps(state) {
	return { infoProjeto: state.infoProjeto };
}

function mapDispatchToProps(dispatch) {
	return {
		alteraInfoProjeto(array) {
			dispatch(alterarInfoProjeto(array));
		},
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalProjetos);
