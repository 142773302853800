import { Button, Grid, TextField } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import { useState, useEffect } from "react";
import PostAddIcon from "@mui/icons-material/PostAdd";
import IconButton from "@mui/material/IconButton";

const VPLILS = (props) => {
	const [numeroDeAmostras, setNumeroDeAmostras] = useState(props.quantidadeDeAmostras);
	const [valorDoProjeto, setValorDoProjeto] = useState(props.vp);
	const [limiteInferior, setLimiteInferior] = useState(props.li);
	const [limiteSuperior, setLimiteSuperior] = useState(props.ls);

	function verificaVPLILS() {
		if (
			numeroDeAmostras === 0 ||
			numeroDeAmostras === "0" ||
			numeroDeAmostras === undefined ||
			valorDoProjeto === null ||
			valorDoProjeto === 0 ||
			valorDoProjeto === "0" ||
			valorDoProjeto === undefined ||
			limiteInferior === null ||
			limiteInferior === undefined ||
			limiteSuperior === null ||
			limiteSuperior === undefined
		) {
			props.verificaCamposVazios(true);
		}
	}

	return (
		<>
			<Grid item xs={12} lg={1.5}>
				<TextField
					id={props.parametro + "Amostras"}
					label="Amostras"
					variant="outlined"
					type="number"
					fullWidth
					value={numeroDeAmostras}
					onChange={(e) => setNumeroDeAmostras(e.target.value)}
					InputLabelProps={{ shrink: true }}
				/>
			</Grid>
			<Grid item xs={12} lg={1.5}>
				<TextField
					id={props.parametro + "VP"}
					label="Valor do projeto"
					variant="outlined"
					type="number"
					fullWidth
					value={valorDoProjeto}
					onChange={(e) => setValorDoProjeto(e.target.value)}
					InputLabelProps={{ shrink: true }}
				/>
			</Grid>
			<Grid item xs={12} lg={1.5}>
				<TextField
					id={props.parametro + "LI"}
					label="Limite Inferior"
					variant="outlined"
					type="number"
					fullWidth
					value={limiteInferior}
					onChange={(e) => setLimiteInferior(e.target.value)}
					InputLabelProps={{ shrink: true }}
				/>
			</Grid>
			<Grid item xs={12} lg={1.5}>
				<TextField
					id={props.parametro + "LS"}
					label="Limite Superior"
					variant="outlined"
					type="number"
					fullWidth
					value={limiteSuperior}
					onChange={(e) => setLimiteSuperior(e.target.value)}
					InputLabelProps={{ shrink: true }}
				/>
			</Grid>
			<Grid item xs={12} lg={0.5} display="flex" justifyContent="end">
				<IconButton
					color="primary"
					variant="outlined"
					onClick={() => [
						props.salvaInformacoes({
							valoresEntrada: props.valoresDeEntrada,
							numeroAmostras: numeroDeAmostras,
							valorDoProjeto: valorDoProjeto,
							limiteInferior: limiteInferior,
							limiteSuperior: limiteSuperior,
						}),
						//verificaVPLILS(),
					]}
				>
					<SaveIcon />
				</IconButton>
			</Grid>
			<Grid item xs={12} lg={1} display="flex" justifyContent="end">
				<Button
					color="primary"
					variant="outlined"
					onClick={() => [
						props.onVisible({
							valoresEntrada: props.valoresDeEntrada,
							trechoEntrada: props.trechoEntrada,
							dataEntrada: props.dataEntrada,
							numeroAmostras: numeroDeAmostras,
							valorDoProjeto: valorDoProjeto,
							limiteInferior: limiteInferior,
							limiteSuperior: limiteSuperior,
						}),
						//verificaVPLILS(),
					]}
				>
					<PostAddIcon sx={{ fontSize: 25 }} />
				</Button>
			</Grid>
		</>
	);
};

export default VPLILS;
