import { useState } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import EditRoadOutlinedIcon from "@mui/icons-material/EditRoadOutlined";
import AnalyticsOutlinedIcon from "@mui/icons-material/AnalyticsOutlined";
import InsightsOutlinedIcon from "@mui/icons-material/InsightsOutlined";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import Logo from "../../assets/logo2.png";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import { isMobile } from "react-device-detect";

const Item = ({ title, to, icon, selected, setSelected }) => {
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);
	return (
		<MenuItem
			active={selected === title}
			style={{
				color: colors.grey[100],
			}}
			onClick={() => setSelected(title)}
			icon={icon}
		>
			<Typography>{title}</Typography>
			<Link to={to} />
		</MenuItem>
	);
};

const Sidebar = () => {
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);
	const [isCollapsed, setIsCollapsed] = useState(isMobile ? true : false);
	const [selected, setSelected] = useState("Dashboard");

	return (
		<Box
			// height={1050}
			sx={{
				"& .pro-sidebar-inner": {
					background: `${colors.grey[800]} !important`,
				},
				"& .pro-icon-wrapper": {
					backgroundColor: "transparent !important",
				},
				"& .pro-inner-item": {
					padding: "5px 20px 5px 20px !important",
				},
				"& .pro-sidebar.collapsed .pro-inner-item": {
					padding: "5px 5px 5px 5px !important",
				},
				"& .pro-inner-item:hover": {
					color: "#70d8bd !important",
				},
				"& .pro-menu-item.active": {
					color: "#70d8bd !important",
				},
			}}
		>
			<ProSidebar collapsed={isCollapsed} width="250px" collapsedWidth={45}>
				<Menu iconShape="square">
					<MenuItem
						onClick={() => setIsCollapsed(!isCollapsed)}
						icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
						style={{
							margin: "10px 0 10px 0",
							color: colors.grey[100],
						}}
					>
						{!isCollapsed && (
							<Box display="flex" justifyContent="space-between" alignItems="center" ml="15px">
								{/* <Typography variant="h3" color={colors.grey[100]}>
									GRiR
								</Typography> */}
								<img src={Logo} height={40} alt="" />
								<IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
									<MenuOutlinedIcon
										style={{
											margin: "10px 0 0 0",
											color: colors.grey[100],
											display: "flex",
											justifyContent: "center",
										}}
									/>
								</IconButton>
							</Box>
						)}
					</MenuItem>

					{!isCollapsed && (
						<Box mb="25px">
							<Box display="flex" justifyContent="center" alignItems="center">
								<img
									alt="profile-user"
									width="100px"
									height="100px"
									src={`../../assets/user.png`}
									style={{
										cursor: "pointer",
										borderRadius: "50%",
									}}
								/>
							</Box>
							<Box textAlign="center">
								<Typography variant="h4" color={colors.grey[100]} fontWeight="bold">
									Alexandre Castro
								</Typography>
								<Typography variant="h5" color={colors.greenAccent[500]}>
									IFCE
								</Typography>
							</Box>
						</Box>
					)}

					<Box paddingLeft={isCollapsed ? undefined : "0%"}>
						<Item title="Dashboard" to="/" icon={<HomeOutlinedIcon />} selected={selected} setSelected={setSelected} />

						<Typography variant="h6" color={colors.grey[300]} display="flex" paddingLeft={3} paddingTop={3}>
							{(!isCollapsed && "Relatórios de Inspeção") || ""}
						</Typography>
						<Item
							title="Projetos"
							to="/projetos"
							icon={<EditRoadOutlinedIcon />}
							selected={selected}
							setSelected={setSelected}
						/>
						<Item
							title="Medições"
							to="/medicoes"
							icon={<AssignmentOutlinedIcon />}
							selected={selected}
							setSelected={setSelected}
						/>
						<Typography variant="h6" color={colors.grey[300]} display="flex" paddingLeft={3} paddingTop={3}>
							{(!isCollapsed && "Análise") || ""}
						</Typography>
						<Item title="Geral" to="/---" icon={<AnalyticsOutlinedIcon />} selected={selected} setSelected={setSelected} />
						<Item
							title="Projetos/Medições"
							to="/---"
							icon={<InsightsOutlinedIcon />}
							selected={selected}
							setSelected={setSelected}
						/>
						<Item title="Mapa" to="/geography" icon={<MapOutlinedIcon />} selected={selected} setSelected={setSelected} />
						<Typography variant="h6" color={colors.grey[300]} display="flex" paddingLeft={3} paddingTop={3}>
							{(!isCollapsed && "Configurações") || ""}
						</Typography>
						<Item title="Usuários" to="/form" icon={<GroupOutlinedIcon />} selected={selected} setSelected={setSelected} />
					</Box>
				</Menu>
			</ProSidebar>
		</Box>
	);
};

export default Sidebar;
