import { Box, useTheme } from "@mui/material";
// import GeographyChart from "../../components/GeographyChart";
import { useWindowSize } from "@uidotdev/usehooks";
import Header from "../../components/Header";
import { tokens } from "../../theme";
import Maphead from "./MapHead";

const Geography = () => {
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);
	const size = useWindowSize();

	return (
		<Box m="20px">
			<Header title="Mapa" subtitle="" />
			<Box border={`1px solid ${colors.grey[100]}`} borderRadius="4px" height="750px">
				<Maphead zoom={8}></Maphead>
			</Box>
		</Box>
	);
};

export default Geography;
