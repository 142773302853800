import { createStore, combineReducers } from "redux";
import camadasReducer from "./reducers/camadas";
import infoProjetoReducer from "./reducers/projeto-info";
import infoMedicaoReducer from "./reducers/dados-medicao";
import camadaMedicaoParametroReducer from "./reducers/camadaMedicaoParametro";

const reducers = combineReducers({
	camadasSelecionadas: camadasReducer,
	infoProjeto: infoProjetoReducer,
	infoMedicao: infoMedicaoReducer,
	camadaMedicaoParametro: camadaMedicaoParametroReducer,
});

function storeConfig() {
	return createStore(reducers);
}

export default storeConfig;
