import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import * as React from "react";
import { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import "../../../node_modules/dayjs/locale/pt-br";
import { tokens } from "../../theme";
import axios from "axios";
import { Suspense } from "react";
import Alert from "@mui/material/Alert";
import Medicao from "../medicoes/medicao";

const ModalMedicoes = (props) => {
	const [listaMedicoes, setListaMedicoes] = useState([]);
	const [nomeMedicao, setMedicao] = useState("");
	const [trecho, setTrecho] = useState("");
	const [data, setData] = useState(dayjs(null));
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);
	const initialStateFormData = { nomeMedicao: "", trechoMedicao: "" };
	const VisuallyHiddenInput = styled("input")({
		clip: "rect(0 0 0 0)",
		clipPath: "inset(50%)",
		height: 1,
		overflow: "hidden",
		position: "center",
		bottom: 0,
		left: 0,
		whiteSpace: "nowrap",
		width: 1,
	});
	const [formData, setformData] = useState({
		nomeMedicao: "",
		trechoMedicao: "",
	});
	const [error, setError] = useState(null);
	const [mensagemErroGeral, setMensagemErro] = useState();
	const [mensagemErroNomeMedicao, setMensagemErroNomeMedicao] = useState(false);
	const [mensagemErroTrecho, setMensagemErroTrecho] = useState(false);
	const [mensagemErroData, setMensagemErroData] = useState(false);

	const errorMessage = useMemo(() => {
		switch (error) {
			case "invalidDate": {
				return "Digite uma data válida";
			}
			default: {
				return "";
			}
		}
	}, [error]);

	useEffect(() => {
		fetchListaMedicoes();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setformData({
			...formData,
			nomeMedicao: nomeMedicao,
			trechoMedicao: trecho,
			dataMedicao: dayjs(data).format("YYYY-MM-DD"),
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [nomeMedicao, trecho, data]);

	async function cadastrarMedicao(e) {
		e.preventDefault();
		//setformData({ ...formData, nomeMedicao: nomeMedicao, trechoMedicao: trecho, dataMedicao: dayjs(data).format("'YYYY-MM-DD'") });
		if (formData.nomeMedicao === "") {
			setMensagemErroNomeMedicao(true);
			setMensagemErro("Preencher nome da medição");
		} else if (formData.trechoMedicao === "") {
			setMensagemErroTrecho(true);
			setMensagemErro("Preencher trecho");
		} else if (formData.dataMedicao === "Invalid Date" || formData.dataMedicao === "") {
			setMensagemErroData(true);
			setMensagemErro("Preencher data");
		} else {
			try {
				const fetchCadastraMedicao = () => {
					axios.post(`${process.env.REACT_APP_URL_BASE}/medicoes/${props.idprojeto}`, formData).then((response) => {
						// listaMedicoes.push({
						// 	nome: response.data.nomeMedicao,
						// 	trecho: response.data.trechoMedicao,
						// 	data: dayjs(response.data.dataMedicao).format("YYYY-MM-DD"),
						// 	projeto_idprojeto: props.idprojeto,
						// 	idmedicao: response.data.idmedicao,
						// });
						setListaMedicoes([
							...listaMedicoes,
							{
								nome: response.data.nomeMedicao,
								trecho: response.data.trechoMedicao,
								data: dayjs(response.data.dataMedicao).format("YYYY-MM-DD"),
								projeto_idprojeto: props.idprojeto,
								idmedicao: response.data.idmedicao,
								idensaio: response.data.idensaio,
							},
						]);

						setformData(initialStateFormData);
						setMedicao("");
						setTrecho("");
						setData(null);
						setMensagemErro("");
						setMensagemErroNomeMedicao(false);
						setMensagemErroTrecho(false);
						setMensagemErroData(false);

						//fetchListaMedicoes();
					});
				};
				fetchCadastraMedicao();
			} catch (error) {
				console.log("Erro ao adicionar uma medição:", error);
			}
		}
	}

	const fetchListaMedicoes = async () => {
		const response = await axios.get(`${process.env.REACT_APP_URL_BASE}/medicoes/${props.idprojeto}`);
		setListaMedicoes(response.data);
		// setListaMedicoes(
		// 	response.data,
		// 	response.data.map((value) => [
		// 		...listaMedicoes,
		// 		{
		// 			nome: value.nome,
		// 			trecho: value.trecho,
		// 			data: dayjs(value.data).format("YYYY-MM-DD"),
		// 			projeto_idprojeto: props.idprojeto,
		// 			idmedicao: value.idmedicao,
		// 			idensaio: value.idqualipav_ensaio,
		// 		},
		// 	])
		// );
	};

	return (
		<Box
			sx={{
				position: "absolute",
				top: "50%",
				left: "50%",
				transform: "translate(-50%, -50%)",
				maxWidth: 900,
				backgroundColor: colors.grey[100],
				border: "1px solid #0c101b",
				p: 1,
				borderRadius: "10px",
			}}
		>
			<Box display="flex" flexDirection="column" p={2}>
				<Box display="flex" justifyContent="center">
					<Typography id="modal-modal-title" variant="h3" fontWeight="700" m={1}>
						Medições
					</Typography>
				</Box>
				<form onSubmit={cadastrarMedicao}>
					{mensagemErroGeral ? <Alert severity="error">{mensagemErroGeral}</Alert> : ""}
					<Box display="flex" justifyContent="space-between" my={1} p={0}>
						<Grid container columnSpacing={1}>
							<Grid item xs={12} lg={5}>
								<TextField
									{...(mensagemErroNomeMedicao ? { error: "error" } : "")}
									fullWidth
									label="Nome"
									name="nomeMedicao"
									value={nomeMedicao}
									onChange={(e) => {
										setMedicao(e.target.value);
									}}
								/>
							</Grid>
							<Grid item xs={12} lg={3}>
								<TextField
									{...(mensagemErroTrecho ? { error: "error" } : "")}
									fullWidth
									label="Trecho"
									variant="outlined"
									value={trecho}
									onChange={(e) => {
										setTrecho(e.target.value);
									}}
								/>
							</Grid>
							<Grid item xs={12} lg={3}>
								<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
									<DatePicker
										{...(mensagemErroData ? { onError: (newError) => setError(newError) } : "")}
										label="Data"
										value={data}
										// onError={(newError) => setError(newError)}
										onChange={(novaData) => {
											setData(novaData);
										}}
										{...(mensagemErroData ? { slotProps: { textField: { helperText: errorMessage } } } : "")}
										// slotProps={{
										// 	//field: { clearable: true, onClear: () => setCleared(true) },
										// 	textField: {
										// 		helperText: errorMessage,
										// 	},
										// }}
									/>
								</LocalizationProvider>
							</Grid>
							<Grid item xs={12} lg={1} display="flex" justifyContent="center" alignItems="center">
								<IconButton color="primary" type="submit">
									<AddCircleOutlineOutlinedIcon />
								</IconButton>
								<IconButton component="label" role={undefined} variant="contained" color="primary">
									<CloudUploadIcon />
									<VisuallyHiddenInput type="file" />
								</IconButton>
							</Grid>
						</Grid>
					</Box>
				</form>
				<Divider />
				<Suspense fallback={<h2>🌀 Loading...</h2>}>
					<TableContainer component={Paper} sx={{ maxHeight: 440 }}>
						<Table
							stickyHeader
							sx={{
								minWidth: 100,
							}}
							size="small"
						>
							<TableHead>
								<TableRow>
									<TableCell key={"medicao"}>
										<Typography variant="h5" fontWeight="600">
											Medição
										</Typography>
									</TableCell>
									<TableCell key={"trecho"} align="left">
										<Typography variant="h5" fontWeight="600">
											Trecho
										</Typography>
									</TableCell>
									<TableCell key={"data"} align="left">
										<Typography variant="h5" fontWeight="600">
											Data
										</Typography>
									</TableCell>
									<TableCell align="center"></TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{listaMedicoes.map((value) => (
									<TableRow
										sx={{
											backgroundColor: colors.grey[100],
										}}
									>
										<TableCell key={value.idnomeMedicao + "nome"} component="th" scope="row">
											<Typography variant="h6" fontWeight="400" color={colors.grey[900]} p={0}>
												{value.nome}
											</Typography>
										</TableCell>
										<TableCell key={value.idnomeMedicao + "trecho"} align="left">
											<Typography variant="h6" fontWeight="400" color={colors.grey[900]} p={0}>
												{value.trecho}
											</Typography>
										</TableCell>
										<TableCell key={value.idnomeMedicao + "data"} align="left">
											<Typography variant="h6" fontWeight="400" color={colors.grey[900]} p={0}>
												{dayjs(value.data).format("DD/MM/YYYY")}
											</Typography>
										</TableCell>
										<TableCell
											key={value.idnomeMedicao + "links"}
											align="center"
											width="20%"
											sx={{
												"& MuiTableCell-root MuiTableCell-body": {
													padding: `0px !important`,
												},
											}}
										>
											<Link
												to="../medicoes/medicao/"
												state={{
													idMedicao: `${value.idmedicao}`,
													nomeMedicao: `${value.nome}`,
													trechoMedicao: `${value.trecho}`,
													idEnsaio: `${value.idqualipav_ensaio || value.idensaio}`, //primeiro valor referente ao get e segundo referente ao post
													dataMedicao: `${value.data}`,
													nomeProjeto: `${value.nomeProjeto}`,
												}}
											>
												<IconButton>
													<FolderOpenIcon color="primary" />
												</IconButton>
											</Link>
											<IconButton>
												<EditIcon color="primary" />
											</IconButton>
											<IconButton>
												<DeleteIcon color="primary" />
											</IconButton>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</Suspense>
			</Box>
		</Box>
	);
};

export default ModalMedicoes;
