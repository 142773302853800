import { Box, useTheme, Typography } from "@mui/material";
import Header from "../../../components/Header";
import Grid from "@mui/material/Grid";
import ScatterChart from "../../../components/ScatterChart";
import { tokens } from "../../../theme";
import BoxPlotChart from "../../../components/BoxPlotChart";
import {
	mockScatterCAP,
	mockBoxplotCAP,
	mockScatterBase,
	mockBoxplotBase,
	mockScatterSubbase,
	mockBoxplotSubbase,
} from "../../../data/mockData";

const ProjetoCE060 = (data) => {
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);
	return (
		<Box sx={{ flexGrow: 1 }} p={0}>
			<Grid container spacing={1} display="flex" justifyContent="center">
				<Grid item xs={12} m={1}>
					<Header title="CE 060" subtitle="" />
				</Grid>
				<Grid item xs={12}>
					<Typography
						variant="h4"
						fontWeight="600"
						color={colors.grey[900]}
						backgroundColor={colors.grey[200]}
						py={1}
						display="flex"
						justifyContent="center"
					>
						Rolamento
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Typography
						variant="h4"
						CAP
						fontWeight="600"
						color={colors.grey[900]}
						display="flex"
						justifyContent="center"
					>
						%CAP
					</Typography>
				</Grid>
				<Grid item xs={12} lg={5.5} m={1}>
					<Box borderRadius="5px" border="1px solid black">
						<Box height="35vh" m={0} p={0}>
							<ScatterChart
								data={mockScatterCAP}
								min={5}
								max={7}
								avg={5.47}
								LI={5.2}
								LS={5.8}
								parameter="%CAP"
							/>
						</Box>
					</Box>
				</Grid>
				<Grid item xs={12} lg={5.5} m={1}>
					<Box borderRadius="5px" border="1px solid black">
						<Box height="35vh" m={0} p={0}>
							<BoxPlotChart
								data={mockBoxplotCAP}
								min={5.1}
								max={5.9}
								avg={5.47}
								LI={5.2}
								LS={5.8}
								parameter="%CAP"
							/>
						</Box>
					</Box>
				</Grid>
				<Grid item xs={12}>
					<Typography
						variant="h4"
						fontWeight="600"
						color={colors.grey[900]}
						p="7px 30px 7px 10px"
						backgroundColor={colors.grey[200]}
						display="flex"
						justifyContent="center"
					>
						Base (B)
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Typography
						variant="h4"
						fontWeight="600"
						color={colors.grey[900]}
						display="flex"
						justifyContent="center"
					>
						Grau de compactação
					</Typography>
				</Grid>
				<Grid item xs={12} lg={5.5} m={1}>
					<Box borderRadius="5px" border="1px solid black">
						<Box height="35vh" m={0} p={0}>
							<ScatterChart
								data={mockScatterBase}
								min={95}
								max={105}
								avg={101.16}
								LI={100}
								parameter="Grau de compactação (%)"
							/>
						</Box>
					</Box>
				</Grid>
				<Grid item xs={12} lg={5.5} m={1}>
					<Box borderRadius="5px" border="1px solid black">
						<Box height="35vh" m={0} p={0}>
							<BoxPlotChart
								data={mockBoxplotBase}
								min={95}
								max={105}
								avg={101.16}
								LI={100}
								legends={false}
								rejected2="12"
								rejected3="13"
								rejected5="18"
								rejected6="20"
								parameter="Grau de compactação (%)"
							/>
						</Box>
					</Box>
				</Grid>
				<Grid item xs={12} lg={4}></Grid>
				<Grid item xs={12}>
					<Typography
						variant="h4"
						fontWeight="600"
						color={colors.grey[900]}
						p="7px 30px 7px 10px"
						backgroundColor={colors.grey[200]}
						display="flex"
						justifyContent="center"
					>
						Sub-base (SB)
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Typography
						variant="h4"
						fontWeight="600"
						color={colors.grey[900]}
						display="flex"
						justifyContent="center"
					>
						Grau de compactação
					</Typography>
				</Grid>
				<Grid item xs={12} lg={5.5} m={1}>
					<Box borderRadius="5px" border="1px solid black">
						<Box height="35vh" m={0} p={0}>
							<ScatterChart
								data={mockScatterSubbase}
								min={95}
								max={105}
								avg={101.16}
								LI={100}
								parameter="Grau de compactação (%)"
							/>
						</Box>
					</Box>
				</Grid>
				<Grid item xs={12} lg={5.5} m={1}>
					<Box borderRadius="5px" border="1px solid black">
						<Box height="35vh" m={0} p={0}>
							<BoxPlotChart
								data={mockBoxplotSubbase}
								min={95}
								max={105}
								avg={101.16}
								LI={100}
								legends={false}
								rejected2="8"
								rejected3="9"
								rejected5="12"
								rejected6="13"
								rejected7="19"
								rejected8="20"
								rejected9="21"
								rejected10="23"
								parameter="Grau de compactação (%)"
							/>
						</Box>
					</Box>
				</Grid>
			</Grid>
		</Box>
	);
};

export default ProjetoCE060;
