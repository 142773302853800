// install (please try to align the version of installed @nivo packages)
// yarn add @nivo/scatterplot
import { ResponsiveScatterPlot } from "@nivo/scatterplot";

import { useTheme } from "@mui/material";
import { tokens } from "../theme";

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
const ScatterChart = ({ data, min, max, avg, LI, LS, parameter }) => {
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);

	return (
		<>
			{typeof LS != "undefined" ? (
				<ResponsiveScatterPlot
					data={data}
					theme={{
						// added
						axis: {
							domain: {
								line: {
									stroke: colors.grey[800],
								},
							},
							legend: {
								text: {
									fill: colors.grey[800],
								},
							},
							ticks: {
								line: {
									stroke: colors.grey[800],
									strokeWidth: 1,
								},
								text: {
									fill: colors.grey[800],
								},
							},
						},
						legends: {
							text: {
								fill: colors.grey[900],
							},
						},
						tooltip: {
							wrapper: {},
							container: {
								background: "#ffffff",
								color: "#333333",
								fontSize: 12,
							},
							basic: {},
							chip: {},
							table: {},
							tableCell: {},
							tableCellValue: {},
						},
					}}
					margin={{ top: 30, right: 60, bottom: 60, left: 70 }}
					colors={{ scheme: "accent" }}
					xScale={{ type: "linear", min: 0, max: "auto" }}
					xFormat=">-.2f"
					yScale={{ type: "linear", min: min, max: max }}
					yFormat=">-.2f"
					blendMode="multiply"
					axisTop={null}
					axisRight={null}
					axisBottom={{
						orient: "bottom",
						tickSize: 5,
						tickPadding: 5,
						tickRotation: 0,
						tickValues: 5,
						legend: "Número de amostras",
						legendPosition: "middle",
						legendOffset: 35,
						truncateTickAt: 0,
					}}
					axisLeft={{
						orient: "left",
						tickSize: 5,
						tickPadding: 5,
						tickRotation: 0,
						legend: parameter,
						legendPosition: "middle",
						legendOffset: -45,
						truncateTickAt: 0,
					}}
					enableGridY={true}
					enableGridX={false}
					// legends={[
					// 	{
					// 		anchor: "top-right",
					// 		direction: "column",
					// 		justify: false,
					// 		translateX: 90,
					// 		translateY: 0,
					// 		itemWidth: 100,
					// 		itemHeight: 12,
					// 		itemsSpacing: 5,
					// 		itemDirection: "left-to-right",
					// 		symbolSize: 12,
					// 		symbolShape: "circle",
					// 		effects: [
					// 			{
					// 				on: "hover",
					// 				style: {
					// 					itemOpacity: 1,
					// 				},
					// 			},
					// 		],
					// 	},
					// ]}
					markers={[
						{
							axis: "y",
							value: LS,
							lineStyle: {
								stroke: "#3d3d3d",
								strokeWidth: 2,
								strokeDasharray: "3",
							},
							legend: "LS",
							legendOrientation: "horizontal",
							legendPosition: "right",
						},
						{
							axis: "y",
							value: LI,
							lineStyle: {
								stroke: "#3d3d3d",
								strokeWidth: 2,
								strokeDasharray: "3",
							},
							legend: "LI",
							legendOrientation: "horizontal",
							legendPosition: "right",
						},
						{
							axis: "y",
							value: avg,
							lineStyle: {
								stroke: "#db4f4a",
								strokeWidth: 2,
								strokeDasharray: "3",
							},
							legend: "Média",
							legendOrientation: "horizontal",
							legendPosition: "right",
						},
					]}
				/>
			) : (
				<ResponsiveScatterPlot
					data={data}
					theme={{
						// added
						axis: {
							domain: {
								line: {
									stroke: colors.grey[800],
								},
							},
							legend: {
								text: {
									fill: colors.grey[800],
								},
							},
							ticks: {
								line: {
									stroke: colors.grey[800],
									strokeWidth: 1,
								},
								text: {
									fill: colors.grey[800],
								},
							},
						},
						legends: {
							text: {
								fill: colors.grey[900],
							},
						},
						tooltip: {
							wrapper: {},
							container: {
								background: "#ffffff",
								color: "#333333",
								fontSize: 12,
							},
							basic: {},
							chip: {},
							table: {},
							tableCell: {},
							tableCellValue: {},
						},
					}}
					margin={{ top: 30, right: 60, bottom: 60, left: 70 }}
					// colors={colors.greenAccent[400]}
					colors={{ scheme: "accent" }}
					xScale={{ type: "linear", min: 0, max: "auto" }}
					xFormat=">-.2f"
					yScale={{ type: "linear", min: min, max: max }}
					yFormat=">-.2f"
					blendMode="multiply"
					axisTop={null}
					axisRight={null}
					axisBottom={{
						orient: "bottom",
						tickSize: 5,
						tickPadding: 5,
						tickRotation: 0,
						tickValues: 5,
						legend: "Número de amostras",
						legendPosition: "middle",
						legendOffset: 35,
						truncateTickAt: 0,
					}}
					axisLeft={{
						orient: "left",
						tickSize: 5,
						tickPadding: 5,
						tickRotation: 0,
						legend: parameter,
						legendPosition: "middle",
						legendOffset: -45,
						truncateTickAt: 0,
					}}
					enableGridY={true}
					enableGridX={false}
					// legends={[
					// 	{
					// 		anchor: "top-right",
					// 		direction: "column",
					// 		justify: false,
					// 		translateX: 90,
					// 		translateY: 0,
					// 		itemWidth: 100,
					// 		itemHeight: 12,
					// 		itemsSpacing: 5,
					// 		itemDirection: "left-to-right",
					// 		symbolSize: 12,
					// 		symbolShape: "circle",
					// 		effects: [
					// 			{
					// 				on: "hover",
					// 				style: {
					// 					itemOpacity: 1,
					// 				},
					// 			},
					// 		],
					// 	},
					// ]}
					markers={[
						// {
						// 	axis: "y",
						// 	value: LS,
						// 	lineStyle: {
						// 		stroke: "#3d3d3d",
						// 		strokeWidth: 2,
						// 		strokeDasharray: "3",
						// 	},
						// 	legend: "LS",
						// 	legendOrientation: "horizontal",
						// 	legendPosition: "right",
						// },
						{
							axis: "y",
							value: LI,
							lineStyle: {
								stroke: "#3d3d3d",
								strokeWidth: 2,
								strokeDasharray: "3",
							},
							legend: "LI",
							legendOrientation: "horizontal",
							legendPosition: "right",
						},
						{
							axis: "y",
							value: avg,
							lineStyle: {
								stroke: "#db4f4a",
								strokeWidth: 2,
								strokeDasharray: "3",
							},
							legend: "Média",
							legendOrientation: "horizontal",
							legendPosition: "right",
						},
					]}
				/>
			)}
		</>
	);
};

export default ScatterChart;
