import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Button, Grid, TextField, Typography, Box } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import MultipleSelectCheckmarksCamadas from "../scenes/projetos/checkmarks-camadas";
import VPLILS from "./VPLILS";
import { useState } from "react";
import AccordionGranulometria from "../components/AccordionGranulometria";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { validateProps } from "@mui/x-data-grid/internals";
import axios from "axios";
import { montaArrayPeneiras, montaArrayPeneirasDB } from "../aux/functions/functions";

const listaTodasAsPeneiras = [
	{ id: 1, nome: "Peneira ASTM 2” (50,8 mm)" },
	{ id: 2, nome: "Peneira ASTM 1 1/2” (38,1 mm)" },
	{ id: 3, nome: "Peneira ASTM 1” (25,4 mm)" },
	{ id: 4, nome: 'Peneira ASTM 3/4" (19,1 mm)' },
	{ id: 5, nome: 'Peneira ASTM 1/2" (12,7 mm)' },
	{ id: 6, nome: "Peneira ASTM 3/8” (9,5 mm)" },
	{ id: 7, nome: 'Peneira ASTM 1/4" (6,3 mm)' },
	{ id: 8, nome: "Peneira ASTM N° 4 (4,75 mm)" },
	{ id: 9, nome: "Peneira ASTM N° 8 (2,36 mm)" },
	{ id: 10, nome: "Peneira ASTM N° 10 (2,0 mm)" },
	{ id: 11, nome: "Peneira ASTM N° 16 (1,18 mm)" },
	{ id: 12, nome: "Peneira ASTM N° 30 (0,6 mm)" },
	{ id: 13, nome: "Peneira ASTM N° 40 (0,425 mm)" },
	{ id: 14, nome: "Peneira ASTM N° 50 (0,33 mm)" },
	{ id: 15, nome: "Peneira ASTM N° 80 (0,18 mm)" },
	{ id: 16, nome: "Peneira ASTM N° 100 (0,15 mm)" },
	{ id: 17, nome: "Peneira ASTM N° 200 (0,075 mm)" },
];

function BasicChips(props) {
	return (
		<Box
			sx={{
				display: "flex",
				margin: 0,
				padding: 0,
				gap: 1,
				flexWrap: "wrap",
			}}
		>
			{props.value.map((e, index) => {
				return e.nomePeneira !== null && <Chip key={index} label={e.nomePeneira} variant="outlined" size="small" />;
			})}
		</Box>
	);
}

const AccordionModel = (props) => {
	let granulometriaAccordion = "";
	const [nomeSegmento, setNomeSegmento] = useState(props.nomeSegmento);
	const [expanded, setExpanded] = useState(false);
	const [alertaSnackbar, setAlertaSnackbar] = useState();
	const [camposVazios, setCamposVazios] = useState(false);
	const [alertStatus, setAlertStatus] = useState("warning");
	const [openGranu, setOpenGranu] = useState(false);

	const handleChange = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
		props.fechaAccordion();
	};

	const [openSnackBar, setOpenSnackBar] = useState(false);

	const handleClickSnackBar = () => {
		setOpenSnackBar(true);
	};

	const handleCloseSnackBar = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}
		setOpenSnackBar(false);
	};

	let arrayPeneirasExibir;

	function handleOnVisible(e) {
		props.handleVisible();
	}

	function verificaVPLILS(value) {
		if (camposVazios) {
			setAlertaSnackbar("Preencher campos vazios");
			handleClickSnackBar();
			setCamposVazios(false);
		} else {
			props.handleVisible({
				valoresEntrada: value.valoresEntrada,
				numeroAmostras: value.numeroAmostras,
			});
		}
	}

	//	console.log(props.dadosGranulometria?.[0].nomePeneira);

	let efeito = false;

	function handlePeneiras(array) {
		let arrayOutput = [];
		let arrayVelho = [];
		let arrayNovo = [];
		let arrayInseri = [];
		let arrayAtualiza = [];
		let arrayExclui = [];
		let arrayVelho1 = [];

		if (nomeSegmento === null || nomeSegmento === "") {
			setAlertStatus("warning");
			setAlertaSnackbar("Preehncher campo segmento");
			handleClickSnackBar();
		} else {
			if (props.dadosGranulometria?.[0].idqualipav_ensaio !== null) {
				arrayVelho = props.dadosGranulometria
					.filter((e) => e.nomePeneira !== null)
					.map((e) => e.nomePeneira)
					.map((value) => listaTodasAsPeneiras.find((e) => value === e.nome).id)
					.sort((a, b) => a - b);
			}
			arrayNovo = array.map((value) => listaTodasAsPeneiras.find((e) => value === e.nome).id).sort((a, b) => a - b);
			const qualipav_camadamedicao_idcamadaMedicao = props.dadosGranulometria[0].idcamadaMedicao;
			arrayOutput = montaArrayPeneiras(arrayNovo, qualipav_camadamedicao_idcamadaMedicao, nomeSegmento);

			arrayInseri = montaArrayPeneiras(
				arrayNovo.filter((value) => !arrayVelho.includes(value)),
				qualipav_camadamedicao_idcamadaMedicao,
				nomeSegmento
			);
			arrayAtualiza = montaArrayPeneirasDB(
				arrayNovo.filter((value) => arrayVelho.includes(value)),
				qualipav_camadamedicao_idcamadaMedicao,
				nomeSegmento
			);
			arrayExclui = montaArrayPeneirasDB(
				listaTodasAsPeneiras
					.map((e) => e.id)
					.filter((value) => !arrayNovo.includes(value))
					.filter((value) => arrayVelho.includes(value)),
				qualipav_camadamedicao_idcamadaMedicao,
				nomeSegmento
			);

			if (cadastraPeneiras(arrayInseri, arrayAtualiza, arrayExclui)) {
				setAlertStatus("success");
				setAlertaSnackbar("Salvo!");
				handleClickSnackBar();
				//props.atualizaAccordionGeral(true);
				props.efeitoInserirAtualizar(true);
				props.atualizaInfoCamada(true);
			} else {
				setAlertStatus("warning");
				setAlertaSnackbar("Erro, tente novamento mais tarde!");
				handleClickSnackBar();
			}
		}
	}

	async function cadastraPeneiras(inseri, atualiza, exclui) {
		try {
			await axios
				.post(`${process.env.REACT_APP_URL_BASE}/peneiras`, {
					inseri,
					atualiza,
					exclui,
				})
				.then((response) => {
					return true;
				});
		} catch (error) {
			console.log("Erro ao adicionar uma medição:", error);
			return false;
		}
	}

	// function teste() {
	// 	const arrayNomeDasPeneiras = props.dadosGranulometria.map((e) => e.nomePeneira);
	// 	return array;
	// }

	if (props.parametro === "Granulometria") {
		//const arrayNomeDasPeneiras = <BasicChips />;
		arrayPeneirasExibir = <BasicChips value={props.dadosGranulometria} />;
		granulometriaAccordion = (
			<AccordionGranulometria
				dados={props.dadosGranulometria}
				handleVisibleGranulometria={(objeto) =>
					props.handleVisible({
						valoresEntrada: objeto.valoresEntrada,
						trechoEntrada: objeto.trechoEntrada,
						dataEntrada: objeto.dataEntrada,
						numeroAmostras: objeto.numeroAmostras,
						idPeneira: objeto.idPeneira,
						vp: objeto.valorDoProjeto,
						li: objeto.limiteInferior,
						ls: objeto.limiteSuperior,
						nomeSegmento: nomeSegmento,
						parametro: "Granulometria",
						idparametroValores: objeto.idparametroValores,
					})
				}
				handleSalvaInformacoes={(objeto) =>
					props.handleSalva({
						valoresEntrada: objeto.valoresEntrada,
						numeroAmostras: objeto.numeroAmostras,
						idPeneira: objeto.idPeneira,
						vp: objeto.valorDoProjeto,
						li: objeto.limiteInferior,
						ls: objeto.limiteSuperior,
						nomeSegmento: nomeSegmento,
						parametro: "Granulometria",
						nomePeneira: objeto.nomePeneira,
					})
				}
				nomePeneira={props.nomePeneira}
				// atualizaAccordionGeral={(e) => (efeito = e)}
				handlePeneirasAccordionGeral={handlePeneiras}
			/>
		);
	} else {
		granulometriaAccordion = "";
	}

	return (
		<>
			<Accordion
				//expanded={expanded === "'" + props.parametro + props.id + "'"}
				onChange={handleChange("'" + props.parametro + props.id + "'")}
				//expanded={props.expandedInfo || null}
				//{...(props.expandedInfo ? { expanded: true } : null)}
				//{...(openGranu ? { defaultExpanded: true } : { defaultExpanded: "" })}
			>
				<AccordionSummary expandIcon={<ArrowDropDownIcon />} aria-controls="panel-content" id="panel-header">
					<Typography sx={{ width: "33%" }} variant="h6" fontWeight="700">
						{props.parametro}
					</Typography>
					<Typography sx={{ color: "text.secondary" }}>{arrayPeneirasExibir || ""}</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<Typography>
						<Grid container mb={1} spacing={1}>
							<Grid item xs={12} {...(props.parametro === "Granulometria" ? { lg: 4.5 } : { lg: 4.5 })}>
								<TextField
									id={props.parametro.replace(/[^A-Z0-9]+/gi, "_")}
									label="Segmento"
									variant="outlined"
									value={nomeSegmento}
									onChange={(e) => setNomeSegmento(e.target.value)}
									InputLabelProps={{ shrink: true }}
									fullWidth
								/>
							</Grid>
							{granulometriaAccordion || (
								<VPLILS
									//onVisible={(e) => verificaVPLILS(e)}
									parametro={props.parametro.replace(/[^A-Z0-9]+/gi, "_")}
									vp={props.valorDeProjeto}
									li={props.LI}
									ls={props.LS}
									//quantidadeDeAmostras={numeroDeAmostras}
									quantidadeDeAmostras={props.quantidadeDeAmostras}
									valoresDeEntrada={props.valoresDeEntrada}
									// verificaCamposVazios={(value) => {
									// 	props.handleVisible({
									// 		valoresEntrada: value.valoresEntrada,
									// 		numeroAmostras: value.numeroAmostras,
									// 	});
									// }}
									idparametroValores={props.idparametroValores}
									onVisible={(objeto) =>
										props.handleVisible({
											valoresEntrada: objeto.valoresEntrada,
											trechoEntrada: props.trechoEntrada,
											dataEntrada: props.dataEntrada,
											numeroAmostras: objeto.numeroAmostras,
											parametro: props.parametro,
											vp: objeto.valorDoProjeto,
											li: objeto.limiteInferior,
											ls: objeto.limiteSuperior,
											nomeSegmento: nomeSegmento,
											idPeneira: 99,
											idparametroValores: props.idparametroValores,
										})
									}
									salvaInformacoes={(objeto) =>
										props.handleSalva({
											valoresEntrada: objeto.valoresEntrada,
											numeroAmostras: objeto.numeroAmostras,
											parametro: props.parametro,
											vp: objeto.valorDoProjeto,
											li: objeto.limiteInferior,
											ls: objeto.limiteSuperior,
											nomeSegmento: nomeSegmento,
											idPeneira: 99,
										})
									}
									trechoEntrada={props.trechoEntrada}
									dataEntrada={props.dataEntrada}
								/>
							)}
						</Grid>
					</Typography>
				</AccordionDetails>
			</Accordion>
			<Snackbar
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
				open={openSnackBar}
				autoHideDuration={1500}
				onClose={handleCloseSnackBar}
			>
				<Alert onClose={handleCloseSnackBar} severity={alertStatus} sx={{ width: "100%" }}>
					{alertaSnackbar}
				</Alert>
			</Snackbar>
		</>
	);
};

export default AccordionModel;
