import { INFO_PROJETO_ALTERADA } from "./actionTypes";

function alterarInfoProjeto(novoInfoProjeto) {
	return {
		type: INFO_PROJETO_ALTERADA,
		payload: novoInfoProjeto,
	};
}

export { alterarInfoProjeto };
