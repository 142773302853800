import { ARRAY_CAMADAS_ALTERADA } from "../actions/actionTypes";

const initialState = [];

function camadasReducer(state = initialState, action) {
	switch (action.type) {
		case ARRAY_CAMADAS_ALTERADA:
			state = [];
			state = action.payload;
			return state;
		default:
			return state;
	}
}

export default camadasReducer;
