import { tokens, colorsGraphics } from "../theme";

export const mockDataProjects = [
	{
		id: 1,
		name: "CE 040",
		initialDate: "03/04/2023",
	},
	{
		id: 2,
		name: "Cersei Lannister",
		email: "cerseilannister@gmail.com",
		age: 42,
		phone: "(421)314-2288",
		access: "manager",
	},
	{
		id: 3,
		name: "Jaime Lannister",
		email: "jaimelannister@gmail.com",
		age: 45,
		phone: "(422)982-6739",
		access: "user",
	},
	{
		id: 4,
		name: "Anya Stark",
		email: "anyastark@gmail.com",
		age: 16,
		phone: "(921)425-6742",
		access: "admin",
	},
	{
		id: 5,
		name: "Daenerys Targaryen",
		email: "daenerystargaryen@gmail.com",
		age: 31,
		phone: "(421)445-1189",
		access: "user",
	},
	{
		id: 6,
		name: "Ever Melisandre",
		email: "evermelisandre@gmail.com",
		age: 150,
		phone: "(232)545-6483",
		access: "manager",
	},
	{
		id: 7,
		name: "Ferrara Clifford",
		email: "ferraraclifford@gmail.com",
		age: 44,
		phone: "(543)124-0123",
		access: "user",
	},
	{
		id: 8,
		name: "Rossini Frances",
		email: "rossinifrances@gmail.com",
		age: 36,
		phone: "(222)444-5555",
		access: "user",
	},
	{
		id: 9,
		name: "Harvey Roxie",
		email: "harveyroxie@gmail.com",
		age: 65,
		phone: "(444)555-6239",
		access: "admin",
	},
];

export const mockDataContacts = [
	{
		id: 1,
		nome: "Medição 12",
		trecho: "km 30",
		data: "30/10/2023",
	},
	{
		id: 2,
		nome: "Medição 01",
		trecho: "km 30",
		data: "30/10/2023",
	},
	{
		id: 3,
		nome: "Medição 06",
		trecho: "km 30",
		data: "30/10/2023",
	},
	{
		id: 4,
		nome: "Medição 11",
		trecho: "km 30",
		data: "30/10/2023",
	},
	{
		id: 5,
		nome: "Medição 12",
		trecho: "km 30",
		data: "30/10/2023",
	},
	{
		id: 6,
		nome: "Medição 02",
		trecho: "km 30",
		data: "30/10/2023",
	},
	{
		id: 7,
		nome: "Medição 03",
		trecho: "km 30",
		data: "30/10/2023",
	},
	{
		id: 8,
		nome: "Medição 11",
		trecho: "km 30",
		data: "30/10/2023",
	},
	{
		id: 9,
		nome: "Medição 05",
		trecho: "km 30",
		data: "30/10/2023",
	},
	{
		id: 10,
		nome: "Medição 07",
		trecho: "km 30",
		data: "30/10/2023",
	},
];

export const mockDataInvoices = [
	{
		id: 1,
		name: "Jon Snow",
		email: "jonsnow@gmail.com",
		cost: "21.24",
		phone: "(665)121-5454",
		date: "03/12/2022",
	},
	{
		id: 2,
		name: "Cersei Lannister",
		email: "cerseilannister@gmail.com",
		cost: "1.24",
		phone: "(421)314-2288",
		date: "06/15/2021",
	},
	{
		id: 3,
		name: "Jaime Lannister",
		email: "jaimelannister@gmail.com",
		cost: "11.24",
		phone: "(422)982-6739",
		date: "05/02/2022",
	},
	{
		id: 4,
		name: "Anya Stark",
		email: "anyastark@gmail.com",
		cost: "80.55",
		phone: "(921)425-6742",
		date: "03/21/2022",
	},
	{
		id: 5,
		name: "Daenerys Targaryen",
		email: "daenerystargaryen@gmail.com",
		cost: "1.24",
		phone: "(421)445-1189",
		date: "01/12/2021",
	},
	{
		id: 6,
		name: "Ever Melisandre",
		email: "evermelisandre@gmail.com",
		cost: "63.12",
		phone: "(232)545-6483",
		date: "11/02/2022",
	},
	{
		id: 7,
		name: "Ferrara Clifford",
		email: "ferraraclifford@gmail.com",
		cost: "52.42",
		phone: "(543)124-0123",
		date: "02/11/2022",
	},
	{
		id: 8,
		name: "Rossini Frances",
		email: "rossinifrances@gmail.com",
		cost: "21.24",
		phone: "(222)444-5555",
		date: "05/02/2021",
	},
];

export const mockTransactions = [
	{
		txId: "CE 178",
		user: "Medição 12",
		date: "03/02/2024",
		cost: "9,6",
	},
	{
		txId: "CE 155",
		user: "Medição 12",
		date: "20/01/2024",
		cost: "10,0",
	},
	{
		txId: "CE 060",
		user: "Medição 12",
		date: "03/11/2023",
		cost: "15,5",
	},
	{
		txId: "CE 040",
		user: "Medição 12",
		date: "13/10/2023",
		cost: "9,0",
	},
	{
		txId: "CE 010",
		user: "Medição 12",
		date: "30/07/2023",
		cost: "5,6",
	},
	{
		txId: "CE 085",
		user: "Medição 12",
		date: "03/12/2022",
		cost: "8",
	},
	{
		txId: "CE 265",
		user: "Medição 12",
		date: "01/06/2021",
		cost: "8",
	},
	{
		txId: "CE 240",
		user: "Medição 12",
		date: "01/02/2021",
		cost: "8",
	},
	{
		txId: "CE 040",
		user: "Medição 12",
		date: "12/12/2020",
		cost: "8",
	},
];

export const mockBarData = [
	{
		Rodovia: "CE 178",
		CV: 9.6,
		color: colorsGraphics[0],
	},
	{
		Rodovia: "CE 155",
		CV: 10.0,
		color: colorsGraphics[1],
	},
	{
		Rodovia: "CE 010",
		CV: 5.6,
		color: colorsGraphics[2],
	},
	{
		Rodovia: "CE 060",
		CV: 15.5,
		color: colorsGraphics[3],
	},
	{
		Rodovia: "CE 040",
		CV: 9.0,
		color: colorsGraphics[4],
	},
	{
		Rodovia: "CE 085",
		CV: 8.0,
		color: colorsGraphics[5],
	},
];

export const mockPieData = [
	{
		id: "hack",
		label: "hack",
		value: 239,
		color: "hsl(104, 70%, 50%)",
	},
	{
		id: "make",
		label: "make",
		value: 170,
		color: "hsl(162, 70%, 50%)",
	},
	{
		id: "go",
		label: "go",
		value: 322,
		color: "hsl(291, 70%, 50%)",
	},
	{
		id: "lisp",
		label: "lisp",
		value: 503,
		color: "hsl(229, 70%, 50%)",
	},
	{
		id: "scala",
		label: "scala",
		value: 584,
		color: "hsl(344, 70%, 50%)",
	},
];

export const mockLineData = [
	{
		id: "CE 178",
		color: colorsGraphics[0],
		data: [
			{
				x: "Rolamento",
				y: 6,
			},
			{
				x: "Ligação",
				y: 7,
			},
			{
				x: "Base",
				y: 5,
			},
			{
				x: "Sub-base",
				y: 7,
			},
			{
				x: "Subleito",
				y: 2,
			},
		],
	},
	{
		id: "CE 155",
		color: colorsGraphics[1],
		data: [
			{
				x: "Rolamento",
				y: 9,
			},
			{
				x: "Ligação",
				y: 4,
			},
			{
				x: "Base",
				y: 4,
			},
			{
				x: "Sub-base",
				y: 5,
			},
			{
				x: "Subleito",
				y: 3,
			},
		],
	},
	{
		id: "CE 010",
		color: colorsGraphics[2],
		data: [
			{
				x: "Rolamento",
				y: 1.5,
			},
			{
				x: "Ligação",
				y: 2.2,
			},
			{
				x: "Base",
				y: 3.8,
			},
			{
				x: "Sub-base",
				y: 2.3,
			},
			{
				x: "Subleito",
				y: 2.5,
			},
		],
	},
	{
		id: "CE 060",
		color: colorsGraphics[3],
		data: [
			{
				x: "Rolamento",
				y: 4.2,
			},
			{
				x: "Ligação",
				y: 3,
			},
			{
				x: "Base",
				y: 7.3,
			},
			{
				x: "Sub-base",
				y: 10.3,
			},
			{
				x: "Subleito",
				y: 2,
			},
		],
	},
	{
		id: "CE 040",
		color: colorsGraphics[4],
		data: [
			{
				x: "Rolamento",
				y: 1,
			},
			{
				x: "Ligação",
				y: 3,
			},
			{
				x: "Base",
				y: 2,
			},
			{
				x: "Sub-base",
				y: 8,
			},
			{
				x: "Subleito",
				y: 2,
			},
		],
	},
	{
		id: "CE 085",
		color: colorsGraphics[5],
		data: [
			{
				x: "Rolamento",
				y: 4,
			},
			{
				x: "Ligação",
				y: 3.6,
			},
			{
				x: "Base",
				y: 1.6,
			},
			{
				x: "Sub-base",
				y: 10,
			},
			{
				x: "Subleito",
				y: 6.5,
			},
		],
	},
];

export const mockScatterCAP = [
	{
		id: "% CAP",
		data: [
			{
				x: 1,
				y: 5.38,
			},
			{
				x: 2,
				y: 5.47,
			},
			{
				x: 3,
				y: 5.46,
			},
			{
				x: 4,
				y: 5.45,
			},
			{
				x: 5,
				y: 5.4,
			},
			{
				x: 6,
				y: 5.5,
			},
			{
				x: 7,
				y: 5.6,
			},
			{
				x: 8,
				y: 6.55,
			},
			{
				x: 9,
				y: 5.47,
			},
			{
				x: 10,
				y: 5.4,
			},
			{
				x: 11,
				y: 5.36,
			},
			{
				x: 12,
				y: 5.4,
			},
			{
				x: 13,
				y: 5.44,
			},
			{
				x: 14,
				y: 5.43,
			},
			{
				x: 15,
				y: 5.43,
			},
			{
				x: 16,
				y: 5.41,
			},
			{
				x: 17,
				y: 5.4,
			},
			{
				x: 18,
				y: 5.5,
			},
			{
				x: 19,
				y: 5.55,
			},
			{
				x: 20,
				y: 5.6,
			},
			{
				x: 21,
				y: 5.3,
			},
			{
				x: 22,
				y: 5.42,
			},
			{
				x: 23,
				y: 5.37,
			},
			{
				x: 24,
				y: 5.3,
			},
			{
				x: 25,
				y: 5.4,
			},
			{
				x: 26,
				y: 5.45,
			},
			{
				x: 27,
				y: 5.5,
			},
			{
				x: 28,
				y: 5.45,
			},
			{
				x: 29,
				y: 5.4,
			},
			{
				x: 30,
				y: 5.52,
			},
			{
				x: 31,
				y: 5.53,
			},
			{
				x: 32,
				y: 5.55,
			},
			{
				x: 33,
				y: 5.5,
			},
			{
				x: 34,
				y: 5.56,
			},
			{
				x: 35,
				y: 5.54,
			},
			{
				x: 36,
				y: 5.48,
			},
			{
				x: 37,
				y: 5.37,
			},
			{
				x: 38,
				y: 5.4,
			},
			{
				x: 39,
				y: 5.62,
			},
			{
				x: 40,
				y: 5.41,
			},
			{
				x: 41,
				y: 5.35,
			},
			{
				x: 42,
				y: 5.44,
			},
			{
				x: 43,
				y: 5.43,
			},
			{
				x: 44,
				y: 5.38,
			},
			{
				x: 45,
				y: 5.36,
			},
			{
				x: 46,
				y: 5.48,
			},
			{
				x: 47,
				y: 5.41,
			},
			{
				x: 48,
				y: 5.43,
			},
			{
				x: 49,
				y: 5.34,
			},
			{
				x: 50,
				y: 5.41,
			},
			{
				x: 51,
				y: 5.33,
			},
			{
				x: 52,
				y: 5.42,
			},
			{
				x: 53,
				y: 5.58,
			},
			{
				x: 54,
				y: 5.43,
			},
			{
				x: 55,
				y: 5.52,
			},
			{
				x: 56,
				y: 5.7,
			},
			{
				x: 57,
				y: 5.42,
			},
			{
				x: 58,
				y: 5.29,
			},
			{
				x: 59,
				y: 5.61,
			},
			{
				x: 60,
				y: 5.81,
			},
			{
				x: 61,
				y: 5.46,
			},
			{
				x: 62,
				y: 5.69,
			},
			{
				x: 63,
				y: 5.42,
			},
			{
				x: 64,
				y: 5.44,
			},
			{
				x: 65,
				y: 5.51,
			},
			{
				x: 66,
				y: 5.44,
			},
			{
				x: 67,
				y: 5.51,
			},
			{
				x: 68,
				y: 5.41,
			},
			{
				x: 69,
				y: 5.47,
			},
			{
				x: 70,
				y: 5.39,
			},
			{
				x: 71,
				y: 5.78,
			},
			{
				x: 72,
				y: 5.43,
			},
			{
				x: 73,
				y: 5.4,
			},
			{
				x: 74,
				y: 5.56,
			},
			{
				x: 75,
				y: 5.48,
			},
			{
				x: 76,
				y: 5.43,
			},
			{
				x: 77,
				y: 5.54,
			},
			{
				x: 78,
				y: 5.39,
			},
			{
				x: 79,
				y: 5.5,
			},
			{
				x: 80,
				y: 5.54,
			},
			{
				x: 81,
				y: 5.54,
			},
			{
				x: 82,
				y: 5.52,
			},
			{
				x: 83,
				y: 5.49,
			},
			{
				x: 84,
				y: 5.46,
			},
			{
				x: 85,
				y: 5.62,
			},
			{
				x: 86,
				y: 5.48,
			},
			{
				x: 87,
				y: 5.45,
			},
			{
				x: 88,
				y: 5.46,
			},
			{
				x: 89,
				y: 5.41,
			},
			{
				x: 90,
				y: 5.38,
			},
			{
				x: 91,
				y: 5.42,
			},
			{
				x: 92,
				y: 5.49,
			},
			{
				x: 93,
				y: 5.48,
			},
			{
				x: 94,
				y: 5.44,
			},
			{
				x: 95,
				y: 5.38,
			},
			{
				x: 96,
				y: 5.54,
			},
			{
				x: 97,
				y: 5.55,
			},
			{
				x: 98,
				y: 5.47,
			},
			{
				x: 99,
				y: 5.56,
			},
			{
				x: 100,
				y: 5.44,
			},
			{
				x: 101,
				y: 5.44,
			},
			{
				x: 102,
				y: 5.41,
			},
			{
				x: 103,
				y: 5.39,
			},
			{
				x: 104,
				y: 5.43,
			},
			{
				x: 105,
				y: 5.47,
			},
			{
				x: 106,
				y: 5.19,
			},
			{
				x: 107,
				y: 5.55,
			},
			{
				x: 108,
				y: 5.49,
			},
			{
				x: 109,
				y: 5.56,
			},
			{
				x: 110,
				y: 5.5,
			},
			{
				x: 111,
				y: 5.53,
			},
			{
				x: 112,
				y: 5.43,
			},
			{
				x: 113,
				y: 5.46,
			},
			{
				x: 114,
				y: 5.44,
			},
			{
				x: 115,
				y: 5.48,
			},
			{
				x: 116,
				y: 5.62,
			},
			{
				x: 117,
				y: 5.48,
			},
			{
				x: 118,
				y: 5.43,
			},
			{
				x: 119,
				y: 5.44,
			},
			{
				x: 120,
				y: 5.45,
			},
			{
				x: 121,
				y: 5.39,
			},
			{
				x: 122,
				y: 5.4,
			},
			{
				x: 123,
				y: 5.38,
			},
			{
				x: 124,
				y: 5.45,
			},
			{
				x: 125,
				y: 5.44,
			},
			{
				x: 126,
				y: 5.56,
			},
			{
				x: 127,
				y: 5.47,
			},
			{
				x: 128,
				y: 5.57,
			},
			{
				x: 129,
				y: 5.4,
			},
			{
				x: 130,
				y: 5.55,
			},
			{
				x: 131,
				y: 5.36,
			},
			{
				x: 132,
				y: 5.39,
			},
			{
				x: 133,
				y: 5.32,
			},
			{
				x: 134,
				y: 5.5,
			},
			{
				x: 135,
				y: 5.68,
			},
			{
				x: 136,
				y: 5.44,
			},
			{
				x: 137,
				y: 5.71,
			},
			{
				x: 138,
				y: 5.79,
			},
			{
				x: 139,
				y: 5.42,
			},
			{
				x: 140,
				y: 5.37,
			},
			{
				x: 141,
				y: 5.44,
			},
			{
				x: 142,
				y: 5.42,
			},
			{
				x: 143,
				y: 5.41,
			},
			{
				x: 144,
				y: 5.41,
			},
			{
				x: 145,
				y: 5.2,
			},
			{
				x: 146,
				y: 5.3,
			},
			{
				x: 147,
				y: 5.44,
			},
			{
				x: 148,
				y: 5.39,
			},
			{
				x: 149,
				y: 5.41,
			},
			{
				x: 150,
				y: 5.51,
			},
			{
				x: 151,
				y: 5.7,
			},
			{
				x: 152,
				y: 5.39,
			},
			{
				x: 153,
				y: 5.52,
			},
			{
				x: 154,
				y: 5.41,
			},
			{
				x: 155,
				y: 5.4,
			},
			{
				x: 156,
				y: 5.57,
			},
			{
				x: 157,
				y: 5.62,
			},
			{
				x: 158,
				y: 5.66,
			},
			{
				x: 159,
				y: 5.43,
			},
			{
				x: 160,
				y: 5.43,
			},
			{
				x: 161,
				y: 5.46,
			},
			{
				x: 162,
				y: 5.47,
			},
			{
				x: 163,
				y: 5.54,
			},
			{
				x: 164,
				y: 5.41,
			},
			{
				x: 165,
				y: 5.66,
			},
			{
				x: 166,
				y: 5.51,
			},
			{
				x: 167,
				y: 5.42,
			},
			{
				x: 168,
				y: 5.43,
			},
			{
				x: 169,
				y: 5.48,
			},
			{
				x: 170,
				y: 5.39,
			},
			{
				x: 171,
				y: 5.42,
			},
			{
				x: 172,
				y: 5.47,
			},
			{
				x: 173,
				y: 5.43,
			},
			{
				x: 174,
				y: 5.44,
			},
			{
				x: 175,
				y: 5.44,
			},
			{
				x: 176,
				y: 5.51,
			},
			{
				x: 177,
				y: 5.56,
			},
			{
				x: 178,
				y: 5.42,
			},
			{
				x: 179,
				y: 5.4,
			},
			{
				x: 180,
				y: 5.51,
			},
			{
				x: 181,
				y: 5.52,
			},
			{
				x: 182,
				y: 5.56,
			},
			{
				x: 183,
				y: 5.49,
			},
			{
				x: 184,
				y: 5.56,
			},
			{
				x: 185,
				y: 5.46,
			},
			{
				x: 186,
				y: 5.45,
			},
			{
				x: 187,
				y: 5.46,
			},
			{
				x: 188,
				y: 5.58,
			},
			{
				x: 189,
				y: 5.35,
			},
			{
				x: 190,
				y: 5.49,
			},
			{
				x: 191,
				y: 5.51,
			},
			{
				x: 192,
				y: 5.45,
			},
			{
				x: 193,
				y: 5.56,
			},
			{
				x: 194,
				y: 5.46,
			},
			{
				x: 195,
				y: 5.4,
			},
			{
				x: 196,
				y: 5.45,
			},
			{
				x: 197,
				y: 5.49,
			},
			{
				x: 198,
				y: 5.48,
			},
		],
	},
];

export const mockBoxplotCAP = [
	{
		group: "10",
		subgroup: "Medição (% CAP)",
		value: 5.38,
		sd: 0.18,
		n: 46,
	},
	{
		group: "10",
		subgroup: "Medição (% CAP)",
		value: 5.47,
		sd: 0.18,
		n: 46,
	},
	{
		group: "10",
		subgroup: "Medição (% CAP)",
		value: 5.46,
		sd: 0.18,
		n: 46,
	},
	{
		group: "10",
		subgroup: "Medição (% CAP)",
		value: 5.45,
		sd: 0.18,
		n: 46,
	},
	{
		group: "10",
		subgroup: "Medição (% CAP)",
		value: 5.4,
		sd: 0.18,
		n: 46,
	},
	{
		group: "10",
		subgroup: "Medição (% CAP)",
		value: 5.5,
		sd: 0.18,
		n: 46,
	},
	{
		group: "10",
		subgroup: "Medição (% CAP)",
		value: 5.6,
		sd: 0.18,
		n: 46,
	},
	{
		group: "10",
		subgroup: "Medição (% CAP)",
		value: 6.55,
		sd: 0.18,
		n: 46,
	},
	{
		group: "10",
		subgroup: "Medição (% CAP)",
		value: 5.47,
		sd: 0.18,
		n: 46,
	},
	{
		group: "10",
		subgroup: "Medição (% CAP)",
		value: 5.4,
		sd: 0.18,
		n: 46,
	},
	{
		group: "10",
		subgroup: "Medição (% CAP)",
		value: 5.36,
		sd: 0.18,
		n: 46,
	},
	{
		group: "10",
		subgroup: "Medição (% CAP)",
		value: 5.4,
		sd: 0.18,
		n: 46,
	},
	{
		group: "10",
		subgroup: "Medição (% CAP)",
		value: 5.44,
		sd: 0.18,
		n: 46,
	},
	{
		group: "10",
		subgroup: "Medição (% CAP)",
		value: 5.43,
		sd: 0.18,
		n: 46,
	},
	{
		group: "10",
		subgroup: "Medição (% CAP)",
		value: 5.43,
		sd: 0.18,
		n: 46,
	},
	{
		group: "10",
		subgroup: "Medição (% CAP)",
		value: 5.41,
		sd: 0.18,
		n: 46,
	},
	{
		group: "10",
		subgroup: "Medição (% CAP)",
		value: 5.4,
		sd: 0.18,
		n: 46,
	},
	{
		group: "10",
		subgroup: "Medição (% CAP)",
		value: 5.5,
		sd: 0.18,
		n: 46,
	},
	{
		group: "10",
		subgroup: "Medição (% CAP)",
		value: 5.55,
		sd: 0.18,
		n: 46,
	},
	{
		group: "10",
		subgroup: "Medição (% CAP)",
		value: 5.6,
		sd: 0.18,
		n: 46,
	},
	{
		group: "10",
		subgroup: "Medição (% CAP)",
		value: 5.3,
		sd: 0.18,
		n: 46,
	},
	{
		group: "10",
		subgroup: "Medição (% CAP)",
		value: 5.42,
		sd: 0.18,
		n: 46,
	},
	{
		group: "10",
		subgroup: "Medição (% CAP)",
		value: 5.37,
		sd: 0.18,
		n: 46,
	},
	{
		group: "10",
		subgroup: "Medição (% CAP)",
		value: 5.3,
		sd: 0.18,
		n: 46,
	},
	{
		group: "10",
		subgroup: "Medição (% CAP)",
		value: 5.4,
		sd: 0.18,
		n: 46,
	},
	{
		group: "10",
		subgroup: "Medição (% CAP)",
		value: 5.45,
		sd: 0.18,
		n: 46,
	},
	{
		group: "10",
		subgroup: "Medição (% CAP)",
		value: 5.5,
		sd: 0.18,
		n: 46,
	},
	{
		group: "10",
		subgroup: "Medição (% CAP)",
		value: 5.45,
		sd: 0.18,
		n: 46,
	},
	{
		group: "10",
		subgroup: "Medição (% CAP)",
		value: 5.4,
		sd: 0.18,
		n: 46,
	},
	{
		group: "10",
		subgroup: "Medição (% CAP)",
		value: 5.52,
		sd: 0.18,
		n: 46,
	},
	{
		group: "10",
		subgroup: "Medição (% CAP)",
		value: 5.53,
		sd: 0.18,
		n: 46,
	},
	{
		group: "10",
		subgroup: "Medição (% CAP)",
		value: 5.55,
		sd: 0.18,
		n: 46,
	},
	{
		group: "10",
		subgroup: "Medição (% CAP)",
		value: 5.5,
		sd: 0.18,
		n: 46,
	},
	{
		group: "10",
		subgroup: "Medição (% CAP)",
		value: 5.56,
		sd: 0.18,
		n: 46,
	},
	{
		group: "10",
		subgroup: "Medição (% CAP)",
		value: 5.54,
		sd: 0.18,
		n: 46,
	},
	{
		group: "10",
		subgroup: "Medição (% CAP)",
		value: 5.48,
		sd: 0.18,
		n: 46,
	},
	{
		group: "10",
		subgroup: "Medição (% CAP)",
		value: 5.37,
		sd: 0.18,
		n: 46,
	},
	{
		group: "10",
		subgroup: "Medição (% CAP)",
		value: 5.4,
		sd: 0.18,
		n: 46,
	},
	{
		group: "10",
		subgroup: "Medição (% CAP)",
		value: 5.62,
		sd: 0.18,
		n: 46,
	},
	{
		group: "10",
		subgroup: "Medição (% CAP)",
		value: 5.41,
		sd: 0.18,
		n: 46,
	},
	{
		group: "10",
		subgroup: "Medição (% CAP)",
		value: 5.35,
		sd: 0.18,
		n: 46,
	},
	{
		group: "10",
		subgroup: "Medição (% CAP)",
		value: 5.44,
		sd: 0.18,
		n: 46,
	},
	{
		group: "10",
		subgroup: "Medição (% CAP)",
		value: 5.43,
		sd: 0.18,
		n: 46,
	},
	{
		group: "10",
		subgroup: "Medição (% CAP)",
		value: 5.38,
		sd: 0.18,
		n: 46,
	},
	{
		group: "10",
		subgroup: "Medição (% CAP)",
		value: 5.36,
		sd: 0.18,
		n: 46,
	},
	{
		group: "10",
		subgroup: "Medição (% CAP)",
		value: 5.48,
		sd: 0.18,
		n: 46,
	},
	{
		group: "11",
		subgroup: "Medição (% CAP)",
		value: 5.41,
		sd: 0.08,
		n: 7,
	},
	{
		group: "11",
		subgroup: "Medição (% CAP)",
		value: 5.43,
		sd: 0.08,
		n: 7,
	},
	{
		group: "11",
		subgroup: "Medição (% CAP)",
		value: 5.34,
		sd: 0.08,
		n: 7,
	},
	{
		group: "11",
		subgroup: "Medição (% CAP)",
		value: 5.41,
		sd: 0.08,
		n: 7,
	},
	{
		group: "11",
		subgroup: "Medição (% CAP)",
		value: 5.33,
		sd: 0.08,
		n: 7,
	},
	{
		group: "11",
		subgroup: "Medição (% CAP)",
		value: 5.42,
		sd: 0.08,
		n: 7,
	},
	{
		group: "11",
		subgroup: "Medição (% CAP)",
		value: 5.58,
		sd: 0.08,
		n: 7,
	},
	{
		group: "13",
		subgroup: "Medição (% CAP)",
		value: 5.43,
		sd: 0.16,
		n: 11,
	},
	{
		group: "13",
		subgroup: "Medição (% CAP)",
		value: 5.52,
		sd: 0.16,
		n: 11,
	},
	{
		group: "13",
		subgroup: "Medição (% CAP)",
		value: 5.7,
		sd: 0.16,
		n: 11,
	},
	{
		group: "13",
		subgroup: "Medição (% CAP)",
		value: 5.42,
		sd: 0.16,
		n: 11,
	},
	{
		group: "13",
		subgroup: "Medição (% CAP)",
		value: 5.29,
		sd: 0.16,
		n: 11,
	},
	{
		group: "13",
		subgroup: "Medição (% CAP)",
		value: 5.61,
		sd: 0.16,
		n: 11,
	},
	{
		group: "13",
		subgroup: "Medição (% CAP)",
		value: 5.81,
		sd: 0.16,
		n: 11,
	},
	{
		group: "13",
		subgroup: "Medição (% CAP)",
		value: 5.46,
		sd: 0.16,
		n: 11,
	},
	{
		group: "13",
		subgroup: "Medição (% CAP)",
		value: 5.69,
		sd: 0.16,
		n: 11,
	},
	{
		group: "13",
		subgroup: "Medição (% CAP)",
		value: 5.42,
		sd: 0.16,
		n: 11,
	},
	{
		group: "13",
		subgroup: "Medição (% CAP)",
		value: 5.44,
		sd: 0.16,
		n: 11,
	},
	{
		group: "18",
		subgroup: "Medição (% CAP)",
		value: 5.51,
		sd: 0.08,
		n: 39,
	},
	{
		group: "18",
		subgroup: "Medição (% CAP)",
		value: 5.44,
		sd: 0.08,
		n: 39,
	},
	{
		group: "18",
		subgroup: "Medição (% CAP)",
		value: 5.51,
		sd: 0.08,
		n: 39,
	},
	{
		group: "18",
		subgroup: "Medição (% CAP)",
		value: 5.41,
		sd: 0.08,
		n: 39,
	},
	{
		group: "18",
		subgroup: "Medição (% CAP)",
		value: 5.47,
		sd: 0.08,
		n: 39,
	},
	{
		group: "18",
		subgroup: "Medição (% CAP)",
		value: 5.39,
		sd: 0.08,
		n: 39,
	},
	{
		group: "18",
		subgroup: "Medição (% CAP)",
		value: 5.78,
		sd: 0.08,
		n: 39,
	},
	{
		group: "18",
		subgroup: "Medição (% CAP)",
		value: 5.43,
		sd: 0.08,
		n: 39,
	},
	{
		group: "18",
		subgroup: "Medição (% CAP)",
		value: 5.4,
		sd: 0.08,
		n: 39,
	},
	{
		group: "18",
		subgroup: "Medição (% CAP)",
		value: 5.56,
		sd: 0.08,
		n: 39,
	},
	{
		group: "18",
		subgroup: "Medição (% CAP)",
		value: 5.48,
		sd: 0.08,
		n: 39,
	},
	{
		group: "18",
		subgroup: "Medição (% CAP)",
		value: 5.43,
		sd: 0.08,
		n: 39,
	},
	{
		group: "18",
		subgroup: "Medição (% CAP)",
		value: 5.54,
		sd: 0.08,
		n: 39,
	},
	{
		group: "18",
		subgroup: "Medição (% CAP)",
		value: 5.39,
		sd: 0.08,
		n: 39,
	},
	{
		group: "18",
		subgroup: "Medição (% CAP)",
		value: 5.5,
		sd: 0.08,
		n: 39,
	},
	{
		group: "18",
		subgroup: "Medição (% CAP)",
		value: 5.54,
		sd: 0.08,
		n: 39,
	},
	{
		group: "18",
		subgroup: "Medição (% CAP)",
		value: 5.54,
		sd: 0.08,
		n: 39,
	},
	{
		group: "18",
		subgroup: "Medição (% CAP)",
		value: 5.52,
		sd: 0.08,
		n: 39,
	},
	{
		group: "18",
		subgroup: "Medição (% CAP)",
		value: 5.49,
		sd: 0.08,
		n: 39,
	},
	{
		group: "18",
		subgroup: "Medição (% CAP)",
		value: 5.46,
		sd: 0.08,
		n: 39,
	},
	{
		group: "18",
		subgroup: "Medição (% CAP)",
		value: 5.62,
		sd: 0.08,
		n: 39,
	},
	{
		group: "18",
		subgroup: "Medição (% CAP)",
		value: 5.48,
		sd: 0.08,
		n: 39,
	},
	{
		group: "18",
		subgroup: "Medição (% CAP)",
		value: 5.45,
		sd: 0.08,
		n: 39,
	},
	{
		group: "18",
		subgroup: "Medição (% CAP)",
		value: 5.46,
		sd: 0.08,
		n: 39,
	},
	{
		group: "18",
		subgroup: "Medição (% CAP)",
		value: 5.41,
		sd: 0.08,
		n: 39,
	},
	{
		group: "18",
		subgroup: "Medição (% CAP)",
		value: 5.38,
		sd: 0.08,
		n: 39,
	},
	{
		group: "18",
		subgroup: "Medição (% CAP)",
		value: 5.42,
		sd: 0.08,
		n: 39,
	},
	{
		group: "18",
		subgroup: "Medição (% CAP)",
		value: 5.49,
		sd: 0.08,
		n: 39,
	},
	{
		group: "18",
		subgroup: "Medição (% CAP)",
		value: 5.48,
		sd: 0.08,
		n: 39,
	},
	{
		group: "18",
		subgroup: "Medição (% CAP)",
		value: 5.44,
		sd: 0.08,
		n: 39,
	},
	{
		group: "18",
		subgroup: "Medição (% CAP)",
		value: 5.38,
		sd: 0.08,
		n: 39,
	},
	{
		group: "18",
		subgroup: "Medição (% CAP)",
		value: 5.54,
		sd: 0.08,
		n: 39,
	},
	{
		group: "18",
		subgroup: "Medição (% CAP)",
		value: 5.55,
		sd: 0.08,
		n: 39,
	},
	{
		group: "18",
		subgroup: "Medição (% CAP)",
		value: 5.47,
		sd: 0.08,
		n: 39,
	},
	{
		group: "18",
		subgroup: "Medição (% CAP)",
		value: 5.56,
		sd: 0.08,
		n: 39,
	},
	{
		group: "18",
		subgroup: "Medição (% CAP)",
		value: 5.44,
		sd: 0.08,
		n: 39,
	},
	{
		group: "18",
		subgroup: "Medição (% CAP)",
		value: 5.44,
		sd: 0.08,
		n: 39,
	},
	{
		group: "18",
		subgroup: "Medição (% CAP)",
		value: 5.41,
		sd: 0.08,
		n: 39,
	},
	{
		group: "18",
		subgroup: "Medição (% CAP)",
		value: 5.39,
		sd: 0.08,
		n: 39,
	},
	{
		group: "19",
		subgroup: "Medição (% CAP)",
		value: 5.43,
		sd: 0.1,
		n: 14,
	},
	{
		group: "19",
		subgroup: "Medição (% CAP)",
		value: 5.47,
		sd: 0.1,
		n: 14,
	},
	{
		group: "19",
		subgroup: "Medição (% CAP)",
		value: 5.19,
		sd: 0.1,
		n: 14,
	},
	{
		group: "19",
		subgroup: "Medição (% CAP)",
		value: 5.55,
		sd: 0.1,
		n: 14,
	},
	{
		group: "19",
		subgroup: "Medição (% CAP)",
		value: 5.49,
		sd: 0.1,
		n: 14,
	},
	{
		group: "19",
		subgroup: "Medição (% CAP)",
		value: 5.56,
		sd: 0.1,
		n: 14,
	},
	{
		group: "19",
		subgroup: "Medição (% CAP)",
		value: 5.5,
		sd: 0.1,
		n: 14,
	},
	{
		group: "19",
		subgroup: "Medição (% CAP)",
		value: 5.53,
		sd: 0.1,
		n: 14,
	},
	{
		group: "19",
		subgroup: "Medição (% CAP)",
		value: 5.43,
		sd: 0.1,
		n: 14,
	},
	{
		group: "19",
		subgroup: "Medição (% CAP)",
		value: 5.46,
		sd: 0.1,
		n: 14,
	},
	{
		group: "19",
		subgroup: "Medição (% CAP)",
		value: 5.44,
		sd: 0.1,
		n: 14,
	},
	{
		group: "19",
		subgroup: "Medição (% CAP)",
		value: 5.48,
		sd: 0.1,
		n: 14,
	},
	{
		group: "19",
		subgroup: "Medição (% CAP)",
		value: 5.62,
		sd: 0.1,
		n: 14,
	},
	{
		group: "19",
		subgroup: "Medição (% CAP)",
		value: 5.48,
		sd: 0.1,
		n: 14,
	},
	{
		group: "20",
		subgroup: "Medição (% CAP)",
		value: 5.43,
		sd: 0.12,
		n: 35,
	},
	{
		group: "20",
		subgroup: "Medição (% CAP)",
		value: 5.44,
		sd: 0.12,
		n: 35,
	},
	{
		group: "20",
		subgroup: "Medição (% CAP)",
		value: 5.45,
		sd: 0.12,
		n: 35,
	},
	{
		group: "20",
		subgroup: "Medição (% CAP)",
		value: 5.39,
		sd: 0.12,
		n: 35,
	},
	{
		group: "20",
		subgroup: "Medição (% CAP)",
		value: 5.4,
		sd: 0.12,
		n: 35,
	},
	{
		group: "20",
		subgroup: "Medição (% CAP)",
		value: 5.38,
		sd: 0.12,
		n: 35,
	},
	{
		group: "20",
		subgroup: "Medição (% CAP)",
		value: 5.45,
		sd: 0.12,
		n: 35,
	},
	{
		group: "20",
		subgroup: "Medição (% CAP)",
		value: 5.44,
		sd: 0.12,
		n: 35,
	},
	{
		group: "20",
		subgroup: "Medição (% CAP)",
		value: 5.56,
		sd: 0.12,
		n: 35,
	},
	{
		group: "20",
		subgroup: "Medição (% CAP)",
		value: 5.47,
		sd: 0.12,
		n: 35,
	},
	{
		group: "20",
		subgroup: "Medição (% CAP)",
		value: 5.57,
		sd: 0.12,
		n: 35,
	},
	{
		group: "20",
		subgroup: "Medição (% CAP)",
		value: 5.4,
		sd: 0.12,
		n: 35,
	},
	{
		group: "20",
		subgroup: "Medição (% CAP)",
		value: 5.55,
		sd: 0.12,
		n: 35,
	},
	{
		group: "20",
		subgroup: "Medição (% CAP)",
		value: 5.36,
		sd: 0.12,
		n: 35,
	},
	{
		group: "20",
		subgroup: "Medição (% CAP)",
		value: 5.39,
		sd: 0.12,
		n: 35,
	},
	{
		group: "20",
		subgroup: "Medição (% CAP)",
		value: 5.32,
		sd: 0.12,
		n: 35,
	},
	{
		group: "20",
		subgroup: "Medição (% CAP)",
		value: 5.5,
		sd: 0.12,
		n: 35,
	},
	{
		group: "20",
		subgroup: "Medição (% CAP)",
		value: 5.68,
		sd: 0.12,
		n: 35,
	},
	{
		group: "20",
		subgroup: "Medição (% CAP)",
		value: 5.44,
		sd: 0.12,
		n: 35,
	},
	{
		group: "20",
		subgroup: "Medição (% CAP)",
		value: 5.71,
		sd: 0.12,
		n: 35,
	},
	{
		group: "20",
		subgroup: "Medição (% CAP)",
		value: 5.79,
		sd: 0.12,
		n: 35,
	},
	{
		group: "20",
		subgroup: "Medição (% CAP)",
		value: 5.42,
		sd: 0.12,
		n: 35,
	},
	{
		group: "20",
		subgroup: "Medição (% CAP)",
		value: 5.37,
		sd: 0.12,
		n: 35,
	},
	{
		group: "20",
		subgroup: "Medição (% CAP)",
		value: 5.44,
		sd: 0.12,
		n: 35,
	},
	{
		group: "20",
		subgroup: "Medição (% CAP)",
		value: 5.42,
		sd: 0.12,
		n: 35,
	},
	{
		group: "20",
		subgroup: "Medição (% CAP)",
		value: 5.41,
		sd: 0.12,
		n: 35,
	},
	{
		group: "20",
		subgroup: "Medição (% CAP)",
		value: 5.41,
		sd: 0.12,
		n: 35,
	},
	{
		group: "20",
		subgroup: "Medição (% CAP)",
		value: 5.2,
		sd: 0.12,
		n: 35,
	},
	{
		group: "20",
		subgroup: "Medição (% CAP)",
		value: 5.3,
		sd: 0.12,
		n: 35,
	},
	{
		group: "20",
		subgroup: "Medição (% CAP)",
		value: 5.44,
		sd: 0.12,
		n: 35,
	},
	{
		group: "20",
		subgroup: "Medição (% CAP)",
		value: 5.39,
		sd: 0.12,
		n: 35,
	},
	{
		group: "20",
		subgroup: "Medição (% CAP)",
		value: 5.41,
		sd: 0.12,
		n: 35,
	},
	{
		group: "20",
		subgroup: "Medição (% CAP)",
		value: 5.51,
		sd: 0.12,
		n: 35,
	},
	{
		group: "20",
		subgroup: "Medição (% CAP)",
		value: 5.7,
		sd: 0.12,
		n: 35,
	},
	{
		group: "20",
		subgroup: "Medição (% CAP)",
		value: 5.39,
		sd: 0.12,
		n: 35,
	},
	{
		group: "21",
		subgroup: "Medição (% CAP)",
		value: 5.52,
		sd: 0.08,
		n: 27,
	},
	{
		group: "21",
		subgroup: "Medição (% CAP)",
		value: 5.41,
		sd: 0.08,
		n: 27,
	},
	{
		group: "21",
		subgroup: "Medição (% CAP)",
		value: 5.4,
		sd: 0.08,
		n: 27,
	},
	{
		group: "21",
		subgroup: "Medição (% CAP)",
		value: 5.57,
		sd: 0.08,
		n: 27,
	},
	{
		group: "21",
		subgroup: "Medição (% CAP)",
		value: 5.62,
		sd: 0.08,
		n: 27,
	},
	{
		group: "21",
		subgroup: "Medição (% CAP)",
		value: 5.66,
		sd: 0.08,
		n: 27,
	},
	{
		group: "21",
		subgroup: "Medição (% CAP)",
		value: 5.43,
		sd: 0.08,
		n: 27,
	},
	{
		group: "21",
		subgroup: "Medição (% CAP)",
		value: 5.43,
		sd: 0.08,
		n: 27,
	},
	{
		group: "21",
		subgroup: "Medição (% CAP)",
		value: 5.46,
		sd: 0.08,
		n: 27,
	},
	{
		group: "21",
		subgroup: "Medição (% CAP)",
		value: 5.47,
		sd: 0.08,
		n: 27,
	},
	{
		group: "21",
		subgroup: "Medição (% CAP)",
		value: 5.54,
		sd: 0.08,
		n: 27,
	},
	{
		group: "21",
		subgroup: "Medição (% CAP)",
		value: 5.41,
		sd: 0.08,
		n: 27,
	},
	{
		group: "21",
		subgroup: "Medição (% CAP)",
		value: 5.66,
		sd: 0.08,
		n: 27,
	},
	{
		group: "21",
		subgroup: "Medição (% CAP)",
		value: 5.51,
		sd: 0.08,
		n: 27,
	},
	{
		group: "21",
		subgroup: "Medição (% CAP)",
		value: 5.42,
		sd: 0.08,
		n: 27,
	},
	{
		group: "21",
		subgroup: "Medição (% CAP)",
		value: 5.43,
		sd: 0.08,
		n: 27,
	},
	{
		group: "21",
		subgroup: "Medição (% CAP)",
		value: 5.48,
		sd: 0.08,
		n: 27,
	},
	{
		group: "21",
		subgroup: "Medição (% CAP)",
		value: 5.39,
		sd: 0.08,
		n: 27,
	},
	{
		group: "21",
		subgroup: "Medição (% CAP)",
		value: 5.42,
		sd: 0.08,
		n: 27,
	},
	{
		group: "21",
		subgroup: "Medição (% CAP)",
		value: 5.47,
		sd: 0.08,
		n: 27,
	},
	{
		group: "21",
		subgroup: "Medição (% CAP)",
		value: 5.43,
		sd: 0.08,
		n: 27,
	},
	{
		group: "21",
		subgroup: "Medição (% CAP)",
		value: 5.44,
		sd: 0.08,
		n: 27,
	},
	{
		group: "21",
		subgroup: "Medição (% CAP)",
		value: 5.44,
		sd: 0.08,
		n: 27,
	},
	{
		group: "21",
		subgroup: "Medição (% CAP)",
		value: 5.51,
		sd: 0.08,
		n: 27,
	},
	{
		group: "21",
		subgroup: "Medição (% CAP)",
		value: 5.56,
		sd: 0.08,
		n: 27,
	},
	{
		group: "21",
		subgroup: "Medição (% CAP)",
		value: 5.42,
		sd: 0.08,
		n: 27,
	},
	{
		group: "21",
		subgroup: "Medição (% CAP)",
		value: 5.4,
		sd: 0.08,
		n: 27,
	},
	{
		group: "22",
		subgroup: "Medição (% CAP)",
		value: 5.51,
		sd: 0.03,
		n: 3,
	},
	{
		group: "22",
		subgroup: "Medição (% CAP)",
		value: 5.52,
		sd: 0.03,
		n: 3,
	},
	{
		group: "22",
		subgroup: "Medição (% CAP)",
		value: 5.56,
		sd: 0.03,
		n: 3,
	},
	{
		group: "23",
		subgroup: "Medição (% CAP)",
		value: 5.49,
		sd: 0.07,
		n: 9,
	},
	{
		group: "23",
		subgroup: "Medição (% CAP)",
		value: 5.56,
		sd: 0.07,
		n: 9,
	},
	{
		group: "23",
		subgroup: "Medição (% CAP)",
		value: 5.46,
		sd: 0.07,
		n: 9,
	},
	{
		group: "23",
		subgroup: "Medição (% CAP)",
		value: 5.45,
		sd: 0.07,
		n: 9,
	},
	{
		group: "23",
		subgroup: "Medição (% CAP)",
		value: 5.46,
		sd: 0.07,
		n: 9,
	},
	{
		group: "23",
		subgroup: "Medição (% CAP)",
		value: 5.58,
		sd: 0.07,
		n: 9,
	},
	{
		group: "23",
		subgroup: "Medição (% CAP)",
		value: 5.35,
		sd: 0.07,
		n: 9,
	},
	{
		group: "23",
		subgroup: "Medição (% CAP)",
		value: 5.49,
		sd: 0.07,
		n: 9,
	},
	{
		group: "23",
		subgroup: "Medição (% CAP)",
		value: 5.51,
		sd: 0.07,
		n: 9,
	},
	{
		group: "24",
		subgroup: "Medição (% CAP)",
		value: 5.45,
		sd: 0.05,
		n: 7,
	},
	{
		group: "24",
		subgroup: "Medição (% CAP)",
		value: 5.56,
		sd: 0.05,
		n: 7,
	},
	{
		group: "24",
		subgroup: "Medição (% CAP)",
		value: 5.46,
		sd: 0.05,
		n: 7,
	},
	{
		group: "24",
		subgroup: "Medição (% CAP)",
		value: 5.4,
		sd: 0.05,
		n: 7,
	},
	{
		group: "24",
		subgroup: "Medição (% CAP)",
		value: 5.45,
		sd: 0.05,
		n: 7,
	},
	{
		group: "24",
		subgroup: "Medição (% CAP)",
		value: 5.49,
		sd: 0.05,
		n: 7,
	},
	{
		group: "24",
		subgroup: "Medição (% CAP)",
		value: 5.48,
		sd: 0.05,
		n: 7,
	},
];

export const mockScatterBase = [
	{
		id: "Grau de Compactação (%)",
		data: [
			{
				y: 100.6,
				x: 1,
			},
			{
				y: 96.55,
				x: 2,
			},
			{
				y: 101.33,
				x: 3,
			},
			{
				y: 100.46,
				x: 4,
			},
			{
				y: 100.78,
				x: 5,
			},
			{
				y: 101.97,
				x: 6,
			},
			{
				y: 100.92,
				x: 7,
			},
			{
				y: 101.2,
				x: 8,
			},
			{
				y: 100.41,
				x: 9,
			},
			{
				y: 102.03,
				x: 10,
			},
			{
				y: 102.57,
				x: 11,
			},
			{
				y: 100.83,
				x: 12,
			},
			{
				y: 100.6,
				x: 13,
			},
			{
				y: 102.21,
				x: 14,
			},
			{
				y: 101.11,
				x: 15,
			},
			{
				y: 102.4,
				x: 16,
			},
			{
				y: 100.88,
				x: 17,
			},
			{
				y: 99.16,
				x: 18,
			},
			{
				y: 101.06,
				x: 19,
			},
			{
				y: 100.14,
				x: 20,
			},
			{
				y: 100.78,
				x: 21,
			},
			{
				y: 101.51,
				x: 22,
			},
			{
				y: 100.87,
				x: 23,
			},
			{
				y: 101.75,
				x: 24,
			},
			{
				y: 100.74,
				x: 25,
			},
			{
				y: 100.92,
				x: 26,
			},
			{
				y: 100.51,
				x: 27,
			},
			{
				y: 102.45,
				x: 28,
			},
			{
				y: 101.1,
				x: 29,
			},
			{
				y: 102.07,
				x: 30,
			},
			{
				y: 101.79,
				x: 31,
			},
			{
				y: 101.75,
				x: 32,
			},
			{
				y: 98.9,
				x: 33,
			},
			{
				y: 100.55,
				x: 34,
			},
			{
				y: 101.19,
				x: 35,
			},
			{
				y: 99.95,
				x: 36,
			},
			{
				y: 100.87,
				x: 37,
			},
			{
				y: 102.52,
				x: 38,
			},
			{
				y: 103.0,
				x: 39,
			},
			{
				y: 101.87,
				x: 40,
			},
			{
				y: 100.91,
				x: 41,
			},
			{
				y: 101.1,
				x: 42,
			},
			{
				y: 100.96,
				x: 43,
			},
			{
				y: 101.59,
				x: 44,
			},
			{
				y: 101.74,
				x: 45,
			},
			{
				y: 101.78,
				x: 46,
			},
			{
				y: 102.97,
				x: 47,
			},
			{
				y: 102.55,
				x: 48,
			},
			{
				y: 103.69,
				x: 49,
			},
			{
				y: 102.33,
				x: 50,
			},
			{
				y: 100.0,
				x: 51,
			},
			{
				y: 103.44,
				x: 52,
			},
			{
				y: 103.07,
				x: 53,
			},
			{
				y: 101.81,
				x: 54,
			},
			{
				y: 102.22,
				x: 55,
			},
			{
				y: 102.31,
				x: 56,
			},
			{
				y: 100.88,
				x: 57,
			},
			{
				y: 101.58,
				x: 58,
			},
			{
				y: 101.69,
				x: 59,
			},
			{
				y: 103.0,
				x: 60,
			},
			{
				y: 102.9,
				x: 61,
			},
			{
				y: 102.18,
				x: 62,
			},
			{
				y: 102.02,
				x: 63,
			},
			{
				y: 101.93,
				x: 64,
			},
			{
				y: 101.16,
				x: 65,
			},
			{
				y: 102.54,
				x: 66,
			},
			{
				y: 101.85,
				x: 67,
			},
			{
				y: 101.29,
				x: 68,
			},
			{
				y: 99.43,
				x: 69,
			},
			{
				y: 101.33,
				x: 70,
			},
			{
				y: 101.29,
				x: 71,
			},
			{
				y: 101.38,
				x: 72,
			},
			{
				y: 101.01,
				x: 73,
			},
			{
				y: 100.28,
				x: 74,
			},
			{
				y: 102.02,
				x: 75,
			},
			{
				y: 101.53,
				x: 76,
			},
			{
				y: 102.16,
				x: 77,
			},
			{
				y: 101.57,
				x: 78,
			},
			{
				y: 101.66,
				x: 79,
			},
			{
				y: 101.29,
				x: 80,
			},
			{
				y: 100.6,
				x: 81,
			},
			{
				y: 100.36,
				x: 82,
			},
			{
				y: 100.32,
				x: 83,
			},
			{
				y: 102.66,
				x: 84,
			},
			{
				y: 102.25,
				x: 85,
			},
			{
				y: 101.39,
				x: 86,
			},
			{
				y: 102.31,
				x: 87,
			},
			{
				y: 100.64,
				x: 88,
			},
			{
				y: 100.51,
				x: 89,
			},
			{
				y: 100.09,
				x: 90,
			},
			{
				y: 101.98,
				x: 91,
			},
			{
				y: 100.6,
				x: 92,
			},
			{
				y: 101.33,
				x: 93,
			},
			{
				y: 100.14,
				x: 94,
			},
			{
				y: 101.37,
				x: 95,
			},
			{
				y: 102.79,
				x: 96,
			},
			{
				y: 100.55,
				x: 97,
			},
			{
				y: 102.16,
				x: 98,
			},
			{
				y: 102.63,
				x: 99,
			},
			{
				y: 99.49,
				x: 100,
			},
			{
				y: 101.64,
				x: 101,
			},
			{
				y: 99.58,
				x: 102,
			},
			{
				y: 100.89,
				x: 103,
			},
			{
				y: 100.0,
				x: 104,
			},
			{
				y: 101.03,
				x: 105,
			},
			{
				y: 101.12,
				x: 106,
			},
			{
				y: 103.04,
				x: 107,
			},
			{
				y: 103.21,
				x: 108,
			},
			{
				y: 103.17,
				x: 109,
			},
			{
				y: 101.03,
				x: 110,
			},
			{
				y: 100.74,
				x: 111,
			},
			{
				y: 100.73,
				x: 112,
			},
			{
				y: 100.6,
				x: 113,
			},
			{
				y: 101.57,
				x: 114,
			},
			{
				y: 101.6,
				x: 115,
			},
			{
				y: 101.96,
				x: 116,
			},
			{
				y: 102.74,
				x: 117,
			},
			{
				y: 103.07,
				x: 118,
			},
			{
				y: 100.42,
				x: 119,
			},
			{
				y: 100.85,
				x: 120,
			},
			{
				y: 101.18,
				x: 121,
			},
			{
				y: 99.91,
				x: 122,
			},
			{
				y: 101.32,
				x: 123,
			},
			{
				y: 101.33,
				x: 124,
			},
			{
				y: 102.96,
				x: 125,
			},
			{
				y: 98.87,
				x: 126,
			},
			{
				y: 100.85,
				x: 127,
			},
			{
				y: 100.52,
				x: 128,
			},
			{
				y: 101.78,
				x: 129,
			},
			{
				y: 102.3,
				x: 130,
			},
			{
				y: 100.84,
				x: 131,
			},
			{
				y: 100.51,
				x: 132,
			},
			{
				y: 97.98,
				x: 133,
			},
			{
				y: 100.47,
				x: 134,
			},
			{
				y: 100.09,
				x: 135,
			},
			{
				y: 100.42,
				x: 136,
			},
			{
				y: 99.15,
				x: 137,
			},
			{
				y: 100.38,
				x: 138,
			},
			{
				y: 100.61,
				x: 139,
			},
			{
				y: 100.75,
				x: 140,
			},
			{
				y: 100.0,
				x: 141,
			},
			{
				y: 100.56,
				x: 142,
			},
			{
				y: 100.8,
				x: 143,
			},
			{
				y: 102.0,
				x: 144,
			},
			{
				y: 102.14,
				x: 145,
			},
			{
				y: 102.15,
				x: 146,
			},
			{
				y: 101.51,
				x: 147,
			},
			{
				y: 102.45,
				x: 148,
			},
			{
				y: 102.01,
				x: 149,
			},
			{
				y: 102.49,
				x: 150,
			},
			{
				y: 101.74,
				x: 151,
			},
			{
				y: 101.89,
				x: 152,
			},
			{
				y: 102.26,
				x: 153,
			},
			{
				y: 101.7,
				x: 154,
			},
			{
				y: 101.79,
				x: 155,
			},
			{
				y: 98.5,
				x: 156,
			},
			{
				y: 101.31,
				x: 157,
			},
			{
				y: 100.7,
				x: 158,
			},
			{
				y: 100.38,
				x: 159,
			},
			{
				y: 101.83,
				x: 160,
			},
			{
				y: 101.35,
				x: 161,
			},
			{
				y: 101.78,
				x: 162,
			},
			{
				y: 101.31,
				x: 163,
			},
			{
				y: 101.83,
				x: 164,
			},
			{
				y: 102.82,
				x: 165,
			},
			{
				y: 100.56,
				x: 166,
			},
			{
				y: 99.58,
				x: 167,
			},
			{
				y: 100.38,
				x: 168,
			},
			{
				y: 102.66,
				x: 169,
			},
			{
				y: 103.01,
				x: 170,
			},
			{
				y: 100.98,
				x: 171,
			},
			{
				y: 102.33,
				x: 172,
			},
			{
				y: 103.8,
				x: 173,
			},
			{
				y: 103.31,
				x: 174,
			},
			{
				y: 102.07,
				x: 175,
			},
			{
				y: 101.41,
				x: 176,
			},
			{
				y: 100.75,
				x: 177,
			},
			{
				y: 101.78,
				x: 178,
			},
			{
				y: 99.06,
				x: 179,
			},
			{
				y: 101.27,
				x: 180,
			},
			{
				y: 100.33,
				x: 181,
			},
			{
				y: 100.38,
				x: 182,
			},
			{
				y: 100.38,
				x: 183,
			},
			{
				y: 103.27,
				x: 184,
			},
			{
				y: 102.02,
				x: 185,
			},
			{
				y: 102.73,
				x: 186,
			},
			{
				y: 102.89,
				x: 187,
			},
			{
				y: 101.2,
				x: 188,
			},
			{
				y: 98.8,
				x: 189,
			},
			{
				y: 98.0,
				x: 190,
			},
			{
				y: 100.7,
				x: 191,
			},
			{
				y: 101.55,
				x: 192,
			},
			{
				y: 100.19,
				x: 193,
			},
			{
				y: 100.42,
				x: 194,
			},
			{
				y: 97.98,
				x: 195,
			},
			{
				y: 97.42,
				x: 196,
			},
			{
				y: 99.06,
				x: 197,
			},
			{
				y: 98.4,
				x: 198,
			},
			{
				y: 100.28,
				x: 199,
			},
			{
				y: 100.05,
				x: 200,
			},
			{
				y: 101.04,
				x: 201,
			},
			{
				y: 100.71,
				x: 202,
			},
			{
				y: 97.99,
				x: 203,
			},
			{
				y: 101.0,
				x: 204,
			},
			{
				y: 100.28,
				x: 205,
			},
			{
				y: 100.52,
				x: 206,
			},
			{
				y: 100.9,
				x: 207,
			},
			{
				y: 100.95,
				x: 208,
			},
			{
				y: 98.39,
				x: 209,
			},
			{
				y: 101.19,
				x: 210,
			},
			{
				y: 100.62,
				x: 211,
			},
			{
				y: 101.03,
				x: 212,
			},
			{
				y: 102.7,
				x: 213,
			},
			{
				y: 103.45,
				x: 214,
			},
			{
				y: 101.21,
				x: 215,
			},
			{
				y: 101.57,
				x: 216,
			},
			{
				y: 100.46,
				x: 217,
			},
			{
				y: 103.96,
				x: 218,
			},
			{
				y: 101.02,
				x: 219,
			},
			{
				y: 101.78,
				x: 220,
			},
			{
				y: 102.96,
				x: 221,
			},
			{
				y: 102.42,
				x: 222,
			},
			{
				y: 100.84,
				x: 223,
			},
			{
				y: 98.35,
				x: 224,
			},
			{
				y: 98.41,
				x: 225,
			},
			{
				y: 100.47,
				x: 226,
			},
			{
				y: 100.89,
				x: 227,
			},
			{
				y: 101.15,
				x: 228,
			},
			{
				y: 101.77,
				x: 229,
			},
			{
				y: 100.09,
				x: 230,
			},
			{
				y: 97.87,
				x: 231,
			},
			{
				y: 100.42,
				x: 232,
			},
			{
				y: 101.11,
				x: 233,
			},
			{
				y: 100.97,
				x: 234,
			},
			{
				y: 101.8,
				x: 235,
			},
			{
				y: 101.21,
				x: 236,
			},
			{
				y: 98.31,
				x: 237,
			},
			{
				y: 100.38,
				x: 238,
			},
			{
				y: 100.88,
				x: 239,
			},
			{
				y: 101.34,
				x: 240,
			},
			{
				y: 100.51,
				x: 241,
			},
			{
				y: 101.3,
				x: 242,
			},
			{
				y: 98.2,
				x: 243,
			},
			{
				y: 100.51,
				x: 244,
			},
			{
				y: 100.28,
				x: 245,
			},
			{
				y: 100.69,
				x: 246,
			},
			{
				y: 101.66,
				x: 247,
			},
			{
				y: 100.23,
				x: 248,
			},
			{
				y: 102.85,
				x: 249,
			},
			{
				y: 101.39,
				x: 250,
			},
			{
				y: 97.21,
				x: 251,
			},
			{
				y: 100.28,
				x: 252,
			},
			{
				y: 100.32,
				x: 253,
			},
			{
				y: 101.25,
				x: 254,
			},
			{
				y: 98.36,
				x: 255,
			},
			{
				y: 100.28,
				x: 256,
			},
			{
				y: 100.19,
				x: 257,
			},
			{
				y: 100.74,
				x: 258,
			},
			{
				y: 100.75,
				x: 259,
			},
			{
				y: 100.47,
				x: 260,
			},
			{
				y: 100.0,
				x: 261,
			},
			{
				y: 100.51,
				x: 262,
			},
			{
				y: 101.49,
				x: 263,
			},
			{
				y: 99.18,
				x: 264,
			},
			{
				y: 102.31,
				x: 265,
			},
			{
				y: 102.33,
				x: 266,
			},
			{
				y: 98.03,
				x: 267,
			},
			{
				y: 101.43,
				x: 268,
			},
			{
				y: 100.09,
				x: 269,
			},
			{
				y: 100.46,
				x: 270,
			},
			{
				y: 100.0,
				x: 271,
			},
			{
				y: 101.71,
				x: 272,
			},
			{
				y: 101.98,
				x: 273,
			},
			{
				y: 103.09,
				x: 274,
			},
			{
				y: 103.54,
				x: 275,
			},
			{
				y: 100.65,
				x: 276,
			},
			{
				y: 101.9,
				x: 277,
			},
			{
				y: 102.89,
				x: 278,
			},
			{
				y: 103.67,
				x: 279,
			},
			{
				y: 103.31,
				x: 280,
			},
			{
				y: 98.1,
				x: 281,
			},
			{
				y: 101.81,
				x: 282,
			},
			{
				y: 102.96,
				x: 283,
			},
			{
				y: 100.37,
				x: 284,
			},
			{
				y: 103.1,
				x: 285,
			},
			{
				y: 102.51,
				x: 286,
			},
			{
				y: 101.53,
				x: 287,
			},
			{
				y: 103.22,
				x: 288,
			},
			{
				y: 102.1,
				x: 289,
			},
			{
				y: 101.5,
				x: 290,
			},
			{
				y: 102.38,
				x: 291,
			},
			{
				y: 102.01,
				x: 292,
			},
			{
				y: 101.87,
				x: 293,
			},
			{
				y: 101.38,
				x: 294,
			},
			{
				y: 101.48,
				x: 295,
			},
			{
				y: 101.17,
				x: 296,
			},
			{
				y: 100.94,
				x: 297,
			},
			{
				y: 102.35,
				x: 298,
			},
			{
				y: 103.59,
				x: 299,
			},
			{
				y: 102.61,
				x: 300,
			},
			{
				y: 100.41,
				x: 301,
			},
			{
				y: 101.2,
				x: 302,
			},
			{
				y: 102.86,
				x: 303,
			},
			{
				y: 100.32,
				x: 304,
			},
			{
				y: 102.21,
				x: 305,
			},
			{
				y: 104.39,
				x: 306,
			},
			{
				y: 100.6,
				x: 307,
			},
			{
				y: 100.93,
				x: 308,
			},
			{
				y: 98.43,
				x: 309,
			},
			{
				y: 98.66,
				x: 310,
			},
			{
				y: 100.46,
				x: 311,
			},
			{
				y: 97.75,
				x: 312,
			},
			{
				y: 97.21,
				x: 313,
			},
			{
				y: 101.19,
				x: 314,
			},
			{
				y: 97.25,
				x: 315,
			},
			{
				y: 100.41,
				x: 316,
			},
			{
				y: 102.32,
				x: 317,
			},
			{
				y: 102.45,
				x: 318,
			},
			{
				y: 100.19,
				x: 319,
			},
			{
				y: 100.23,
				x: 320,
			},
			{
				y: 103.69,
				x: 321,
			},
			{
				y: 103.25,
				x: 322,
			},
			{
				y: 100.8,
				x: 323,
			},
			{
				y: 101.71,
				x: 324,
			},
			{
				y: 101.17,
				x: 325,
			},
			{
				y: 97.4,
				x: 326,
			},
			{
				y: 100.23,
				x: 327,
			},
			{
				y: 100.97,
				x: 328,
			},
			{
				y: 100.46,
				x: 329,
			},
			{
				y: 100.42,
				x: 330,
			},
			{
				y: 100.51,
				x: 331,
			},
			{
				y: 100.23,
				x: 332,
			},
			{
				y: 100.23,
				x: 333,
			},
			{
				y: 100.32,
				x: 334,
			},
			{
				y: 100.75,
				x: 335,
			},
			{
				y: 101.08,
				x: 336,
			},
			{
				y: 98.58,
				x: 337,
			},
			{
				y: 98.77,
				x: 338,
			},
			{
				y: 101.18,
				x: 339,
			},
			{
				y: 100.18,
				x: 340,
			},
			{
				y: 101.19,
				x: 341,
			},
			{
				y: 100.47,
				x: 342,
			},
			{
				y: 101.77,
				x: 343,
			},
			{
				y: 103.36,
				x: 344,
			},
			{
				y: 102.91,
				x: 345,
			},
			{
				y: 98.97,
				x: 346,
			},
			{
				y: 101.03,
				x: 347,
			},
			{
				y: 101.02,
				x: 348,
			},
			{
				y: 101.12,
				x: 349,
			},
			{
				y: 100.98,
				x: 350,
			},
			{
				y: 101.56,
				x: 351,
			},
			{
				y: 98.58,
				x: 352,
			},
			{
				y: 100.69,
				x: 353,
			},
			{
				y: 100.32,
				x: 354,
			},
			{
				y: 100.55,
				x: 355,
			},
			{
				y: 102.03,
				x: 356,
			},
			{
				y: 101.29,
				x: 357,
			},
			{
				y: 103.27,
				x: 358,
			},
			{
				y: 96.42,
				x: 359,
			},
			{
				y: 100.41,
				x: 360,
			},
			{
				y: 100.51,
				x: 361,
			},
			{
				y: 100.37,
				x: 362,
			},
			{
				y: 100.41,
				x: 363,
			},
			{
				y: 100.14,
				x: 364,
			},
			{
				y: 102.26,
				x: 365,
			},
			{
				y: 103.84,
				x: 366,
			},
			{
				y: 103.97,
				x: 367,
			},
			{
				y: 100.09,
				x: 368,
			},
			{
				y: 100.59,
				x: 369,
			},
			{
				y: 100.18,
				x: 370,
			},
			{
				y: 101.31,
				x: 371,
			},
			{
				y: 102.15,
				x: 372,
			},
			{
				y: 102.99,
				x: 373,
			},
			{
				y: 103.62,
				x: 374,
			},
			{
				y: 103.16,
				x: 375,
			},
			{
				y: 103.3,
				x: 376,
			},
			{
				y: 103.25,
				x: 377,
			},
			{
				y: 102.15,
				x: 378,
			},
			{
				y: 101.49,
				x: 379,
			},
			{
				y: 101.36,
				x: 380,
			},
			{
				y: 100.27,
				x: 381,
			},
			{
				y: 100.6,
				x: 382,
			},
			{
				y: 100.51,
				x: 383,
			},
			{
				y: 101.48,
				x: 384,
			},
			{
				y: 101.39,
				x: 385,
			},
			{
				y: 101.99,
				x: 386,
			},
			{
				y: 100.97,
				x: 387,
			},
			{
				y: 101.34,
				x: 388,
			},
			{
				y: 100.74,
				x: 389,
			},
			{
				y: 100.65,
				x: 390,
			},
			{
				y: 98.13,
				x: 391,
			},
			{
				y: 100.19,
				x: 392,
			},
			{
				y: 100.28,
				x: 393,
			},
			{
				y: 96.74,
				x: 394,
			},
			{
				y: 100.33,
				x: 395,
			},
			{
				y: 100.56,
				x: 396,
			},
			{
				y: 96.49,
				x: 397,
			},
			{
				y: 100.52,
				x: 398,
			},
			{
				y: 101.08,
				x: 399,
			},
			{
				y: 100.61,
				x: 400,
			},
			{
				y: 100.46,
				x: 401,
			},
			{
				y: 103.2,
				x: 402,
			},
			{
				y: 100.19,
				x: 403,
			},
			{
				y: 100.28,
				x: 404,
			},
			{
				y: 101.82,
				x: 405,
			},
			{
				y: 102.67,
				x: 406,
			},
			{
				y: 100.19,
				x: 407,
			},
			{
				y: 101.64,
				x: 408,
			},
			{
				y: 102.58,
				x: 409,
			},
			{
				y: 102.59,
				x: 410,
			},
			{
				y: 100.28,
				x: 411,
			},
			{
				y: 100.76,
				x: 412,
			},
			{
				y: 100.43,
				x: 413,
			},
			{
				y: 101.61,
				x: 414,
			},
			{
				y: 100.57,
				x: 415,
			},
			{
				y: 102.15,
				x: 416,
			},
			{
				y: 102.42,
				x: 417,
			},
			{
				y: 103.11,
				x: 418,
			},
			{
				y: 101.63,
				x: 419,
			},
			{
				y: 101.33,
				x: 420,
			},
			{
				y: 103.55,
				x: 421,
			},
			{
				y: 100.33,
				x: 422,
			},
			{
				y: 101.07,
				x: 423,
			},
			{
				y: 101.22,
				x: 424,
			},
			{
				y: 101.64,
				x: 425,
			},
			{
				y: 102.24,
				x: 426,
			},
			{
				y: 102.06,
				x: 427,
			},
			{
				y: 99.17,
				x: 428,
			},
			{
				y: 100.14,
				x: 429,
			},
			{
				y: 100.05,
				x: 430,
			},
			{
				y: 100.32,
				x: 431,
			},
			{
				y: 100.75,
				x: 432,
			},
			{
				y: 101.21,
				x: 433,
			},
			{
				y: 100.8,
				x: 434,
			},
			{
				y: 101.27,
				x: 435,
			},
			{
				y: 100.93,
				x: 436,
			},
			{
				y: 100.19,
				x: 437,
			},
			{
				y: 102.48,
				x: 438,
			},
			{
				y: 100.43,
				x: 439,
			},
			{
				y: 101.15,
				x: 440,
			},
			{
				y: 100.52,
				x: 441,
			},
			{
				y: 101.28,
				x: 442,
			},
			{
				y: 102.9,
				x: 443,
			},
			{
				y: 100.79,
				x: 444,
			},
			{
				y: 103.67,
				x: 445,
			},
			{
				y: 102.05,
				x: 446,
			},
			{
				y: 102.82,
				x: 447,
			},
			{
				y: 104.06,
				x: 448,
			},
			{
				y: 104.05,
				x: 449,
			},
			{
				y: 104.09,
				x: 450,
			},
			{
				y: 103.2,
				x: 451,
			},
			{
				y: 100.94,
				x: 452,
			},
			{
				y: 101.21,
				x: 453,
			},
			{
				y: 101.13,
				x: 454,
			},
			{
				y: 101.69,
				x: 455,
			},
			{
				y: 100.09,
				x: 456,
			},
			{
				y: 100.37,
				x: 457,
			},
			{
				y: 100.8,
				x: 458,
			},
			{
				y: 99.02,
				x: 459,
			},
			{
				y: 100.93,
				x: 460,
			},
			{
				y: 102.15,
				x: 461,
			},
			{
				y: 100.51,
				x: 462,
			},
			{
				y: 100.28,
				x: 463,
			},
			{
				y: 102.75,
				x: 464,
			},
			{
				y: 103.13,
				x: 465,
			},
			{
				y: 102.26,
				x: 466,
			},
			{
				y: 101.48,
				x: 467,
			},
			{
				y: 101.17,
				x: 468,
			},
			{
				y: 102.24,
				x: 469,
			},
			{
				y: 101.12,
				x: 470,
			},
			{
				y: 104.54,
				x: 471,
			},
			{
				y: 102.33,
				x: 472,
			},
			{
				y: 100.66,
				x: 473,
			},
			{
				y: 100.28,
				x: 474,
			},
			{
				y: 103.04,
				x: 475,
			},
			{
				y: 100.61,
				x: 476,
			},
			{
				y: 101.71,
				x: 477,
			},
			{
				y: 101.59,
				x: 478,
			},
			{
				y: 103.38,
				x: 479,
			},
			{
				y: 101.84,
				x: 480,
			},
			{
				y: 102.03,
				x: 481,
			},
			{
				y: 100.62,
				x: 482,
			},
			{
				y: 101.71,
				x: 483,
			},
			{
				y: 100.95,
				x: 484,
			},
			{
				y: 100.28,
				x: 485,
			},
			{
				y: 100.86,
				x: 486,
			},
			{
				y: 100.94,
				x: 487,
			},
			{
				y: 100.85,
				x: 488,
			},
			{
				y: 101.09,
				x: 489,
			},
			{
				y: 100.48,
				x: 490,
			},
			{
				y: 101.14,
				x: 491,
			},
			{
				y: 100.0,
				x: 492,
			},
			{
				y: 100.19,
				x: 493,
			},
			{
				y: 100.09,
				x: 494,
			},
			{
				y: 100.33,
				x: 495,
			},
			{
				y: 100.56,
				x: 496,
			},
			{
				y: 100.93,
				x: 497,
			},
			{
				y: 101.03,
				x: 498,
			},
			{
				y: 101.31,
				x: 499,
			},
			{
				y: 104.35,
				x: 500,
			},
			{
				y: 104.23,
				x: 501,
			},
			{
				y: 102.5,
				x: 502,
			},
			{
				y: 100.47,
				x: 503,
			},
			{
				y: 101.77,
				x: 504,
			},
			{
				y: 103.28,
				x: 505,
			},
			{
				y: 101.03,
				x: 506,
			},
			{
				y: 100.6,
				x: 507,
			},
		],
	},
];

export const mockBoxplotBase = [
	{
		group: "9",
		subgroup: "",
		value: 100.6,
		sd: 1.065,
		n: 90,
	},
	{
		group: "9",
		subgroup: "",
		value: 96.55,
		sd: 1.065,
		n: 90,
	},
	{
		group: "9",
		subgroup: "",
		value: 101.33,
		sd: 1.065,
		n: 90,
	},
	{
		group: "9",
		subgroup: "",
		value: 100.46,
		sd: 1.065,
		n: 90,
	},
	{
		group: "9",
		subgroup: "",
		value: 100.78,
		sd: 1.065,
		n: 90,
	},
	{
		group: "9",
		subgroup: "",
		value: 101.97,
		sd: 1.065,
		n: 90,
	},
	{
		group: "9",
		subgroup: "",
		value: 100.92,
		sd: 1.065,
		n: 90,
	},
	{
		group: "9",
		subgroup: "",
		value: 101.2,
		sd: 1.065,
		n: 90,
	},
	{
		group: "9",
		subgroup: "",
		value: 100.41,
		sd: 1.065,
		n: 90,
	},
	{
		group: "9",
		subgroup: "",
		value: 102.03,
		sd: 1.065,
		n: 90,
	},
	{
		group: "9",
		subgroup: "",
		value: 102.57,
		sd: 1.065,
		n: 90,
	},
	{
		group: "9",
		subgroup: "",
		value: 100.83,
		sd: 1.065,
		n: 90,
	},
	{
		group: "9",
		subgroup: "",
		value: 100.6,
		sd: 1.065,
		n: 90,
	},
	{
		group: "9",
		subgroup: "",
		value: 102.21,
		sd: 1.065,
		n: 90,
	},
	{
		group: "9",
		subgroup: "",
		value: 101.11,
		sd: 1.065,
		n: 90,
	},
	{
		group: "9",
		subgroup: "",
		value: 102.4,
		sd: 1.065,
		n: 90,
	},
	{
		group: "9",
		subgroup: "",
		value: 100.88,
		sd: 1.065,
		n: 90,
	},
	{
		group: "9",
		subgroup: "",
		value: 99.16,
		sd: 1.065,
		n: 90,
	},
	{
		group: "9",
		subgroup: "",
		value: 101.06,
		sd: 1.065,
		n: 90,
	},
	{
		group: "9",
		subgroup: "",
		value: 100.14,
		sd: 1.065,
		n: 90,
	},
	{
		group: "9",
		subgroup: "",
		value: 100.78,
		sd: 1.065,
		n: 90,
	},
	{
		group: "9",
		subgroup: "",
		value: 101.51,
		sd: 1.065,
		n: 90,
	},
	{
		group: "9",
		subgroup: "",
		value: 100.87,
		sd: 1.065,
		n: 90,
	},
	{
		group: "9",
		subgroup: "",
		value: 101.75,
		sd: 1.065,
		n: 90,
	},
	{
		group: "9",
		subgroup: "",
		value: 100.74,
		sd: 1.065,
		n: 90,
	},
	{
		group: "9",
		subgroup: "",
		value: 100.92,
		sd: 1.065,
		n: 90,
	},
	{
		group: "9",
		subgroup: "",
		value: 100.51,
		sd: 1.065,
		n: 90,
	},
	{
		group: "9",
		subgroup: "",
		value: 102.45,
		sd: 1.065,
		n: 90,
	},
	{
		group: "9",
		subgroup: "",
		value: 101.1,
		sd: 1.065,
		n: 90,
	},
	{
		group: "9",
		subgroup: "",
		value: 102.07,
		sd: 1.065,
		n: 90,
	},
	{
		group: "9",
		subgroup: "",
		value: 101.79,
		sd: 1.065,
		n: 90,
	},
	{
		group: "9",
		subgroup: "",
		value: 101.75,
		sd: 1.065,
		n: 90,
	},
	{
		group: "9",
		subgroup: "",
		value: 98.9,
		sd: 1.065,
		n: 90,
	},
	{
		group: "9",
		subgroup: "",
		value: 100.55,
		sd: 1.065,
		n: 90,
	},
	{
		group: "9",
		subgroup: "",
		value: 101.19,
		sd: 1.065,
		n: 90,
	},
	{
		group: "9",
		subgroup: "",
		value: 99.95,
		sd: 1.065,
		n: 90,
	},
	{
		group: "9",
		subgroup: "",
		value: 100.87,
		sd: 1.065,
		n: 90,
	},
	{
		group: "9",
		subgroup: "",
		value: 102.52,
		sd: 1.065,
		n: 90,
	},
	{
		group: "9",
		subgroup: "",
		value: 103.0,
		sd: 1.065,
		n: 90,
	},
	{
		group: "9",
		subgroup: "",
		value: 101.87,
		sd: 1.065,
		n: 90,
	},
	{
		group: "9",
		subgroup: "",
		value: 100.91,
		sd: 1.065,
		n: 90,
	},
	{
		group: "9",
		subgroup: "",
		value: 101.1,
		sd: 1.065,
		n: 90,
	},
	{
		group: "9",
		subgroup: "",
		value: 100.96,
		sd: 1.065,
		n: 90,
	},
	{
		group: "9",
		subgroup: "",
		value: 101.59,
		sd: 1.065,
		n: 90,
	},
	{
		group: "9",
		subgroup: "",
		value: 101.74,
		sd: 1.065,
		n: 90,
	},
	{
		group: "9",
		subgroup: "",
		value: 101.78,
		sd: 1.065,
		n: 90,
	},
	{
		group: "9",
		subgroup: "",
		value: 102.97,
		sd: 1.065,
		n: 90,
	},
	{
		group: "9",
		subgroup: "",
		value: 102.55,
		sd: 1.065,
		n: 90,
	},
	{
		group: "9",
		subgroup: "",
		value: 103.69,
		sd: 1.065,
		n: 90,
	},
	{
		group: "9",
		subgroup: "",
		value: 102.33,
		sd: 1.065,
		n: 90,
	},
	{
		group: "9",
		subgroup: "",
		value: 100.0,
		sd: 1.065,
		n: 90,
	},
	{
		group: "9",
		subgroup: "",
		value: 103.44,
		sd: 1.065,
		n: 90,
	},
	{
		group: "9",
		subgroup: "",
		value: 103.07,
		sd: 1.065,
		n: 90,
	},
	{
		group: "9",
		subgroup: "",
		value: 101.81,
		sd: 1.065,
		n: 90,
	},
	{
		group: "9",
		subgroup: "",
		value: 102.22,
		sd: 1.065,
		n: 90,
	},
	{
		group: "9",
		subgroup: "",
		value: 102.31,
		sd: 1.065,
		n: 90,
	},
	{
		group: "9",
		subgroup: "",
		value: 100.88,
		sd: 1.065,
		n: 90,
	},
	{
		group: "9",
		subgroup: "",
		value: 101.58,
		sd: 1.065,
		n: 90,
	},
	{
		group: "9",
		subgroup: "",
		value: 101.69,
		sd: 1.065,
		n: 90,
	},
	{
		group: "9",
		subgroup: "",
		value: 103.0,
		sd: 1.065,
		n: 90,
	},
	{
		group: "9",
		subgroup: "",
		value: 102.9,
		sd: 1.065,
		n: 90,
	},
	{
		group: "9",
		subgroup: "",
		value: 102.18,
		sd: 1.065,
		n: 90,
	},
	{
		group: "9",
		subgroup: "",
		value: 102.02,
		sd: 1.065,
		n: 90,
	},
	{
		group: "9",
		subgroup: "",
		value: 101.93,
		sd: 1.065,
		n: 90,
	},
	{
		group: "9",
		subgroup: "",
		value: 101.16,
		sd: 1.065,
		n: 90,
	},
	{
		group: "9",
		subgroup: "",
		value: 102.54,
		sd: 1.065,
		n: 90,
	},
	{
		group: "9",
		subgroup: "",
		value: 101.85,
		sd: 1.065,
		n: 90,
	},
	{
		group: "9",
		subgroup: "",
		value: 101.29,
		sd: 1.065,
		n: 90,
	},
	{
		group: "9",
		subgroup: "",
		value: 99.43,
		sd: 1.065,
		n: 90,
	},
	{
		group: "9",
		subgroup: "",
		value: 101.33,
		sd: 1.065,
		n: 90,
	},
	{
		group: "9",
		subgroup: "",
		value: 101.29,
		sd: 1.065,
		n: 90,
	},
	{
		group: "9",
		subgroup: "",
		value: 101.38,
		sd: 1.065,
		n: 90,
	},
	{
		group: "9",
		subgroup: "",
		value: 101.01,
		sd: 1.065,
		n: 90,
	},
	{
		group: "9",
		subgroup: "",
		value: 100.28,
		sd: 1.065,
		n: 90,
	},
	{
		group: "9",
		subgroup: "",
		value: 102.02,
		sd: 1.065,
		n: 90,
	},
	{
		group: "9",
		subgroup: "",
		value: 101.53,
		sd: 1.065,
		n: 90,
	},
	{
		group: "9",
		subgroup: "",
		value: 102.16,
		sd: 1.065,
		n: 90,
	},
	{
		group: "9",
		subgroup: "",
		value: 101.57,
		sd: 1.065,
		n: 90,
	},
	{
		group: "9",
		subgroup: "",
		value: 101.66,
		sd: 1.065,
		n: 90,
	},
	{
		group: "9",
		subgroup: "",
		value: 101.29,
		sd: 1.065,
		n: 90,
	},
	{
		group: "9",
		subgroup: "",
		value: 100.6,
		sd: 1.065,
		n: 90,
	},
	{
		group: "9",
		subgroup: "",
		value: 100.36,
		sd: 1.065,
		n: 90,
	},
	{
		group: "9",
		subgroup: "",
		value: 100.32,
		sd: 1.065,
		n: 90,
	},
	{
		group: "9",
		subgroup: "",
		value: 102.66,
		sd: 1.065,
		n: 90,
	},
	{
		group: "9",
		subgroup: "",
		value: 102.25,
		sd: 1.065,
		n: 90,
	},
	{
		group: "9",
		subgroup: "",
		value: 101.39,
		sd: 1.065,
		n: 90,
	},
	{
		group: "9",
		subgroup: "",
		value: 102.31,
		sd: 1.065,
		n: 90,
	},
	{
		group: "9",
		subgroup: "",
		value: 100.64,
		sd: 1.065,
		n: 90,
	},
	{
		group: "9",
		subgroup: "",
		value: 100.51,
		sd: 1.065,
		n: 90,
	},
	{
		group: "9",
		subgroup: "",
		value: 100.09,
		sd: 1.065,
		n: 90,
	},
	{
		group: "10",
		subgroup: "",
		value: 101.98,
		sd: 1.13,
		n: 55,
	},
	{
		group: "10",
		subgroup: "",
		value: 100.6,
		sd: 1.13,
		n: 55,
	},
	{
		group: "10",
		subgroup: "",
		value: 101.33,
		sd: 1.13,
		n: 55,
	},
	{
		group: "10",
		subgroup: "",
		value: 100.14,
		sd: 1.13,
		n: 55,
	},
	{
		group: "10",
		subgroup: "",
		value: 101.37,
		sd: 1.13,
		n: 55,
	},
	{
		group: "10",
		subgroup: "",
		value: 102.79,
		sd: 1.13,
		n: 55,
	},
	{
		group: "10",
		subgroup: "",
		value: 100.55,
		sd: 1.13,
		n: 55,
	},
	{
		group: "10",
		subgroup: "",
		value: 102.16,
		sd: 1.13,
		n: 55,
	},
	{
		group: "10",
		subgroup: "",
		value: 102.63,
		sd: 1.13,
		n: 55,
	},
	{
		group: "10",
		subgroup: "",
		value: 99.49,
		sd: 1.13,
		n: 55,
	},
	{
		group: "10",
		subgroup: "",
		value: 101.64,
		sd: 1.13,
		n: 55,
	},
	{
		group: "10",
		subgroup: "",
		value: 99.58,
		sd: 1.13,
		n: 55,
	},
	{
		group: "10",
		subgroup: "",
		value: 100.89,
		sd: 1.13,
		n: 55,
	},
	{
		group: "10",
		subgroup: "",
		value: 100.0,
		sd: 1.13,
		n: 55,
	},
	{
		group: "10",
		subgroup: "",
		value: 101.03,
		sd: 1.13,
		n: 55,
	},
	{
		group: "10",
		subgroup: "",
		value: 101.12,
		sd: 1.13,
		n: 55,
	},
	{
		group: "10",
		subgroup: "",
		value: 103.04,
		sd: 1.13,
		n: 55,
	},
	{
		group: "10",
		subgroup: "",
		value: 103.21,
		sd: 1.13,
		n: 55,
	},
	{
		group: "10",
		subgroup: "",
		value: 103.17,
		sd: 1.13,
		n: 55,
	},
	{
		group: "10",
		subgroup: "",
		value: 101.03,
		sd: 1.13,
		n: 55,
	},
	{
		group: "10",
		subgroup: "",
		value: 100.74,
		sd: 1.13,
		n: 55,
	},
	{
		group: "10",
		subgroup: "",
		value: 100.73,
		sd: 1.13,
		n: 55,
	},
	{
		group: "10",
		subgroup: "",
		value: 100.6,
		sd: 1.13,
		n: 55,
	},
	{
		group: "10",
		subgroup: "",
		value: 101.57,
		sd: 1.13,
		n: 55,
	},
	{
		group: "10",
		subgroup: "",
		value: 101.6,
		sd: 1.13,
		n: 55,
	},
	{
		group: "10",
		subgroup: "",
		value: 101.96,
		sd: 1.13,
		n: 55,
	},
	{
		group: "10",
		subgroup: "",
		value: 102.74,
		sd: 1.13,
		n: 55,
	},
	{
		group: "10",
		subgroup: "",
		value: 103.07,
		sd: 1.13,
		n: 55,
	},
	{
		group: "10",
		subgroup: "",
		value: 100.42,
		sd: 1.13,
		n: 55,
	},
	{
		group: "10",
		subgroup: "",
		value: 100.85,
		sd: 1.13,
		n: 55,
	},
	{
		group: "10",
		subgroup: "",
		value: 101.18,
		sd: 1.13,
		n: 55,
	},
	{
		group: "10",
		subgroup: "",
		value: 99.91,
		sd: 1.13,
		n: 55,
	},
	{
		group: "10",
		subgroup: "",
		value: 101.32,
		sd: 1.13,
		n: 55,
	},
	{
		group: "10",
		subgroup: "",
		value: 101.33,
		sd: 1.13,
		n: 55,
	},
	{
		group: "10",
		subgroup: "",
		value: 102.96,
		sd: 1.13,
		n: 55,
	},
	{
		group: "10",
		subgroup: "",
		value: 98.87,
		sd: 1.13,
		n: 55,
	},
	{
		group: "10",
		subgroup: "",
		value: 100.85,
		sd: 1.13,
		n: 55,
	},
	{
		group: "10",
		subgroup: "",
		value: 100.52,
		sd: 1.13,
		n: 55,
	},
	{
		group: "10",
		subgroup: "",
		value: 101.78,
		sd: 1.13,
		n: 55,
	},
	{
		group: "10",
		subgroup: "",
		value: 102.3,
		sd: 1.13,
		n: 55,
	},
	{
		group: "10",
		subgroup: "",
		value: 100.84,
		sd: 1.13,
		n: 55,
	},
	{
		group: "10",
		subgroup: "",
		value: 100.51,
		sd: 1.13,
		n: 55,
	},
	{
		group: "10",
		subgroup: "",
		value: 97.98,
		sd: 1.13,
		n: 55,
	},
	{
		group: "10",
		subgroup: "",
		value: 100.47,
		sd: 1.13,
		n: 55,
	},
	{
		group: "10",
		subgroup: "",
		value: 100.09,
		sd: 1.13,
		n: 55,
	},
	{
		group: "10",
		subgroup: "",
		value: 100.42,
		sd: 1.13,
		n: 55,
	},
	{
		group: "10",
		subgroup: "",
		value: 99.15,
		sd: 1.13,
		n: 55,
	},
	{
		group: "10",
		subgroup: "",
		value: 100.38,
		sd: 1.13,
		n: 55,
	},
	{
		group: "10",
		subgroup: "",
		value: 100.61,
		sd: 1.13,
		n: 55,
	},
	{
		group: "10",
		subgroup: "",
		value: 100.75,
		sd: 1.13,
		n: 55,
	},
	{
		group: "10",
		subgroup: "",
		value: 100.0,
		sd: 1.13,
		n: 55,
	},
	{
		group: "10",
		subgroup: "",
		value: 100.56,
		sd: 1.13,
		n: 55,
	},
	{
		group: "10",
		subgroup: "",
		value: 100.8,
		sd: 1.13,
		n: 55,
	},
	{
		group: "10",
		subgroup: "",
		value: 102.0,
		sd: 1.13,
		n: 55,
	},
	{
		group: "10",
		subgroup: "",
		value: 102.14,
		sd: 1.13,
		n: 55,
	},
	{
		group: "11",
		subgroup: "",
		value: 102.15,
		sd: 1.116,
		n: 35,
	},
	{
		group: "11",
		subgroup: "",
		value: 101.51,
		sd: 1.116,
		n: 35,
	},
	{
		group: "11",
		subgroup: "",
		value: 102.45,
		sd: 1.116,
		n: 35,
	},
	{
		group: "11",
		subgroup: "",
		value: 102.01,
		sd: 1.116,
		n: 35,
	},
	{
		group: "11",
		subgroup: "",
		value: 102.49,
		sd: 1.116,
		n: 35,
	},
	{
		group: "11",
		subgroup: "",
		value: 101.74,
		sd: 1.116,
		n: 35,
	},
	{
		group: "11",
		subgroup: "",
		value: 101.89,
		sd: 1.116,
		n: 35,
	},
	{
		group: "11",
		subgroup: "",
		value: 102.26,
		sd: 1.116,
		n: 35,
	},
	{
		group: "11",
		subgroup: "",
		value: 101.7,
		sd: 1.116,
		n: 35,
	},
	{
		group: "11",
		subgroup: "",
		value: 101.79,
		sd: 1.116,
		n: 35,
	},
	{
		group: "11",
		subgroup: "",
		value: 98.5,
		sd: 1.116,
		n: 35,
	},
	{
		group: "11",
		subgroup: "",
		value: 101.31,
		sd: 1.116,
		n: 35,
	},
	{
		group: "11",
		subgroup: "",
		value: 100.7,
		sd: 1.116,
		n: 35,
	},
	{
		group: "11",
		subgroup: "",
		value: 100.38,
		sd: 1.116,
		n: 35,
	},
	{
		group: "11",
		subgroup: "",
		value: 101.83,
		sd: 1.116,
		n: 35,
	},
	{
		group: "11",
		subgroup: "",
		value: 101.35,
		sd: 1.116,
		n: 35,
	},
	{
		group: "11",
		subgroup: "",
		value: 101.78,
		sd: 1.116,
		n: 35,
	},
	{
		group: "11",
		subgroup: "",
		value: 101.31,
		sd: 1.116,
		n: 35,
	},
	{
		group: "11",
		subgroup: "",
		value: 101.83,
		sd: 1.116,
		n: 35,
	},
	{
		group: "11",
		subgroup: "",
		value: 102.82,
		sd: 1.116,
		n: 35,
	},
	{
		group: "11",
		subgroup: "",
		value: 100.56,
		sd: 1.116,
		n: 35,
	},
	{
		group: "11",
		subgroup: "",
		value: 99.58,
		sd: 1.116,
		n: 35,
	},
	{
		group: "11",
		subgroup: "",
		value: 100.38,
		sd: 1.116,
		n: 35,
	},
	{
		group: "11",
		subgroup: "",
		value: 102.66,
		sd: 1.116,
		n: 35,
	},
	{
		group: "11",
		subgroup: "",
		value: 103.01,
		sd: 1.116,
		n: 35,
	},
	{
		group: "11",
		subgroup: "",
		value: 100.98,
		sd: 1.116,
		n: 35,
	},
	{
		group: "11",
		subgroup: "",
		value: 102.33,
		sd: 1.116,
		n: 35,
	},
	{
		group: "11",
		subgroup: "",
		value: 103.8,
		sd: 1.116,
		n: 35,
	},
	{
		group: "11",
		subgroup: "",
		value: 103.31,
		sd: 1.116,
		n: 35,
	},
	{
		group: "11",
		subgroup: "",
		value: 102.07,
		sd: 1.116,
		n: 35,
	},
	{
		group: "11",
		subgroup: "",
		value: 101.41,
		sd: 1.116,
		n: 35,
	},
	{
		group: "11",
		subgroup: "",
		value: 100.75,
		sd: 1.116,
		n: 35,
	},
	{
		group: "11",
		subgroup: "",
		value: 101.78,
		sd: 1.116,
		n: 35,
	},
	{
		group: "11",
		subgroup: "",
		value: 99.06,
		sd: 1.116,
		n: 35,
	},
	{
		group: "11",
		subgroup: "",
		value: 101.27,
		sd: 1.116,
		n: 35,
	},
	{
		group: "12",
		subgroup: "",
		value: 100.33,
		sd: 1.233,
		n: 8,
	},
	{
		group: "12",
		subgroup: "",
		value: 100.38,
		sd: 1.233,
		n: 8,
	},
	{
		group: "12",
		subgroup: "",
		value: 100.38,
		sd: 1.233,
		n: 8,
	},
	{
		group: "12",
		subgroup: "",
		value: 103.27,
		sd: 1.233,
		n: 8,
	},
	{
		group: "12",
		subgroup: "",
		value: 102.02,
		sd: 1.233,
		n: 8,
	},
	{
		group: "12",
		subgroup: "",
		value: 102.73,
		sd: 1.233,
		n: 8,
	},
	{
		group: "12",
		subgroup: "",
		value: 102.89,
		sd: 1.233,
		n: 8,
	},
	{
		group: "12",
		subgroup: "",
		value: 101.2,
		sd: 1.233,
		n: 8,
	},
	{
		group: "13",
		subgroup: "",
		value: 98.8,
		sd: 1.267,
		n: 12,
	},
	{
		group: "13",
		subgroup: "",
		value: 98.0,
		sd: 1.267,
		n: 12,
	},
	{
		group: "13",
		subgroup: "",
		value: 100.7,
		sd: 1.267,
		n: 12,
	},
	{
		group: "13",
		subgroup: "",
		value: 101.55,
		sd: 1.267,
		n: 12,
	},
	{
		group: "13",
		subgroup: "",
		value: 100.19,
		sd: 1.267,
		n: 12,
	},
	{
		group: "13",
		subgroup: "",
		value: 100.42,
		sd: 1.267,
		n: 12,
	},
	{
		group: "13",
		subgroup: "",
		value: 97.98,
		sd: 1.267,
		n: 12,
	},
	{
		group: "13",
		subgroup: "",
		value: 97.42,
		sd: 1.267,
		n: 12,
	},
	{
		group: "13",
		subgroup: "",
		value: 99.06,
		sd: 1.267,
		n: 12,
	},
	{
		group: "13",
		subgroup: "",
		value: 98.4,
		sd: 1.267,
		n: 12,
	},
	{
		group: "13",
		subgroup: "",
		value: 100.28,
		sd: 1.267,
		n: 12,
	},
	{
		group: "13",
		subgroup: "",
		value: 100.05,
		sd: 1.267,
		n: 12,
	},
	{
		group: "13",
		subgroup: "",
		value: 101.04,
		sd: 1.267,
		n: 12,
	},
	{
		group: "13",
		subgroup: "",
		value: 100.71,
		sd: 1.267,
		n: 12,
	},
	{
		group: "13",
		subgroup: "",
		value: 97.99,
		sd: 1.267,
		n: 12,
	},
	{
		group: "13",
		subgroup: "",
		value: 101.0,
		sd: 1.267,
		n: 12,
	},
	{
		group: "13",
		subgroup: "",
		value: 100.28,
		sd: 1.267,
		n: 12,
	},
	{
		group: "13",
		subgroup: "",
		value: 100.52,
		sd: 1.267,
		n: 12,
	},
	{
		group: "13",
		subgroup: "",
		value: 100.9,
		sd: 1.267,
		n: 12,
	},
	{
		group: "13",
		subgroup: "",
		value: 100.95,
		sd: 1.267,
		n: 12,
	},
	{
		group: "13",
		subgroup: "",
		value: 98.39,
		sd: 1.267,
		n: 12,
	},
	{
		group: "13",
		subgroup: "",
		value: 101.19,
		sd: 1.267,
		n: 12,
	},
	{
		group: "13",
		subgroup: "",
		value: 100.62,
		sd: 1.267,
		n: 12,
	},
	{
		group: "17",
		subgroup: "",
		value: 101.03,
		sd: 1.122,
		n: 6,
	},
	{
		group: "17",
		subgroup: "",
		value: 102.7,
		sd: 1.122,
		n: 6,
	},
	{
		group: "17",
		subgroup: "",
		value: 103.45,
		sd: 1.122,
		n: 6,
	},
	{
		group: "17",
		subgroup: "",
		value: 101.21,
		sd: 1.122,
		n: 6,
	},
	{
		group: "17",
		subgroup: "",
		value: 101.57,
		sd: 1.122,
		n: 6,
	},
	{
		group: "17",
		subgroup: "",
		value: 100.46,
		sd: 1.122,
		n: 6,
	},
	{
		group: "18",
		subgroup: "",
		value: 103.96,
		sd: 1.35,
		n: 56,
	},
	{
		group: "18",
		subgroup: "",
		value: 101.02,
		sd: 1.35,
		n: 56,
	},
	{
		group: "18",
		subgroup: "",
		value: 101.78,
		sd: 1.35,
		n: 56,
	},
	{
		group: "18",
		subgroup: "",
		value: 102.96,
		sd: 1.35,
		n: 56,
	},
	{
		group: "18",
		subgroup: "",
		value: 102.42,
		sd: 1.35,
		n: 56,
	},
	{
		group: "18",
		subgroup: "",
		value: 100.84,
		sd: 1.35,
		n: 56,
	},
	{
		group: "18",
		subgroup: "",
		value: 98.35,
		sd: 1.35,
		n: 56,
	},
	{
		group: "18",
		subgroup: "",
		value: 98.41,
		sd: 1.35,
		n: 56,
	},
	{
		group: "18",
		subgroup: "",
		value: 100.47,
		sd: 1.35,
		n: 56,
	},
	{
		group: "18",
		subgroup: "",
		value: 100.89,
		sd: 1.35,
		n: 56,
	},
	{
		group: "18",
		subgroup: "",
		value: 101.15,
		sd: 1.35,
		n: 56,
	},
	{
		group: "18",
		subgroup: "",
		value: 101.77,
		sd: 1.35,
		n: 56,
	},
	{
		group: "18",
		subgroup: "",
		value: 100.09,
		sd: 1.35,
		n: 56,
	},
	{
		group: "18",
		subgroup: "",
		value: 97.87,
		sd: 1.35,
		n: 56,
	},
	{
		group: "18",
		subgroup: "",
		value: 100.42,
		sd: 1.35,
		n: 56,
	},
	{
		group: "18",
		subgroup: "",
		value: 101.11,
		sd: 1.35,
		n: 56,
	},
	{
		group: "18",
		subgroup: "",
		value: 100.97,
		sd: 1.35,
		n: 56,
	},
	{
		group: "18",
		subgroup: "",
		value: 101.8,
		sd: 1.35,
		n: 56,
	},
	{
		group: "18",
		subgroup: "",
		value: 101.21,
		sd: 1.35,
		n: 56,
	},
	{
		group: "18",
		subgroup: "",
		value: 98.31,
		sd: 1.35,
		n: 56,
	},
	{
		group: "18",
		subgroup: "",
		value: 100.38,
		sd: 1.35,
		n: 56,
	},
	{
		group: "18",
		subgroup: "",
		value: 100.88,
		sd: 1.35,
		n: 56,
	},
	{
		group: "18",
		subgroup: "",
		value: 101.34,
		sd: 1.35,
		n: 56,
	},
	{
		group: "18",
		subgroup: "",
		value: 100.51,
		sd: 1.35,
		n: 56,
	},
	{
		group: "18",
		subgroup: "",
		value: 101.3,
		sd: 1.35,
		n: 56,
	},
	{
		group: "18",
		subgroup: "",
		value: 98.2,
		sd: 1.35,
		n: 56,
	},
	{
		group: "18",
		subgroup: "",
		value: 100.51,
		sd: 1.35,
		n: 56,
	},
	{
		group: "18",
		subgroup: "",
		value: 100.28,
		sd: 1.35,
		n: 56,
	},
	{
		group: "18",
		subgroup: "",
		value: 100.69,
		sd: 1.35,
		n: 56,
	},
	{
		group: "18",
		subgroup: "",
		value: 101.66,
		sd: 1.35,
		n: 56,
	},
	{
		group: "18",
		subgroup: "",
		value: 100.23,
		sd: 1.35,
		n: 56,
	},
	{
		group: "18",
		subgroup: "",
		value: 102.85,
		sd: 1.35,
		n: 56,
	},
	{
		group: "18",
		subgroup: "",
		value: 101.39,
		sd: 1.35,
		n: 56,
	},
	{
		group: "18",
		subgroup: "",
		value: 97.21,
		sd: 1.35,
		n: 56,
	},
	{
		group: "18",
		subgroup: "",
		value: 100.28,
		sd: 1.35,
		n: 56,
	},
	{
		group: "18",
		subgroup: "",
		value: 100.32,
		sd: 1.35,
		n: 56,
	},
	{
		group: "18",
		subgroup: "",
		value: 101.25,
		sd: 1.35,
		n: 56,
	},
	{
		group: "18",
		subgroup: "",
		value: 98.36,
		sd: 1.35,
		n: 56,
	},
	{
		group: "18",
		subgroup: "",
		value: 100.28,
		sd: 1.35,
		n: 56,
	},
	{
		group: "18",
		subgroup: "",
		value: 100.19,
		sd: 1.35,
		n: 56,
	},
	{
		group: "18",
		subgroup: "",
		value: 100.74,
		sd: 1.35,
		n: 56,
	},
	{
		group: "18",
		subgroup: "",
		value: 100.75,
		sd: 1.35,
		n: 56,
	},
	{
		group: "18",
		subgroup: "",
		value: 100.47,
		sd: 1.35,
		n: 56,
	},
	{
		group: "18",
		subgroup: "",
		value: 100,
		sd: 1.35,
		n: 56,
	},
	{
		group: "18",
		subgroup: "",
		value: 100.51,
		sd: 1.35,
		n: 56,
	},
	{
		group: "18",
		subgroup: "",
		value: 101.49,
		sd: 1.35,
		n: 56,
	},
	{
		group: "18",
		subgroup: "",
		value: 99.18,
		sd: 1.35,
		n: 56,
	},
	{
		group: "18",
		subgroup: "",
		value: 102.31,
		sd: 1.35,
		n: 56,
	},
	{
		group: "18",
		subgroup: "",
		value: 102.33,
		sd: 1.35,
		n: 56,
	},
	{
		group: "18",
		subgroup: "",
		value: 98.03,
		sd: 1.35,
		n: 56,
	},
	{
		group: "18",
		subgroup: "",
		value: 101.43,
		sd: 1.35,
		n: 56,
	},
	{
		group: "18",
		subgroup: "",
		value: 100.09,
		sd: 1.35,
		n: 56,
	},
	{
		group: "18",
		subgroup: "",
		value: 100.46,
		sd: 1.35,
		n: 56,
	},
	{
		group: "18",
		subgroup: "",
		value: 100,
		sd: 1.35,
		n: 56,
	},
	{
		group: "18",
		subgroup: "",
		value: 101.71,
		sd: 1.35,
		n: 56,
	},
	{
		group: "18",
		subgroup: "",
		value: 101.98,
		sd: 1.35,
		n: 35,
	},
	{
		group: "19",
		subgroup: "",
		value: 103.09,
		sd: 1.262,
		n: 35,
	},
	{
		group: "19",
		subgroup: "",
		value: 103.54,
		sd: 1.262,
		n: 35,
	},
	{
		group: "19",
		subgroup: "",
		value: 100.65,
		sd: 1.262,
		n: 35,
	},
	{
		group: "19",
		subgroup: "",
		value: 101.9,
		sd: 1.262,
		n: 35,
	},
	{
		group: "19",
		subgroup: "",
		value: 102.89,
		sd: 1.262,
		n: 35,
	},
	{
		group: "19",
		subgroup: "",
		value: 103.67,
		sd: 1.262,
		n: 35,
	},
	{
		group: "19",
		subgroup: "",
		value: 103.31,
		sd: 1.262,
		n: 35,
	},
	{
		group: "19",
		subgroup: "",
		value: 98.1,
		sd: 1.262,
		n: 35,
	},
	{
		group: "19",
		subgroup: "",
		value: 101.81,
		sd: 1.262,
		n: 35,
	},
	{
		group: "19",
		subgroup: "",
		value: 102.96,
		sd: 1.262,
		n: 35,
	},
	{
		group: "19",
		subgroup: "",
		value: 100.37,
		sd: 1.262,
		n: 35,
	},
	{
		group: "19",
		subgroup: "",
		value: 103.1,
		sd: 1.262,
		n: 35,
	},
	{
		group: "19",
		subgroup: "",
		value: 102.51,
		sd: 1.262,
		n: 35,
	},
	{
		group: "19",
		subgroup: "",
		value: 101.53,
		sd: 1.262,
		n: 35,
	},
	{
		group: "19",
		subgroup: "",
		value: 103.22,
		sd: 1.262,
		n: 35,
	},
	{
		group: "19",
		subgroup: "",
		value: 102.1,
		sd: 1.262,
		n: 35,
	},
	{
		group: "19",
		subgroup: "",
		value: 101.5,
		sd: 1.262,
		n: 35,
	},
	{
		group: "19",
		subgroup: "",
		value: 102.38,
		sd: 1.262,
		n: 35,
	},
	{
		group: "19",
		subgroup: "",
		value: 102.01,
		sd: 1.262,
		n: 35,
	},
	{
		group: "19",
		subgroup: "",
		value: 101.87,
		sd: 1.262,
		n: 35,
	},
	{
		group: "19",
		subgroup: "",
		value: 101.38,
		sd: 1.262,
		n: 35,
	},
	{
		group: "19",
		subgroup: "",
		value: 101.48,
		sd: 1.262,
		n: 35,
	},
	{
		group: "19",
		subgroup: "",
		value: 101.17,
		sd: 1.262,
		n: 35,
	},
	{
		group: "19",
		subgroup: "",
		value: 100.94,
		sd: 1.262,
		n: 35,
	},
	{
		group: "19",
		subgroup: "",
		value: 102.35,
		sd: 1.262,
		n: 35,
	},
	{
		group: "19",
		subgroup: "",
		value: 103.59,
		sd: 1.262,
		n: 35,
	},
	{
		group: "19",
		subgroup: "",
		value: 102.61,
		sd: 1.262,
		n: 35,
	},
	{
		group: "19",
		subgroup: "",
		value: 100.41,
		sd: 1.262,
		n: 35,
	},
	{
		group: "19",
		subgroup: "",
		value: 101.2,
		sd: 1.262,
		n: 35,
	},
	{
		group: "19",
		subgroup: "",
		value: 102.86,
		sd: 1.262,
		n: 35,
	},
	{
		group: "19",
		subgroup: "",
		value: 100.32,
		sd: 1.262,
		n: 35,
	},
	{
		group: "19",
		subgroup: "",
		value: 102.21,
		sd: 1.262,
		n: 35,
	},
	{
		group: "19",
		subgroup: "",
		value: 104.39,
		sd: 1.262,
		n: 35,
	},
	{
		group: "19",
		subgroup: "",
		value: 100.6,
		sd: 1.262,
		n: 35,
	},
	{
		group: "19",
		subgroup: "",
		value: 100.93,
		sd: 1.262,
		n: 35,
	},
	{
		group: "20",
		subgroup: "",
		value: 98.43,
		sd: 1.573,
		n: 107,
	},
	{
		group: "20",
		subgroup: "",
		value: 98.66,
		sd: 1.573,
		n: 107,
	},
	{
		group: "20",
		subgroup: "",
		value: 100.46,
		sd: 1.573,
		n: 107,
	},
	{
		group: "20",
		subgroup: "",
		value: 97.75,
		sd: 1.573,
		n: 107,
	},
	{
		group: "20",
		subgroup: "",
		value: 97.21,
		sd: 1.573,
		n: 107,
	},
	{
		group: "20",
		subgroup: "",
		value: 101.19,
		sd: 1.573,
		n: 107,
	},
	{
		group: "20",
		subgroup: "",
		value: 97.25,
		sd: 1.573,
		n: 107,
	},
	{
		group: "20",
		subgroup: "",
		value: 100.41,
		sd: 1.573,
		n: 107,
	},
	{
		group: "20",
		subgroup: "",
		value: 102.32,
		sd: 1.573,
		n: 107,
	},
	{
		group: "20",
		subgroup: "",
		value: 102.45,
		sd: 1.573,
		n: 107,
	},
	{
		group: "20",
		subgroup: "",
		value: 100.19,
		sd: 1.573,
		n: 107,
	},
	{
		group: "20",
		subgroup: "",
		value: 100.23,
		sd: 1.573,
		n: 107,
	},
	{
		group: "20",
		subgroup: "",
		value: 103.69,
		sd: 1.573,
		n: 107,
	},
	{
		group: "20",
		subgroup: "",
		value: 103.25,
		sd: 1.573,
		n: 107,
	},
	{
		group: "20",
		subgroup: "",
		value: 100.8,
		sd: 1.573,
		n: 107,
	},
	{
		group: "20",
		subgroup: "",
		value: 101.71,
		sd: 1.573,
		n: 107,
	},
	{
		group: "20",
		subgroup: "",
		value: 101.17,
		sd: 1.573,
		n: 107,
	},
	{
		group: "20",
		subgroup: "",
		value: 97.4,
		sd: 1.573,
		n: 107,
	},
	{
		group: "20",
		subgroup: "",
		value: 100.23,
		sd: 1.573,
		n: 107,
	},
	{
		group: "20",
		subgroup: "",
		value: 100.97,
		sd: 1.573,
		n: 107,
	},
	{
		group: "20",
		subgroup: "",
		value: 100.46,
		sd: 1.573,
		n: 107,
	},
	{
		group: "20",
		subgroup: "",
		value: 100.42,
		sd: 1.573,
		n: 107,
	},
	{
		group: "20",
		subgroup: "",
		value: 100.51,
		sd: 1.573,
		n: 107,
	},
	{
		group: "20",
		subgroup: "",
		value: 100.23,
		sd: 1.573,
		n: 107,
	},
	{
		group: "20",
		subgroup: "",
		value: 100.23,
		sd: 1.573,
		n: 107,
	},
	{
		group: "20",
		subgroup: "",
		value: 100.32,
		sd: 1.573,
		n: 107,
	},
	{
		group: "20",
		subgroup: "",
		value: 100.75,
		sd: 1.573,
		n: 107,
	},
	{
		group: "20",
		subgroup: "",
		value: 101.08,
		sd: 1.573,
		n: 107,
	},
	{
		group: "20",
		subgroup: "",
		value: 98.58,
		sd: 1.573,
		n: 107,
	},
	{
		group: "20",
		subgroup: "",
		value: 98.77,
		sd: 1.573,
		n: 107,
	},
	{
		group: "20",
		subgroup: "",
		value: 101.18,
		sd: 1.573,
		n: 107,
	},
	{
		group: "20",
		subgroup: "",
		value: 100.18,
		sd: 1.573,
		n: 107,
	},
	{
		group: "20",
		subgroup: "",
		value: 101.19,
		sd: 1.573,
		n: 107,
	},
	{
		group: "20",
		subgroup: "",
		value: 100.47,
		sd: 1.573,
		n: 107,
	},
	{
		group: "20",
		subgroup: "",
		value: 101.77,
		sd: 1.573,
		n: 107,
	},
	{
		group: "20",
		subgroup: "",
		value: 103.36,
		sd: 1.573,
		n: 107,
	},
	{
		group: "20",
		subgroup: "",
		value: 102.91,
		sd: 1.573,
		n: 107,
	},
	{
		group: "20",
		subgroup: "",
		value: 98.97,
		sd: 1.573,
		n: 107,
	},
	{
		group: "20",
		subgroup: "",
		value: 101.03,
		sd: 1.573,
		n: 107,
	},
	{
		group: "20",
		subgroup: "",
		value: 101.02,
		sd: 1.573,
		n: 107,
	},
	{
		group: "20",
		subgroup: "",
		value: 101.12,
		sd: 1.573,
		n: 107,
	},
	{
		group: "20",
		subgroup: "",
		value: 100.98,
		sd: 1.573,
		n: 107,
	},
	{
		group: "20",
		subgroup: "",
		value: 101.56,
		sd: 1.573,
		n: 107,
	},
	{
		group: "20",
		subgroup: "",
		value: 98.58,
		sd: 1.573,
		n: 107,
	},
	{
		group: "20",
		subgroup: "",
		value: 100.69,
		sd: 1.573,
		n: 107,
	},
	{
		group: "20",
		subgroup: "",
		value: 100.32,
		sd: 1.573,
		n: 107,
	},
	{
		group: "20",
		subgroup: "",
		value: 100.55,
		sd: 1.573,
		n: 107,
	},
	{
		group: "20",
		subgroup: "",
		value: 102.03,
		sd: 1.573,
		n: 107,
	},
	{
		group: "20",
		subgroup: "",
		value: 101.29,
		sd: 1.573,
		n: 107,
	},
	{
		group: "20",
		subgroup: "",
		value: 103.27,
		sd: 1.573,
		n: 107,
	},
	{
		group: "20",
		subgroup: "",
		value: 96.42,
		sd: 1.573,
		n: 107,
	},
	{
		group: "20",
		subgroup: "",
		value: 100.41,
		sd: 1.573,
		n: 107,
	},
	{
		group: "20",
		subgroup: "",
		value: 100.51,
		sd: 1.573,
		n: 107,
	},
	{
		group: "20",
		subgroup: "",
		value: 100.37,
		sd: 1.573,
		n: 107,
	},
	{
		group: "20",
		subgroup: "",
		value: 100.41,
		sd: 1.573,
		n: 107,
	},
	{
		group: "20",
		subgroup: "",
		value: 100.14,
		sd: 1.573,
		n: 107,
	},
	{
		group: "20",
		subgroup: "",
		value: 102.26,
		sd: 1.573,
		n: 107,
	},
	{
		group: "20",
		subgroup: "",
		value: 103.84,
		sd: 1.573,
		n: 107,
	},
	{
		group: "20",
		subgroup: "",
		value: 103.97,
		sd: 1.573,
		n: 107,
	},
	{
		group: "20",
		subgroup: "",
		value: 100.09,
		sd: 1.573,
		n: 107,
	},
	{
		group: "20",
		subgroup: "",
		value: 100.59,
		sd: 1.573,
		n: 107,
	},
	{
		group: "20",
		subgroup: "",
		value: 100.18,
		sd: 1.573,
		n: 107,
	},
	{
		group: "20",
		subgroup: "",
		value: 101.31,
		sd: 1.573,
		n: 107,
	},
	{
		group: "20",
		subgroup: "",
		value: 102.15,
		sd: 1.573,
		n: 107,
	},
	{
		group: "20",
		subgroup: "",
		value: 102.99,
		sd: 1.573,
		n: 107,
	},
	{
		group: "20",
		subgroup: "",
		value: 103.62,
		sd: 1.573,
		n: 107,
	},
	{
		group: "20",
		subgroup: "",
		value: 103.16,
		sd: 1.573,
		n: 107,
	},
	{
		group: "20",
		subgroup: "",
		value: 103.3,
		sd: 1.573,
		n: 107,
	},
	{
		group: "20",
		subgroup: "",
		value: 103.25,
		sd: 1.573,
		n: 107,
	},
	{
		group: "20",
		subgroup: "",
		value: 102.15,
		sd: 1.573,
		n: 107,
	},
	{
		group: "20",
		subgroup: "",
		value: 101.49,
		sd: 1.573,
		n: 107,
	},
	{
		group: "20",
		subgroup: "",
		value: 101.36,
		sd: 1.573,
		n: 107,
	},
	{
		group: "20",
		subgroup: "",
		value: 100.27,
		sd: 1.573,
		n: 107,
	},
	{
		group: "20",
		subgroup: "",
		value: 100.6,
		sd: 1.573,
		n: 107,
	},
	{
		group: "20",
		subgroup: "",
		value: 100.51,
		sd: 1.573,
		n: 107,
	},
	{
		group: "20",
		subgroup: "",
		value: 101.48,
		sd: 1.573,
		n: 107,
	},
	{
		group: "20",
		subgroup: "",
		value: 101.39,
		sd: 1.573,
		n: 107,
	},
	{
		group: "20",
		subgroup: "",
		value: 101.99,
		sd: 1.573,
		n: 107,
	},
	{
		group: "20",
		subgroup: "",
		value: 100.97,
		sd: 1.573,
		n: 107,
	},
	{
		group: "20",
		subgroup: "",
		value: 101.34,
		sd: 1.573,
		n: 107,
	},
	{
		group: "20",
		subgroup: "",
		value: 100.74,
		sd: 1.573,
		n: 107,
	},
	{
		group: "20",
		subgroup: "",
		value: 100.65,
		sd: 1.573,
		n: 107,
	},
	{
		group: "20",
		subgroup: "",
		value: 98.13,
		sd: 1.573,
		n: 107,
	},
	{
		group: "20",
		subgroup: "",
		value: 100.19,
		sd: 1.573,
		n: 107,
	},
	{
		group: "20",
		subgroup: "",
		value: 100.28,
		sd: 1.573,
		n: 107,
	},
	{
		group: "20",
		subgroup: "",
		value: 96.74,
		sd: 1.573,
		n: 107,
	},
	{
		group: "20",
		subgroup: "",
		value: 100.33,
		sd: 1.573,
		n: 107,
	},
	{
		group: "20",
		subgroup: "",
		value: 100.56,
		sd: 1.573,
		n: 107,
	},
	{
		group: "20",
		subgroup: "",
		value: 96.49,
		sd: 1.573,
		n: 107,
	},
	{
		group: "20",
		subgroup: "",
		value: 100.52,
		sd: 1.573,
		n: 107,
	},
	{
		group: "20",
		subgroup: "",
		value: 101.08,
		sd: 1.573,
		n: 107,
	},
	{
		group: "20",
		subgroup: "",
		value: 100.61,
		sd: 1.573,
		n: 107,
	},
	{
		group: "20",
		subgroup: "",
		value: 100.46,
		sd: 1.573,
		n: 107,
	},
	{
		group: "20",
		subgroup: "",
		value: 103.2,
		sd: 1.573,
		n: 107,
	},
	{
		group: "20",
		subgroup: "",
		value: 100.19,
		sd: 1.573,
		n: 107,
	},
	{
		group: "20",
		subgroup: "",
		value: 100.28,
		sd: 1.573,
		n: 107,
	},
	{
		group: "20",
		subgroup: "",
		value: 101.82,
		sd: 1.573,
		n: 107,
	},
	{
		group: "20",
		subgroup: "",
		value: 102.67,
		sd: 1.573,
		n: 107,
	},
	{
		group: "20",
		subgroup: "",
		value: 100.19,
		sd: 1.573,
		n: 107,
	},
	{
		group: "20",
		subgroup: "",
		value: 101.64,
		sd: 1.573,
		n: 107,
	},
	{
		group: "20",
		subgroup: "",
		value: 102.58,
		sd: 1.573,
		n: 107,
	},
	{
		group: "20",
		subgroup: "",
		value: 102.59,
		sd: 1.573,
		n: 107,
	},
	{
		group: "20",
		subgroup: "",
		value: 100.28,
		sd: 1.573,
		n: 107,
	},
	{
		group: "20",
		subgroup: "",
		value: 100.76,
		sd: 1.573,
		n: 107,
	},
	{
		group: "20",
		subgroup: "",
		value: 100.43,
		sd: 1.573,
		n: 107,
	},
	{
		group: "20",
		subgroup: "",
		value: 101.61,
		sd: 1.573,
		n: 107,
	},
	{
		group: "20",
		subgroup: "",
		value: 100.57,
		sd: 1.573,
		n: 107,
	},
	{
		group: "21",
		subgroup: "",
		value: 102.15,
		sd: 1.209,
		n: 55,
	},
	{
		group: "21",
		subgroup: "",
		value: 102.42,
		sd: 1.209,
		n: 55,
	},
	{
		group: "21",
		subgroup: "",
		value: 103.11,
		sd: 1.209,
		n: 55,
	},
	{
		group: "21",
		subgroup: "",
		value: 101.63,
		sd: 1.209,
		n: 55,
	},
	{
		group: "21",
		subgroup: "",
		value: 101.33,
		sd: 1.209,
		n: 55,
	},
	{
		group: "21",
		subgroup: "",
		value: 103.55,
		sd: 1.209,
		n: 55,
	},
	{
		group: "21",
		subgroup: "",
		value: 100.33,
		sd: 1.209,
		n: 55,
	},
	{
		group: "21",
		subgroup: "",
		value: 101.07,
		sd: 1.209,
		n: 55,
	},
	{
		group: "21",
		subgroup: "",
		value: 101.22,
		sd: 1.209,
		n: 55,
	},
	{
		group: "21",
		subgroup: "",
		value: 101.64,
		sd: 1.209,
		n: 55,
	},
	{
		group: "21",
		subgroup: "",
		value: 102.24,
		sd: 1.209,
		n: 55,
	},
	{
		group: "21",
		subgroup: "",
		value: 102.06,
		sd: 1.209,
		n: 55,
	},
	{
		group: "21",
		subgroup: "",
		value: 99.17,
		sd: 1.209,
		n: 55,
	},
	{
		group: "21",
		subgroup: "",
		value: 100.14,
		sd: 1.209,
		n: 55,
	},
	{
		group: "21",
		subgroup: "",
		value: 100.05,
		sd: 1.209,
		n: 55,
	},
	{
		group: "21",
		subgroup: "",
		value: 100.32,
		sd: 1.209,
		n: 55,
	},
	{
		group: "21",
		subgroup: "",
		value: 100.75,
		sd: 1.209,
		n: 55,
	},
	{
		group: "21",
		subgroup: "",
		value: 101.21,
		sd: 1.209,
		n: 55,
	},
	{
		group: "21",
		subgroup: "",
		value: 100.8,
		sd: 1.209,
		n: 55,
	},
	{
		group: "21",
		subgroup: "",
		value: 101.27,
		sd: 1.209,
		n: 55,
	},
	{
		group: "21",
		subgroup: "",
		value: 100.93,
		sd: 1.209,
		n: 55,
	},
	{
		group: "21",
		subgroup: "",
		value: 100.19,
		sd: 1.209,
		n: 55,
	},
	{
		group: "21",
		subgroup: "",
		value: 102.48,
		sd: 1.209,
		n: 55,
	},
	{
		group: "21",
		subgroup: "",
		value: 100.43,
		sd: 1.209,
		n: 55,
	},
	{
		group: "21",
		subgroup: "",
		value: 101.15,
		sd: 1.209,
		n: 55,
	},
	{
		group: "21",
		subgroup: "",
		value: 100.52,
		sd: 1.209,
		n: 55,
	},
	{
		group: "21",
		subgroup: "",
		value: 101.28,
		sd: 1.209,
		n: 55,
	},
	{
		group: "21",
		subgroup: "",
		value: 102.9,
		sd: 1.209,
		n: 55,
	},
	{
		group: "21",
		subgroup: "",
		value: 100.79,
		sd: 1.209,
		n: 55,
	},
	{
		group: "21",
		subgroup: "",
		value: 103.67,
		sd: 1.209,
		n: 55,
	},
	{
		group: "21",
		subgroup: "",
		value: 102.05,
		sd: 1.209,
		n: 55,
	},
	{
		group: "21",
		subgroup: "",
		value: 102.82,
		sd: 1.209,
		n: 55,
	},
	{
		group: "21",
		subgroup: "",
		value: 104.06,
		sd: 1.209,
		n: 55,
	},
	{
		group: "21",
		subgroup: "",
		value: 104.05,
		sd: 1.209,
		n: 55,
	},
	{
		group: "21",
		subgroup: "",
		value: 104.09,
		sd: 1.209,
		n: 55,
	},
	{
		group: "21",
		subgroup: "",
		value: 103.2,
		sd: 1.209,
		n: 55,
	},
	{
		group: "21",
		subgroup: "",
		value: 100.94,
		sd: 1.209,
		n: 55,
	},
	{
		group: "21",
		subgroup: "",
		value: 101.21,
		sd: 1.209,
		n: 55,
	},
	{
		group: "21",
		subgroup: "",
		value: 101.13,
		sd: 1.209,
		n: 55,
	},
	{
		group: "21",
		subgroup: "",
		value: 101.69,
		sd: 1.209,
		n: 55,
	},
	{
		group: "21",
		subgroup: "",
		value: 100.09,
		sd: 1.209,
		n: 55,
	},
	{
		group: "21",
		subgroup: "",
		value: 100.37,
		sd: 1.209,
		n: 55,
	},
	{
		group: "21",
		subgroup: "",
		value: 100.8,
		sd: 1.209,
		n: 55,
	},
	{
		group: "21",
		subgroup: "",
		value: 99.02,
		sd: 1.209,
		n: 55,
	},
	{
		group: "21",
		subgroup: "",
		value: 100.93,
		sd: 1.209,
		n: 55,
	},
	{
		group: "21",
		subgroup: "",
		value: 102.15,
		sd: 1.209,
		n: 55,
	},
	{
		group: "21",
		subgroup: "",
		value: 100.51,
		sd: 1.209,
		n: 55,
	},
	{
		group: "21",
		subgroup: "",
		value: 100.28,
		sd: 1.209,
		n: 55,
	},
	{
		group: "21",
		subgroup: "",
		value: 102.75,
		sd: 1.209,
		n: 55,
	},
	{
		group: "21",
		subgroup: "",
		value: 103.13,
		sd: 1.209,
		n: 55,
	},
	{
		group: "21",
		subgroup: "",
		value: 102.26,
		sd: 1.209,
		n: 55,
	},
	{
		group: "21",
		subgroup: "",
		value: 101.48,
		sd: 1.209,
		n: 55,
	},
	{
		group: "21",
		subgroup: "",
		value: 101.17,
		sd: 1.209,
		n: 55,
	},
	{
		group: "21",
		subgroup: "",
		value: 102.24,
		sd: 1.209,
		n: 55,
	},
	{
		group: "21",
		subgroup: "",
		value: 101.12,
		sd: 1.209,
		n: 55,
	},
	{
		group: "22",
		subgroup: "",
		value: 104.54,
		sd: 1.285,
		n: 11,
	},
	{
		group: "22",
		subgroup: "",
		value: 102.33,
		sd: 1.285,
		n: 11,
	},
	{
		group: "22",
		subgroup: "",
		value: 100.66,
		sd: 1.285,
		n: 11,
	},
	{
		group: "22",
		subgroup: "",
		value: 100.28,
		sd: 1.285,
		n: 11,
	},
	{
		group: "22",
		subgroup: "",
		value: 103.04,
		sd: 1.285,
		n: 11,
	},
	{
		group: "22",
		subgroup: "",
		value: 100.61,
		sd: 1.285,
		n: 11,
	},
	{
		group: "22",
		subgroup: "",
		value: 101.71,
		sd: 1.285,
		n: 11,
	},
	{
		group: "22",
		subgroup: "",
		value: 101.59,
		sd: 1.285,
		n: 11,
	},
	{
		group: "22",
		subgroup: "",
		value: 103.38,
		sd: 1.285,
		n: 11,
	},
	{
		group: "22",
		subgroup: "",
		value: 101.84,
		sd: 1.285,
		n: 11,
	},
	{
		group: "22",
		subgroup: "",
		value: 102.03,
		sd: 1.285,
		n: 11,
	},
	{
		group: "23",
		subgroup: "",
		value: 100.62,
		sd: 1.159,
		n: 26,
	},
	{
		group: "23",
		subgroup: "",
		value: 101.71,
		sd: 1.159,
		n: 26,
	},
	{
		group: "23",
		subgroup: "",
		value: 100.95,
		sd: 1.159,
		n: 26,
	},
	{
		group: "23",
		subgroup: "",
		value: 100.28,
		sd: 1.159,
		n: 26,
	},
	{
		group: "23",
		subgroup: "",
		value: 100.86,
		sd: 1.159,
		n: 26,
	},
	{
		group: "23",
		subgroup: "",
		value: 100.94,
		sd: 1.159,
		n: 26,
	},
	{
		group: "23",
		subgroup: "",
		value: 100.85,
		sd: 1.159,
		n: 26,
	},
	{
		group: "23",
		subgroup: "",
		value: 101.09,
		sd: 1.159,
		n: 26,
	},
	{
		group: "23",
		subgroup: "",
		value: 100.48,
		sd: 1.159,
		n: 26,
	},
	{
		group: "23",
		subgroup: "",
		value: 101.14,
		sd: 1.159,
		n: 26,
	},
	{
		group: "23",
		subgroup: "",
		value: 100,
		sd: 1.159,
		n: 26,
	},
	{
		group: "23",
		subgroup: "",
		value: 100.19,
		sd: 1.159,
		n: 26,
	},
	{
		group: "23",
		subgroup: "",
		value: 100.09,
		sd: 1.159,
		n: 26,
	},
	{
		group: "23",
		subgroup: "",
		value: 100.33,
		sd: 1.159,
		n: 26,
	},
	{
		group: "23",
		subgroup: "",
		value: 100.56,
		sd: 1.159,
		n: 26,
	},
	{
		group: "23",
		subgroup: "",
		value: 100.93,
		sd: 1.159,
		n: 26,
	},
	{
		group: "23",
		subgroup: "",
		value: 101.03,
		sd: 1.159,
		n: 26,
	},
	{
		group: "23",
		subgroup: "",
		value: 101.31,
		sd: 1.159,
		n: 26,
	},
	{
		group: "23",
		subgroup: "",
		value: 104.35,
		sd: 1.159,
		n: 26,
	},
	{
		group: "23",
		subgroup: "",
		value: 104.23,
		sd: 1.159,
		n: 26,
	},
	{
		group: "23",
		subgroup: "",
		value: 102.5,
		sd: 1.159,
		n: 26,
	},
	{
		group: "23",
		subgroup: "",
		value: 100.47,
		sd: 1.159,
		n: 26,
	},
	{
		group: "23",
		subgroup: "",
		value: 101.77,
		sd: 1.159,
		n: 26,
	},
	{
		group: "23",
		subgroup: "",
		value: 103.28,
		sd: 1.159,
		n: 26,
	},
	{
		group: "23",
		subgroup: "",
		value: 101.03,
		sd: 1.159,
		n: 26,
	},
	{
		group: "23",
		subgroup: "",
		value: 100.66,
		sd: 1.159,
		n: 26,
	},
];

export const mockScatterSubbase = [
	{
		id: "Grau de Compactação (%)",
		data: [
			{
				x: 1,
				y: 101.27,
			},
			{
				x: 2,
				y: 100.69,
			},
			{
				x: 3,
				y: 100.39,
			},
			{
				x: 4,
				y: 100.15,
			},
			{
				x: 5,
				y: 100.69,
			},
			{
				x: 6,
				y: 100.78,
			},
			{
				x: 7,
				y: 100.1,
			},
			{
				x: 8,
				y: 101.89,
			},
			{
				x: 9,
				y: 101.06,
			},
			{
				x: 10,
				y: 101.4,
			},
			{
				x: 11,
				y: 101.37,
			},
			{
				x: 12,
				y: 100.39,
			},
			{
				x: 13,
				y: 99.02,
			},
			{
				x: 14,
				y: 100.2,
			},
			{
				x: 15,
				y: 100.79,
			},
			{
				x: 16,
				y: 100.99,
			},
			{
				x: 17,
				y: 100.2,
			},
			{
				x: 18,
				y: 101.32,
			},
			{
				x: 19,
				y: 100.58,
			},
			{
				x: 20,
				y: 101.08,
			},
			{
				x: 21,
				y: 100.39,
			},
			{
				x: 22,
				y: 102.39,
			},
			{
				x: 23,
				y: 100.92,
			},
			{
				x: 24,
				y: 101.27,
			},
			{
				x: 25,
				y: 100.73,
			},
			{
				x: 26,
				y: 101.22,
			},
			{
				x: 27,
				y: 100.88,
			},
			{
				x: 28,
				y: 100.1,
			},
			{
				x: 29,
				y: 100.88,
			},
			{
				x: 30,
				y: 100.1,
			},
			{
				x: 31,
				y: 100.39,
			},
			{
				x: 32,
				y: 98.88,
			},
			{
				x: 33,
				y: 100.29,
			},
			{
				x: 34,
				y: 100.85,
			},
			{
				x: 35,
				y: 100.4,
			},
			{
				x: 36,
				y: 101.45,
			},
			{
				x: 37,
				y: 102.19,
			},
			{
				x: 38,
				y: 101.16,
			},
			{
				x: 39,
				y: 100.35,
			},
			{
				x: 40,
				y: 100.99,
			},
			{
				x: 41,
				y: 100.3,
			},
			{
				x: 42,
				y: 99,
			},
			{
				x: 43,
				y: 100.2,
			},
			{
				x: 44,
				y: 101.27,
			},
			{
				x: 45,
				y: 101.92,
			},
			{
				x: 46,
				y: 100.55,
			},
			{
				x: 47,
				y: 100.6,
			},
			{
				x: 48,
				y: 100.2,
			},
			{
				x: 49,
				y: 99.1,
			},
			{
				x: 50,
				y: 101.99,
			},
			{
				x: 51,
				y: 99,
			},
			{
				x: 52,
				y: 100.3,
			},
			{
				x: 53,
				y: 100.1,
			},
			{
				x: 54,
				y: 100.25,
			},
			{
				x: 55,
				y: 101.28,
			},
			{
				x: 56,
				y: 100.59,
			},
			{
				x: 57,
				y: 100.9,
			},
			{
				x: 58,
				y: 98.9,
			},
			{
				x: 59,
				y: 99.65,
			},
			{
				x: 60,
				y: 99.31,
			},
			{
				x: 61,
				y: 99.8,
			},
			{
				x: 62,
				y: 100.2,
			},
			{
				x: 63,
				y: 100.9,
			},
			{
				x: 64,
				y: 100.49,
			},
			{
				x: 65,
				y: 101.09,
			},
			{
				x: 66,
				y: 101.88,
			},
			{
				x: 67,
				y: 100.2,
			},
			{
				x: 68,
				y: 100.88,
			},
			{
				x: 69,
				y: 100.59,
			},
			{
				x: 70,
				y: 100.99,
			},
			{
				x: 71,
				y: 101.49,
			},
			{
				x: 72,
				y: 100.19,
			},
			{
				x: 73,
				y: 100.59,
			},
			{
				x: 74,
				y: 100.4,
			},
			{
				x: 75,
				y: 98.79,
			},
			{
				x: 76,
				y: 98.22,
			},
			{
				x: 77,
				y: 98.99,
			},
			{
				x: 78,
				y: 98.31,
			},
			{
				x: 79,
				y: 98.21,
			},
			{
				x: 80,
				y: 100.49,
			},
			{
				x: 81,
				y: 100.72,
			},
			{
				x: 82,
				y: 101.01,
			},
			{
				x: 83,
				y: 102.8,
			},
			{
				x: 84,
				y: 101.59,
			},
			{
				x: 85,
				y: 101.05,
			},
			{
				x: 86,
				y: 103.08,
			},
			{
				x: 87,
				y: 100.4,
			},
			{
				x: 88,
				y: 99.02,
			},
			{
				x: 89,
				y: 102.98,
			},
			{
				x: 90,
				y: 100.63,
			},
			{
				x: 91,
				y: 99.41,
			},
			{
				x: 92,
				y: 100.39,
			},
			{
				x: 93,
				y: 99.51,
			},
			{
				x: 94,
				y: 100.54,
			},
			{
				x: 95,
				y: 100.25,
			},
			{
				x: 96,
				y: 100.64,
			},
			{
				x: 97,
				y: 100.79,
			},
			{
				x: 98,
				y: 100,
			},
			{
				x: 99,
				y: 100.1,
			},
			{
				x: 100,
				y: 97.99,
			},
			{
				x: 101,
				y: 97.19,
			},
			{
				x: 102,
				y: 100.2,
			},
			{
				x: 103,
				y: 100.68,
			},
			{
				x: 104,
				y: 100.4,
			},
			{
				x: 105,
				y: 100.94,
			},
			{
				x: 106,
				y: 100.1,
			},
			{
				x: 107,
				y: 100.25,
			},
			{
				x: 108,
				y: 101.46,
			},
			{
				x: 109,
				y: 100.35,
			},
			{
				x: 110,
				y: 102.3,
			},
			{
				x: 111,
				y: 100.95,
			},
			{
				x: 112,
				y: 100.7,
			},
			{
				x: 113,
				y: 102.01,
			},
			{
				x: 114,
				y: 102.5,
			},
			{
				x: 115,
				y: 102.99,
			},
			{
				x: 116,
				y: 102.44,
			},
			{
				x: 117,
				y: 101.95,
			},
			{
				x: 118,
				y: 99.25,
			},
			{
				x: 119,
				y: 100.1,
			},
			{
				x: 120,
				y: 100.4,
			},
			{
				x: 121,
				y: 100.2,
			},
			{
				x: 122,
				y: 100.6,
			},
			{
				x: 123,
				y: 100.3,
			},
			{
				x: 124,
				y: 100,
			},
			{
				x: 125,
				y: 99.31,
			},
			{
				x: 126,
				y: 100.49,
			},
			{
				x: 127,
				y: 100.2,
			},
			{
				x: 128,
				y: 98.6,
			},
			{
				x: 129,
				y: 100.25,
			},
			{
				x: 130,
				y: 100,
			},
			{
				x: 131,
				y: 99.1,
			},
			{
				x: 132,
				y: 99.3,
			},
			{
				x: 133,
				y: 99.9,
			},
			{
				x: 134,
				y: 99,
			},
			{
				x: 135,
				y: 100.4,
			},
			{
				x: 136,
				y: 100.15,
			},
			{
				x: 137,
				y: 100.5,
			},
			{
				x: 138,
				y: 100.95,
			},
			{
				x: 139,
				y: 100.15,
			},
			{
				x: 140,
				y: 100.6,
			},
			{
				x: 141,
				y: 97.76,
			},
			{
				x: 142,
				y: 102.61,
			},
			{
				x: 143,
				y: 101.14,
			},
			{
				x: 144,
				y: 100.3,
			},
			{
				x: 145,
				y: 100.73,
			},
			{
				x: 146,
				y: 101.12,
			},
			{
				x: 147,
				y: 100.52,
			},
			{
				x: 148,
				y: 102.08,
			},
			{
				x: 149,
				y: 101.34,
			},
			{
				x: 150,
				y: 101.15,
			},
			{
				x: 151,
				y: 100.5,
			},
			{
				x: 152,
				y: 99.72,
			},
			{
				x: 153,
				y: 101.57,
			},
			{
				x: 154,
				y: 100.39,
			},
			{
				x: 155,
				y: 100.2,
			},
			{
				x: 156,
				y: 100.39,
			},
			{
				x: 157,
				y: 100.6,
			},
			{
				x: 158,
				y: 99,
			},
			{
				x: 159,
				y: 100.2,
			},
			{
				x: 160,
				y: 98.93,
			},
			{
				x: 161,
				y: 99.24,
			},
			{
				x: 162,
				y: 100.41,
			},
			{
				x: 163,
				y: 100.41,
			},
			{
				x: 164,
				y: 100.61,
			},
			{
				x: 165,
				y: 100.61,
			},
			{
				x: 166,
				y: 101.95,
			},
			{
				x: 167,
				y: 100.97,
			},
			{
				x: 168,
				y: 100.61,
			},
			{
				x: 169,
				y: 100.87,
			},
			{
				x: 170,
				y: 99.09,
			},
			{
				x: 171,
				y: 99.59,
			},
			{
				x: 172,
				y: 98.52,
			},
			{
				x: 173,
				y: 100.66,
			},
			{
				x: 174,
				y: 100.61,
			},
			{
				x: 175,
				y: 100.7,
			},
			{
				x: 176,
				y: 100.1,
			},
			{
				x: 177,
				y: 100.65,
			},
			{
				x: 178,
				y: 100.25,
			},
			{
				x: 179,
				y: 100.84,
			},
			{
				x: 180,
				y: 100.45,
			},
			{
				x: 181,
				y: 101,
			},
			{
				x: 182,
				y: 100.45,
			},
			{
				x: 183,
				y: 97.9,
			},
			{
				x: 184,
				y: 100.65,
			},
			{
				x: 185,
				y: 100.1,
			},
			{
				x: 186,
				y: 100.25,
			},
			{
				x: 187,
				y: 100.89,
			},
			{
				x: 188,
				y: 101.31,
			},
			{
				x: 189,
				y: 100.5,
			},
			{
				x: 190,
				y: 100.8,
			},
			{
				x: 191,
				y: 99.95,
			},
			{
				x: 192,
				y: 100.6,
			},
			{
				x: 193,
				y: 101.05,
			},
			{
				x: 194,
				y: 100.93,
			},
			{
				x: 195,
				y: 100.72,
			},
			{
				x: 196,
				y: 100.92,
			},
			{
				x: 197,
				y: 102.72,
			},
			{
				x: 198,
				y: 102.13,
			},
			{
				x: 199,
				y: 103.03,
			},
			{
				x: 200,
				y: 101.79,
			},
			{
				x: 201,
				y: 100.62,
			},
			{
				x: 202,
				y: 99.74,
			},
			{
				x: 203,
				y: 100.93,
			},
			{
				x: 204,
				y: 100.26,
			},
			{
				x: 205,
				y: 101.45,
			},
			{
				x: 206,
				y: 101.62,
			},
			{
				x: 207,
				y: 100.2,
			},
			{
				x: 208,
				y: 100.51,
			},
			{
				x: 209,
				y: 100.83,
			},
			{
				x: 210,
				y: 100.1,
			},
			{
				x: 211,
				y: 98.08,
			},
			{
				x: 212,
				y: 100.19,
			},
			{
				x: 213,
				y: 98.39,
			},
			{
				x: 214,
				y: 100.78,
			},
			{
				x: 215,
				y: 101.57,
			},
			{
				x: 216,
				y: 102.16,
			},
			{
				x: 217,
				y: 101.29,
			},
			{
				x: 218,
				y: 102.95,
			},
			{
				x: 219,
				y: 99.01,
			},
			{
				x: 220,
				y: 101.68,
			},
			{
				x: 221,
				y: 100.29,
			},
			{
				x: 222,
				y: 98.72,
			},
			{
				x: 223,
				y: 101.09,
			},
			{
				x: 224,
				y: 100.79,
			},
			{
				x: 225,
				y: 101.42,
			},
			{
				x: 226,
				y: 101.07,
			},
			{
				x: 227,
				y: 101.22,
			},
			{
				x: 228,
				y: 100.84,
			},
			{
				x: 229,
				y: 100.83,
			},
			{
				x: 230,
				y: 102.26,
			},
			{
				x: 231,
				y: 101.89,
			},
			{
				x: 232,
				y: 101.04,
			},
			{
				x: 233,
				y: 102.74,
			},
			{
				x: 234,
				y: 101.14,
			},
			{
				x: 235,
				y: 101.28,
			},
			{
				x: 236,
				y: 100.59,
			},
			{
				x: 237,
				y: 99.09,
			},
			{
				x: 238,
				y: 100.41,
			},
			{
				x: 239,
				y: 100.66,
			},
			{
				x: 240,
				y: 99.41,
			},
			{
				x: 241,
				y: 100.05,
			},
			{
				x: 242,
				y: 100.59,
			},
			{
				x: 243,
				y: 101.21,
			},
			{
				x: 244,
				y: 99,
			},
			{
				x: 245,
				y: 102.09,
			},
			{
				x: 246,
				y: 100.84,
			},
			{
				x: 247,
				y: 100.55,
			},
			{
				x: 248,
				y: 101.3,
			},
			{
				x: 249,
				y: 98.65,
			},
			{
				x: 250,
				y: 100.7,
			},
			{
				x: 251,
				y: 100.45,
			},
			{
				x: 252,
				y: 100.95,
			},
			{
				x: 253,
				y: 100.7,
			},
			{
				x: 254,
				y: 101.39,
			},
			{
				x: 255,
				y: 100.54,
			},
			{
				x: 256,
				y: 100.69,
			},
			{
				x: 257,
				y: 100.4,
			},
			{
				x: 258,
				y: 100.92,
			},
			{
				x: 259,
				y: 100.85,
			},
			{
				x: 260,
				y: 101.27,
			},
			{
				x: 261,
				y: 100.6,
			},
			{
				x: 262,
				y: 98.19,
			},
			{
				x: 263,
				y: 100.7,
			},
			{
				x: 264,
				y: 100.99,
			},
			{
				x: 265,
				y: 100.6,
			},
			{
				x: 266,
				y: 101.19,
			},
			{
				x: 267,
				y: 100.69,
			},
			{
				x: 268,
				y: 100.45,
			},
			{
				x: 269,
				y: 100.79,
			},
			{
				x: 270,
				y: 98.8,
			},
			{
				x: 271,
				y: 100.7,
			},
			{
				x: 272,
				y: 100.6,
			},
			{
				x: 273,
				y: 101.22,
			},
			{
				x: 274,
				y: 101.11,
			},
			{
				x: 275,
				y: 97.69,
			},
			{
				x: 276,
				y: 100.86,
			},
			{
				x: 277,
				y: 100.1,
			},
			{
				x: 278,
				y: 100.6,
			},
			{
				x: 279,
				y: 100.36,
			},
			{
				x: 280,
				y: 94.69,
			},
			{
				x: 281,
				y: 100.56,
			},
			{
				x: 282,
				y: 101.1,
			},
			{
				x: 283,
				y: 100.89,
			},
			{
				x: 284,
				y: 101.11,
			},
			{
				x: 285,
				y: 101.16,
			},
			{
				x: 286,
				y: 100.36,
			},
			{
				x: 287,
				y: 98.88,
			},
			{
				x: 288,
				y: 100.54,
			},
			{
				x: 289,
				y: 100.1,
			},
			{
				x: 290,
				y: 100.49,
			},
			{
				x: 291,
				y: 100.58,
			},
			{
				x: 292,
				y: 102.23,
			},
			{
				x: 293,
				y: 100.59,
			},
			{
				x: 294,
				y: 100.34,
			},
			{
				x: 295,
				y: 98.41,
			},
			{
				x: 296,
				y: 98.12,
			},
			{
				x: 297,
				y: 100.7,
			},
			{
				x: 298,
				y: 100.24,
			},
			{
				x: 299,
				y: 99.21,
			},
			{
				x: 300,
				y: 100.84,
			},
			{
				x: 301,
				y: 100.79,
			},
			{
				x: 302,
				y: 100.69,
			},
			{
				x: 303,
				y: 100.44,
			},
			{
				x: 304,
				y: 101.01,
			},
			{
				x: 305,
				y: 100.2,
			},
			{
				x: 306,
				y: 101.84,
			},
			{
				x: 307,
				y: 100.34,
			},
			{
				x: 308,
				y: 100.92,
			},
			{
				x: 309,
				y: 98.18,
			},
			{
				x: 310,
				y: 100.3,
			},
			{
				x: 311,
				y: 100.39,
			},
			{
				x: 312,
				y: 100.79,
			},
			{
				x: 313,
				y: 100.2,
			},
			{
				x: 314,
				y: 97.99,
			},
			{
				x: 315,
				y: 98.28,
			},
			{
				x: 316,
				y: 100.1,
			},
			{
				x: 317,
				y: 100.1,
			},
			{
				x: 318,
				y: 100.29,
			},
			{
				x: 319,
				y: 100,
			},
			{
				x: 320,
				y: 100.87,
			},
			{
				x: 321,
				y: 100.57,
			},
			{
				x: 322,
				y: 101.87,
			},
			{
				x: 323,
				y: 101.03,
			},
			{
				x: 324,
				y: 103,
			},
			{
				x: 325,
				y: 102.13,
			},
			{
				x: 326,
				y: 100.93,
			},
			{
				x: 327,
				y: 100.1,
			},
			{
				x: 328,
				y: 100.61,
			},
			{
				x: 329,
				y: 100.1,
			},
			{
				x: 330,
				y: 100.92,
			},
			{
				x: 331,
				y: 104.24,
			},
			{
				x: 332,
				y: 103.9,
			},
			{
				x: 333,
				y: 100.1,
			},
			{
				x: 334,
				y: 100.72,
			},
			{
				x: 335,
				y: 100.62,
			},
			{
				x: 336,
				y: 100.15,
			},
			{
				x: 337,
				y: 100.41,
			},
			{
				x: 338,
				y: 100.77,
			},
			{
				x: 339,
				y: 101.34,
			},
			{
				x: 340,
				y: 100.62,
			},
			{
				x: 341,
				y: 100.57,
			},
			{
				x: 342,
				y: 99.17,
			},
			{
				x: 343,
				y: 101.51,
			},
			{
				x: 344,
				y: 101.62,
			},
			{
				x: 345,
				y: 100.41,
			},
			{
				x: 346,
				y: 97.93,
			},
			{
				x: 347,
				y: 100.36,
			},
			{
				x: 348,
				y: 100.31,
			},
			{
				x: 349,
				y: 100.05,
			},
			{
				x: 350,
				y: 101.5,
			},
			{
				x: 351,
				y: 102.03,
			},
			{
				x: 352,
				y: 97.31,
			},
			{
				x: 353,
				y: 97.9,
			},
			{
				x: 354,
				y: 100.31,
			},
			{
				x: 355,
				y: 101.05,
			},
			{
				x: 356,
				y: 100.72,
			},
			{
				x: 357,
				y: 100.94,
			},
			{
				x: 358,
				y: 100.1,
			},
			{
				x: 359,
				y: 97.93,
			},
			{
				x: 360,
				y: 100.52,
			},
			{
				x: 361,
				y: 104.94,
			},
			{
				x: 362,
				y: 100.15,
			},
			{
				x: 363,
				y: 104.31,
			},
			{
				x: 364,
				y: 100.62,
			},
			{
				x: 365,
				y: 99.7,
			},
			{
				x: 366,
				y: 96.12,
			},
			{
				x: 367,
				y: 96.43,
			},
			{
				x: 368,
				y: 99.65,
			},
			{
				x: 369,
				y: 100.3,
			},
			{
				x: 370,
				y: 100.86,
			},
			{
				x: 371,
				y: 100.35,
			},
			{
				x: 372,
				y: 95.96,
			},
			{
				x: 373,
				y: 96.68,
			},
			{
				x: 374,
				y: 95.72,
			},
			{
				x: 375,
				y: 103.34,
			},
			{
				x: 376,
				y: 100.6,
			},
			{
				x: 377,
				y: 100.35,
			},
			{
				x: 378,
				y: 100.71,
			},
			{
				x: 379,
				y: 101.07,
			},
			{
				x: 380,
				y: 101.42,
			},
			{
				x: 381,
				y: 100.61,
			},
			{
				x: 382,
				y: 100.36,
			},
			{
				x: 383,
				y: 101.03,
			},
			{
				x: 384,
				y: 100.88,
			},
			{
				x: 385,
				y: 100.82,
			},
			{
				x: 386,
				y: 100.72,
			},
			{
				x: 387,
				y: 99.95,
			},
			{
				x: 388,
				y: 100.6,
			},
			{
				x: 389,
				y: 100.2,
			},
			{
				x: 390,
				y: 100.86,
			},
			{
				x: 391,
				y: 100.61,
			},
			{
				x: 392,
				y: 100.05,
			},
			{
				x: 393,
				y: 100.8,
			},
			{
				x: 394,
				y: 101.52,
			},
			{
				x: 395,
				y: 100.92,
			},
			{
				x: 396,
				y: 100.25,
			},
			{
				x: 397,
				y: 100.15,
			},
			{
				x: 398,
				y: 100.25,
			},
			{
				x: 399,
				y: 100.39,
			},
			{
				x: 400,
				y: 100.68,
			},
			{
				x: 401,
				y: 100.58,
			},
			{
				x: 402,
				y: 100.31,
			},
			{
				x: 403,
				y: 100,
			},
			{
				x: 404,
				y: 100.83,
			},
			{
				x: 405,
				y: 100.05,
			},
			{
				x: 406,
				y: 101.02,
			},
			{
				x: 407,
				y: 97.44,
			},
			{
				x: 408,
				y: 100.31,
			},
			{
				x: 409,
				y: 100.16,
			},
			{
				x: 410,
				y: 100.15,
			},
			{
				x: 411,
				y: 100.41,
			},
			{
				x: 412,
				y: 101.26,
			},
			{
				x: 413,
				y: 103.7,
			},
			{
				x: 414,
				y: 101.52,
			},
			{
				x: 415,
				y: 105.02,
			},
			{
				x: 416,
				y: 103.03,
			},
			{
				x: 417,
				y: 100.3,
			},
			{
				x: 418,
				y: 102.22,
			},
			{
				x: 419,
				y: 99.07,
			},
			{
				x: 420,
				y: 100.93,
			},
			{
				x: 421,
				y: 102.79,
			},
			{
				x: 422,
				y: 100.88,
			},
			{
				x: 423,
				y: 98.14,
			},
			{
				x: 424,
				y: 97.69,
			},
			{
				x: 425,
				y: 100.4,
			},
			{
				x: 426,
				y: 100.5,
			},
			{
				x: 427,
				y: 100.53,
			},
			{
				x: 428,
				y: 100.37,
			},
			{
				x: 429,
				y: 103.34,
			},
			{
				x: 430,
				y: 100.4,
			},
			{
				x: 431,
				y: 102.73,
			},
			{
				x: 432,
				y: 101.95,
			},
			{
				x: 433,
				y: 99.24,
			},
			{
				x: 434,
				y: 103.16,
			},
			{
				x: 435,
				y: 101.01,
			},
			{
				x: 436,
				y: 97,
			},
			{
				x: 437,
				y: 100.57,
			},
			{
				x: 438,
				y: 100.62,
			},
			{
				x: 439,
				y: 100.2,
			},
			{
				x: 440,
				y: 100.1,
			},
			{
				x: 441,
				y: 97.88,
			},
			{
				x: 442,
				y: 101.37,
			},
			{
				x: 443,
				y: 100.15,
			},
			{
				x: 444,
				y: 100.45,
			},
			{
				x: 445,
				y: 99.11,
			},
			{
				x: 446,
				y: 101.99,
			},
			{
				x: 447,
				y: 100.49,
			},
			{
				x: 448,
				y: 99.4,
			},
			{
				x: 449,
				y: 102.54,
			},
			{
				x: 450,
				y: 100,
			},
			{
				x: 451,
				y: 104.43,
			},
			{
				x: 452,
				y: 100.53,
			},
			{
				x: 453,
				y: 100,
			},
			{
				x: 454,
				y: 100.5,
			},
			{
				x: 455,
				y: 100.4,
			},
			{
				x: 456,
				y: 100.4,
			},
			{
				x: 457,
				y: 101.59,
			},
			{
				x: 458,
				y: 101.13,
			},
			{
				x: 459,
				y: 100.44,
			},
			{
				x: 460,
				y: 101.19,
			},
			{
				x: 461,
				y: 100.24,
			},
			{
				x: 462,
				y: 100.58,
			},
			{
				x: 463,
				y: 101.98,
			},
			{
				x: 464,
				y: 100.35,
			},
			{
				x: 465,
				y: 100,
			},
			{
				x: 466,
				y: 101,
			},
			{
				x: 467,
				y: 101.97,
			},
			{
				x: 468,
				y: 101.3,
			},
			{
				x: 469,
				y: 100.99,
			},
			{
				x: 470,
				y: 100,
			},
			{
				x: 471,
				y: 100.45,
			},
			{
				x: 472,
				y: 100.45,
			},
			{
				x: 473,
				y: 102.31,
			},
			{
				x: 474,
				y: 102.24,
			},
			{
				x: 475,
				y: 105.85,
			},
			{
				x: 476,
				y: 103.67,
			},
			{
				x: 477,
				y: 100.8,
			},
			{
				x: 478,
				y: 101.45,
			},
			{
				x: 479,
				y: 103.37,
			},
			{
				x: 480,
				y: 100.94,
			},
			{
				x: 481,
				y: 101.22,
			},
			{
				x: 482,
				y: 100.4,
			},
			{
				x: 483,
				y: 100.93,
			},
			{
				x: 484,
				y: 100.5,
			},
			{
				x: 485,
				y: 99.4,
			},
			{
				x: 486,
				y: 100.1,
			},
			{
				x: 487,
				y: 98.12,
			},
			{
				x: 488,
				y: 98.62,
			},
			{
				x: 489,
				y: 100.51,
			},
			{
				x: 490,
				y: 100.2,
			},
			{
				x: 491,
				y: 101.18,
			},
			{
				x: 492,
				y: 101.42,
			},
			{
				x: 493,
				y: 100.89,
			},
			{
				x: 494,
				y: 100,
			},
			{
				x: 495,
				y: 100,
			},
			{
				x: 496,
				y: 98.94,
			},
			{
				x: 497,
				y: 101.11,
			},
			{
				x: 498,
				y: 100.35,
			},
			{
				x: 499,
				y: 101.06,
			},
			{
				x: 500,
				y: 100.5,
			},
			{
				x: 501,
				y: 102.43,
			},
			{
				x: 502,
				y: 100.8,
			},
			{
				x: 503,
				y: 101.21,
			},
			{
				x: 504,
				y: 101.86,
			},
			{
				x: 505,
				y: 101.46,
			},
			{
				x: 506,
				y: 103.57,
			},
			{
				x: 507,
				y: 102.38,
			},
		],
	},
];

export const mockBoxplotSubbase = [
	{
		group: "7",
		subgroup: "",
		value: 101.27,
		sd: 0.767303932,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 100.69,
		sd: 0.767303932,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 100.39,
		sd: 0.767303932,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 100.15,
		sd: 0.767303932,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 100.69,
		sd: 0.767303932,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 100.78,
		sd: 0.767303932,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 100.1,
		sd: 0.767303932,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 101.89,
		sd: 0.767303932,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 101.06,
		sd: 0.767303932,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 101.4,
		sd: 0.767303932,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 101.37,
		sd: 0.767303932,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 100.39,
		sd: 0.767303932,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 99.02,
		sd: 0.767303932,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 100.2,
		sd: 0.767303932,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 100.79,
		sd: 0.767303932,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 100.99,
		sd: 0.767303932,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 100.2,
		sd: 0.767303932,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 101.32,
		sd: 0.767303932,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 100.58,
		sd: 0.767303932,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 101.08,
		sd: 0.767303932,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 100.39,
		sd: 0.767303932,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 102.39,
		sd: 0.767303932,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 100.92,
		sd: 0.767303932,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 101.27,
		sd: 0.767303932,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 100.73,
		sd: 0.767303932,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 101.22,
		sd: 0.767303932,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 100.88,
		sd: 0.767303932,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 100.1,
		sd: 0.767303932,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 100.88,
		sd: 0.767303932,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 100.1,
		sd: 0.767303932,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 100.39,
		sd: 0.767303932,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 98.88,
		sd: 0.767303932,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 100.29,
		sd: 0.767303932,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 100.85,
		sd: 0.767303932,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 100.4,
		sd: 0.767303932,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 101.45,
		sd: 0.767303932,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 102.19,
		sd: 0.767303932,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 101.16,
		sd: 0.767303932,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 100.35,
		sd: 0.767303932,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 100.99,
		sd: 0.767303932,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 100.3,
		sd: 0.767303932,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 99,
		sd: 0.767303932,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 100.2,
		sd: 0.767303932,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 101.27,
		sd: 0.767303932,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 101.92,
		sd: 0.767303932,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 100.55,
		sd: 0.767303932,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 100.6,
		sd: 0.767303932,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 100.2,
		sd: 0.767303932,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 99.1,
		sd: 0.767303932,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 101.99,
		sd: 0.767303932,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 99,
		sd: 0.767303932,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 100.3,
		sd: 0.767303932,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 100.1,
		sd: 0.767303932,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 100.25,
		sd: 0.767303932,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 101.28,
		sd: 0.767303932,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 100.59,
		sd: 0.767303932,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 100.9,
		sd: 1.184707609,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 98.9,
		sd: 1.184707609,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 99.65,
		sd: 1.184707609,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 99.31,
		sd: 1.184707609,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 99.8,
		sd: 1.184707609,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 100.2,
		sd: 1.184707609,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 100.9,
		sd: 1.184707609,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 100.49,
		sd: 1.184707609,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 101.09,
		sd: 1.184707609,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 101.88,
		sd: 1.184707609,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 100.2,
		sd: 1.184707609,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 100.88,
		sd: 1.184707609,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 100.59,
		sd: 1.184707609,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 100.99,
		sd: 1.184707609,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 101.49,
		sd: 1.184707609,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 100.19,
		sd: 1.184707609,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 100.59,
		sd: 1.184707609,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 100.4,
		sd: 1.184707609,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 98.79,
		sd: 1.184707609,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 98.22,
		sd: 1.184707609,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 98.99,
		sd: 1.184707609,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 98.31,
		sd: 1.184707609,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 98.21,
		sd: 1.184707609,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 100.49,
		sd: 1.184707609,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 100.72,
		sd: 1.184707609,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 101.01,
		sd: 1.184707609,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 102.8,
		sd: 1.184707609,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 101.59,
		sd: 1.184707609,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 101.05,
		sd: 1.184707609,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 103.08,
		sd: 1.184707609,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 100.4,
		sd: 1.184707609,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 99.02,
		sd: 1.184707609,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 102.98,
		sd: 1.184707609,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 100.63,
		sd: 1.184707609,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 99.41,
		sd: 1.184707609,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 100.39,
		sd: 1.184707609,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 99.51,
		sd: 1.184707609,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 100.54,
		sd: 1.184707609,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 100.25,
		sd: 1.184707609,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 100.64,
		sd: 1.184707609,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 100.79,
		sd: 1.184707609,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 100,
		sd: 1.184707609,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 100.1,
		sd: 1.184707609,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 97.99,
		sd: 1.184707609,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 97.19,
		sd: 1.184707609,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 100.2,
		sd: 1.184707609,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 100.68,
		sd: 1.184707609,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 100.4,
		sd: 1.184707609,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 100.94,
		sd: 1.184707609,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 100.1,
		sd: 1.184707609,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 100.25,
		sd: 1.184707609,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 101.46,
		sd: 1.184707609,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 100.35,
		sd: 1.184707609,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 102.3,
		sd: 1.184707609,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 100.95,
		sd: 1.184707609,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 100.7,
		sd: 1.184707609,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 102.01,
		sd: 1.184707609,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 102.5,
		sd: 1.184707609,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 102.99,
		sd: 1.184707609,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 102.44,
		sd: 1.184707609,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 101.95,
		sd: 1.184707609,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 99.25,
		sd: 1.184707609,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 100.1,
		sd: 1.184707609,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 100.4,
		sd: 1.184707609,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 100.2,
		sd: 1.184707609,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 100.6,
		sd: 1.184707609,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 100.3,
		sd: 1.184707609,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 100,
		sd: 1.184707609,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 99.31,
		sd: 1.184707609,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 100.49,
		sd: 1.184707609,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 100.2,
		sd: 1.184707609,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 98.6,
		sd: 1.184707609,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 100.25,
		sd: 1.184707609,
		n: 130,
	},
	{
		group: "7",
		subgroup: "",
		value: 100,
		sd: 1.184707609,
		n: 130,
	},
	{
		group: "8",
		subgroup: "",
		value: 99.1,
		sd: 0.668933646,
		n: 10,
	},
	{
		group: "8",
		subgroup: "",
		value: 99.3,
		sd: 0.668933646,
		n: 10,
	},
	{
		group: "8",
		subgroup: "",
		value: 99.9,
		sd: 0.668933646,
		n: 10,
	},
	{
		group: "8",
		subgroup: "",
		value: 99,
		sd: 0.668933646,
		n: 10,
	},
	{
		group: "8",
		subgroup: "",
		value: 100.4,
		sd: 0.668933646,
		n: 10,
	},
	{
		group: "8",
		subgroup: "",
		value: 100.15,
		sd: 0.668933646,
		n: 10,
	},
	{
		group: "8",
		subgroup: "",
		value: 100.5,
		sd: 0.668933646,
		n: 10,
	},
	{
		group: "8",
		subgroup: "",
		value: 100.95,
		sd: 0.668933646,
		n: 10,
	},
	{
		group: "8",
		subgroup: "",
		value: 100.15,
		sd: 0.668933646,
		n: 10,
	},
	{
		group: "8",
		subgroup: "",
		value: 100.6,
		sd: 0.668933646,
		n: 10,
	},
	{
		group: "9",
		subgroup: "",
		value: 97.76,
		sd: 1.012494317,
		n: 53,
	},
	{
		group: "9",
		subgroup: "",
		value: 102.61,
		sd: 1.012494317,
		n: 53,
	},
	{
		group: "9",
		subgroup: "",
		value: 101.14,
		sd: 1.012494317,
		n: 53,
	},
	{
		group: "9",
		subgroup: "",
		value: 100.3,
		sd: 1.012494317,
		n: 53,
	},
	{
		group: "9",
		subgroup: "",
		value: 100.73,
		sd: 1.012494317,
		n: 53,
	},
	{
		group: "9",
		subgroup: "",
		value: 101.12,
		sd: 1.012494317,
		n: 53,
	},
	{
		group: "9",
		subgroup: "",
		value: 100.52,
		sd: 1.012494317,
		n: 53,
	},
	{
		group: "9",
		subgroup: "",
		value: 102.08,
		sd: 1.012494317,
		n: 53,
	},
	{
		group: "9",
		subgroup: "",
		value: 101.34,
		sd: 1.012494317,
		n: 53,
	},
	{
		group: "9",
		subgroup: "",
		value: 101.15,
		sd: 1.012494317,
		n: 53,
	},
	{
		group: "9",
		subgroup: "",
		value: 100.5,
		sd: 1.012494317,
		n: 53,
	},
	{
		group: "9",
		subgroup: "",
		value: 99.72,
		sd: 1.012494317,
		n: 53,
	},
	{
		group: "9",
		subgroup: "",
		value: 101.57,
		sd: 1.012494317,
		n: 53,
	},
	{
		group: "9",
		subgroup: "",
		value: 100.39,
		sd: 1.012494317,
		n: 53,
	},
	{
		group: "9",
		subgroup: "",
		value: 100.2,
		sd: 1.012494317,
		n: 53,
	},
	{
		group: "9",
		subgroup: "",
		value: 100.39,
		sd: 1.012494317,
		n: 53,
	},
	{
		group: "9",
		subgroup: "",
		value: 100.6,
		sd: 1.012494317,
		n: 53,
	},
	{
		group: "9",
		subgroup: "",
		value: 99,
		sd: 1.012494317,
		n: 53,
	},
	{
		group: "9",
		subgroup: "",
		value: 100.2,
		sd: 1.012494317,
		n: 53,
	},
	{
		group: "9",
		subgroup: "",
		value: 98.93,
		sd: 1.012494317,
		n: 53,
	},
	{
		group: "9",
		subgroup: "",
		value: 99.24,
		sd: 1.012494317,
		n: 53,
	},
	{
		group: "9",
		subgroup: "",
		value: 100.41,
		sd: 1.012494317,
		n: 53,
	},
	{
		group: "9",
		subgroup: "",
		value: 100.41,
		sd: 1.012494317,
		n: 53,
	},
	{
		group: "9",
		subgroup: "",
		value: 100.61,
		sd: 1.012494317,
		n: 53,
	},
	{
		group: "9",
		subgroup: "",
		value: 100.61,
		sd: 1.012494317,
		n: 53,
	},
	{
		group: "9",
		subgroup: "",
		value: 101.95,
		sd: 1.012494317,
		n: 53,
	},
	{
		group: "9",
		subgroup: "",
		value: 100.97,
		sd: 1.012494317,
		n: 53,
	},
	{
		group: "9",
		subgroup: "",
		value: 100.61,
		sd: 1.012494317,
		n: 53,
	},
	{
		group: "9",
		subgroup: "",
		value: 100.87,
		sd: 1.012494317,
		n: 53,
	},
	{
		group: "9",
		subgroup: "",
		value: 99.09,
		sd: 1.012494317,
		n: 53,
	},
	{
		group: "9",
		subgroup: "",
		value: 99.59,
		sd: 1.012494317,
		n: 53,
	},
	{
		group: "9",
		subgroup: "",
		value: 98.52,
		sd: 1.012494317,
		n: 53,
	},
	{
		group: "9",
		subgroup: "",
		value: 100.66,
		sd: 1.012494317,
		n: 53,
	},
	{
		group: "9",
		subgroup: "",
		value: 100.61,
		sd: 1.012494317,
		n: 53,
	},
	{
		group: "9",
		subgroup: "",
		value: 100.7,
		sd: 0.711596919,
		n: 53,
	},
	{
		group: "9",
		subgroup: "",
		value: 100.1,
		sd: 0.711596919,
		n: 53,
	},
	{
		group: "9",
		subgroup: "",
		value: 100.65,
		sd: 0.711596919,
		n: 53,
	},
	{
		group: "9",
		subgroup: "",
		value: 100.25,
		sd: 0.711596919,
		n: 53,
	},
	{
		group: "9",
		subgroup: "",
		value: 100.84,
		sd: 0.711596919,
		n: 53,
	},
	{
		group: "9",
		subgroup: "",
		value: 100.45,
		sd: 0.711596919,
		n: 53,
	},
	{
		group: "9",
		subgroup: "",
		value: 101,
		sd: 0.711596919,
		n: 53,
	},
	{
		group: "9",
		subgroup: "",
		value: 100.45,
		sd: 0.711596919,
		n: 53,
	},
	{
		group: "9",
		subgroup: "",
		value: 97.9,
		sd: 0.711596919,
		n: 53,
	},
	{
		group: "9",
		subgroup: "",
		value: 100.65,
		sd: 0.711596919,
		n: 53,
	},
	{
		group: "9",
		subgroup: "",
		value: 100.1,
		sd: 0.711596919,
		n: 53,
	},
	{
		group: "9",
		subgroup: "",
		value: 100.25,
		sd: 0.711596919,
		n: 53,
	},
	{
		group: "9",
		subgroup: "",
		value: 100.89,
		sd: 0.711596919,
		n: 53,
	},
	{
		group: "9",
		subgroup: "",
		value: 101.31,
		sd: 0.711596919,
		n: 53,
	},
	{
		group: "9",
		subgroup: "",
		value: 100.5,
		sd: 0.711596919,
		n: 53,
	},
	{
		group: "9",
		subgroup: "",
		value: 100.8,
		sd: 0.711596919,
		n: 53,
	},
	{
		group: "9",
		subgroup: "",
		value: 99.95,
		sd: 0.711596919,
		n: 53,
	},
	{
		group: "9",
		subgroup: "",
		value: 100.6,
		sd: 0.711596919,
		n: 53,
	},
	{
		group: "9",
		subgroup: "",
		value: 101.05,
		sd: 0.711596919,
		n: 53,
	},
	{
		group: "10",
		subgroup: "",
		value: 100.93,
		sd: 0.985448675,
		n: 36,
	},
	{
		group: "10",
		subgroup: "",
		value: 100.72,
		sd: 0.985448675,
		n: 36,
	},
	{
		group: "10",
		subgroup: "",
		value: 100.92,
		sd: 0.985448675,
		n: 36,
	},
	{
		group: "10",
		subgroup: "",
		value: 102.72,
		sd: 0.985448675,
		n: 36,
	},
	{
		group: "10",
		subgroup: "",
		value: 102.13,
		sd: 0.985448675,
		n: 36,
	},
	{
		group: "10",
		subgroup: "",
		value: 103.03,
		sd: 0.985448675,
		n: 36,
	},
	{
		group: "10",
		subgroup: "",
		value: 101.79,
		sd: 0.985448675,
		n: 36,
	},
	{
		group: "10",
		subgroup: "",
		value: 100.62,
		sd: 0.985448675,
		n: 36,
	},
	{
		group: "10",
		subgroup: "",
		value: 99.74,
		sd: 0.985448675,
		n: 36,
	},
	{
		group: "10",
		subgroup: "",
		value: 100.93,
		sd: 0.985448675,
		n: 36,
	},
	{
		group: "10",
		subgroup: "",
		value: 100.26,
		sd: 0.985448675,
		n: 36,
	},
	{
		group: "10",
		subgroup: "",
		value: 101.45,
		sd: 0.985448675,
		n: 36,
	},
	{
		group: "10",
		subgroup: "",
		value: 101.62,
		sd: 1.165553843,
		n: 36,
	},
	{
		group: "10",
		subgroup: "",
		value: 100.2,
		sd: 1.165553843,
		n: 36,
	},
	{
		group: "10",
		subgroup: "",
		value: 100.51,
		sd: 1.165553843,
		n: 36,
	},
	{
		group: "10",
		subgroup: "",
		value: 100.83,
		sd: 1.165553843,
		n: 36,
	},
	{
		group: "10",
		subgroup: "",
		value: 100.1,
		sd: 1.165553843,
		n: 36,
	},
	{
		group: "10",
		subgroup: "",
		value: 98.08,
		sd: 1.165553843,
		n: 36,
	},
	{
		group: "10",
		subgroup: "",
		value: 100.19,
		sd: 1.165553843,
		n: 36,
	},
	{
		group: "10",
		subgroup: "",
		value: 98.39,
		sd: 1.165553843,
		n: 36,
	},
	{
		group: "10",
		subgroup: "",
		value: 100.78,
		sd: 1.165553843,
		n: 36,
	},
	{
		group: "10",
		subgroup: "",
		value: 101.57,
		sd: 1.165553843,
		n: 36,
	},
	{
		group: "10",
		subgroup: "",
		value: 102.16,
		sd: 1.165553843,
		n: 36,
	},
	{
		group: "10",
		subgroup: "",
		value: 101.29,
		sd: 1.165553843,
		n: 36,
	},
	{
		group: "10",
		subgroup: "",
		value: 102.95,
		sd: 1.165553843,
		n: 36,
	},
	{
		group: "10",
		subgroup: "",
		value: 99.01,
		sd: 1.165553843,
		n: 36,
	},
	{
		group: "10",
		subgroup: "",
		value: 101.68,
		sd: 1.165553843,
		n: 36,
	},
	{
		group: "10",
		subgroup: "",
		value: 100.29,
		sd: 1.165553843,
		n: 36,
	},
	{
		group: "10",
		subgroup: "",
		value: 98.72,
		sd: 1.165553843,
		n: 36,
	},
	{
		group: "10",
		subgroup: "",
		value: 101.09,
		sd: 1.165553843,
		n: 36,
	},
	{
		group: "10",
		subgroup: "",
		value: 100.79,
		sd: 1.165553843,
		n: 36,
	},
	{
		group: "10",
		subgroup: "",
		value: 101.42,
		sd: 1.165553843,
		n: 36,
	},
	{
		group: "10",
		subgroup: "",
		value: 101.07,
		sd: 1.165553843,
		n: 36,
	},
	{
		group: "10",
		subgroup: "",
		value: 101.22,
		sd: 1.165553843,
		n: 36,
	},
	{
		group: "10",
		subgroup: "",
		value: 100.84,
		sd: 1.165553843,
		n: 36,
	},
	{
		group: "10",
		subgroup: "",
		value: 100.83,
		sd: 1.165553843,
		n: 36,
	},
	{
		group: "11",
		subgroup: "",
		value: 102.26,
		sd: 1.045714699,
		n: 13,
	},
	{
		group: "11",
		subgroup: "",
		value: 101.89,
		sd: 1.045714699,
		n: 13,
	},
	{
		group: "11",
		subgroup: "",
		value: 101.04,
		sd: 1.045714699,
		n: 13,
	},
	{
		group: "11",
		subgroup: "",
		value: 102.74,
		sd: 1.045714699,
		n: 13,
	},
	{
		group: "11",
		subgroup: "",
		value: 101.14,
		sd: 1.045714699,
		n: 13,
	},
	{
		group: "11",
		subgroup: "",
		value: 101.28,
		sd: 1.045714699,
		n: 13,
	},
	{
		group: "11",
		subgroup: "",
		value: 100.59,
		sd: 1.045714699,
		n: 13,
	},
	{
		group: "11",
		subgroup: "",
		value: 99.09,
		sd: 1.045714699,
		n: 13,
	},
	{
		group: "11",
		subgroup: "",
		value: 100.41,
		sd: 1.045714699,
		n: 13,
	},
	{
		group: "11",
		subgroup: "",
		value: 100.66,
		sd: 1.045714699,
		n: 13,
	},
	{
		group: "11",
		subgroup: "",
		value: 99.41,
		sd: 1.045714699,
		n: 13,
	},
	{
		group: "11",
		subgroup: "",
		value: 100.05,
		sd: 1.045714699,
		n: 13,
	},
	{
		group: "11",
		subgroup: "",
		value: 100.59,
		sd: 1.045714699,
		n: 13,
	},
	{
		group: "12",
		subgroup: "",
		value: 101.21,
		sd: 1.234082171,
		n: 44,
	},
	{
		group: "12",
		subgroup: "",
		value: 99,
		sd: 1.234082171,
		n: 44,
	},
	{
		group: "12",
		subgroup: "",
		value: 102.09,
		sd: 1.234082171,
		n: 44,
	},
	{
		group: "12",
		subgroup: "",
		value: 100.84,
		sd: 1.234082171,
		n: 44,
	},
	{
		group: "12",
		subgroup: "",
		value: 100.55,
		sd: 1.234082171,
		n: 44,
	},
	{
		group: "12",
		subgroup: "",
		value: 101.3,
		sd: 1.234082171,
		n: 44,
	},
	{
		group: "12",
		subgroup: "",
		value: 98.65,
		sd: 1.234082171,
		n: 44,
	},
	{
		group: "12",
		subgroup: "",
		value: 100.7,
		sd: 1.234082171,
		n: 44,
	},
	{
		group: "12",
		subgroup: "",
		value: 100.45,
		sd: 1.234082171,
		n: 44,
	},
	{
		group: "12",
		subgroup: "",
		value: 100.95,
		sd: 1.234082171,
		n: 44,
	},
	{
		group: "12",
		subgroup: "",
		value: 100.7,
		sd: 1.234082171,
		n: 44,
	},
	{
		group: "12",
		subgroup: "",
		value: 101.39,
		sd: 1.234082171,
		n: 44,
	},
	{
		group: "12",
		subgroup: "",
		value: 100.54,
		sd: 1.234082171,
		n: 44,
	},
	{
		group: "12",
		subgroup: "",
		value: 100.69,
		sd: 1.234082171,
		n: 44,
	},
	{
		group: "12",
		subgroup: "",
		value: 100.4,
		sd: 1.234082171,
		n: 44,
	},
	{
		group: "12",
		subgroup: "",
		value: 100.92,
		sd: 1.234082171,
		n: 44,
	},
	{
		group: "12",
		subgroup: "",
		value: 100.85,
		sd: 1.234082171,
		n: 44,
	},
	{
		group: "12",
		subgroup: "",
		value: 101.27,
		sd: 1.234082171,
		n: 44,
	},
	{
		group: "12",
		subgroup: "",
		value: 100.6,
		sd: 1.234082171,
		n: 44,
	},
	{
		group: "12",
		subgroup: "",
		value: 98.19,
		sd: 1.234082171,
		n: 44,
	},
	{
		group: "12",
		subgroup: "",
		value: 100.7,
		sd: 1.234082171,
		n: 44,
	},
	{
		group: "12",
		subgroup: "",
		value: 100.99,
		sd: 1.234082171,
		n: 44,
	},
	{
		group: "12",
		subgroup: "",
		value: 100.6,
		sd: 1.234082171,
		n: 44,
	},
	{
		group: "12",
		subgroup: "",
		value: 101.19,
		sd: 1.234082171,
		n: 44,
	},
	{
		group: "12",
		subgroup: "",
		value: 100.69,
		sd: 1.234082171,
		n: 44,
	},
	{
		group: "12",
		subgroup: "",
		value: 100.45,
		sd: 1.234082171,
		n: 44,
	},
	{
		group: "12",
		subgroup: "",
		value: 100.79,
		sd: 1.234082171,
		n: 44,
	},
	{
		group: "12",
		subgroup: "",
		value: 98.8,
		sd: 1.234082171,
		n: 44,
	},
	{
		group: "12",
		subgroup: "",
		value: 100.7,
		sd: 1.234082171,
		n: 44,
	},
	{
		group: "12",
		subgroup: "",
		value: 100.6,
		sd: 1.234082171,
		n: 44,
	},
	{
		group: "12",
		subgroup: "",
		value: 101.22,
		sd: 1.234082171,
		n: 44,
	},
	{
		group: "12",
		subgroup: "",
		value: 101.11,
		sd: 1.234082171,
		n: 44,
	},
	{
		group: "12",
		subgroup: "",
		value: 97.69,
		sd: 1.234082171,
		n: 44,
	},
	{
		group: "12",
		subgroup: "",
		value: 100.86,
		sd: 1.234082171,
		n: 44,
	},
	{
		group: "12",
		subgroup: "",
		value: 100.1,
		sd: 1.234082171,
		n: 44,
	},
	{
		group: "12",
		subgroup: "",
		value: 100.6,
		sd: 1.234082171,
		n: 44,
	},
	{
		group: "12",
		subgroup: "",
		value: 100.36,
		sd: 1.234082171,
		n: 44,
	},
	{
		group: "12",
		subgroup: "",
		value: 94.69,
		sd: 1.234082171,
		n: 44,
	},
	{
		group: "12",
		subgroup: "",
		value: 100.56,
		sd: 1.234082171,
		n: 44,
	},
	{
		group: "12",
		subgroup: "",
		value: 101.1,
		sd: 1.234082171,
		n: 44,
	},
	{
		group: "12",
		subgroup: "",
		value: 100.89,
		sd: 1.234082171,
		n: 44,
	},
	{
		group: "12",
		subgroup: "",
		value: 101.11,
		sd: 1.234082171,
		n: 44,
	},
	{
		group: "12",
		subgroup: "",
		value: 101.16,
		sd: 1.234082171,
		n: 44,
	},
	{
		group: "12",
		subgroup: "",
		value: 100.36,
		sd: 1.234082171,
		n: 44,
	},
	{
		group: "13",
		subgroup: "",
		value: 98.88,
		sd: 1.015769786,
		n: 33,
	},
	{
		group: "13",
		subgroup: "",
		value: 100.54,
		sd: 1.015769786,
		n: 33,
	},
	{
		group: "13",
		subgroup: "",
		value: 100.1,
		sd: 1.015769786,
		n: 33,
	},
	{
		group: "13",
		subgroup: "",
		value: 100.49,
		sd: 1.015769786,
		n: 33,
	},
	{
		group: "13",
		subgroup: "",
		value: 100.58,
		sd: 1.015769786,
		n: 33,
	},
	{
		group: "13",
		subgroup: "",
		value: 102.23,
		sd: 1.015769786,
		n: 33,
	},
	{
		group: "13",
		subgroup: "",
		value: 100.59,
		sd: 1.015769786,
		n: 33,
	},
	{
		group: "13",
		subgroup: "",
		value: 100.34,
		sd: 1.015769786,
		n: 33,
	},
	{
		group: "13",
		subgroup: "",
		value: 98.41,
		sd: 1.015769786,
		n: 33,
	},
	{
		group: "13",
		subgroup: "",
		value: 98.12,
		sd: 1.015769786,
		n: 33,
	},
	{
		group: "13",
		subgroup: "",
		value: 100.7,
		sd: 1.015769786,
		n: 33,
	},
	{
		group: "13",
		subgroup: "",
		value: 100.24,
		sd: 1.015769786,
		n: 33,
	},
	{
		group: "13",
		subgroup: "",
		value: 99.21,
		sd: 1.015769786,
		n: 33,
	},
	{
		group: "13",
		subgroup: "",
		value: 100.84,
		sd: 1.015769786,
		n: 33,
	},
	{
		group: "13",
		subgroup: "",
		value: 100.79,
		sd: 1.015769786,
		n: 33,
	},
	{
		group: "13",
		subgroup: "",
		value: 100.69,
		sd: 1.015769786,
		n: 33,
	},
	{
		group: "13",
		subgroup: "",
		value: 100.44,
		sd: 1.015769786,
		n: 33,
	},
	{
		group: "13",
		subgroup: "",
		value: 101.01,
		sd: 1.015769786,
		n: 33,
	},
	{
		group: "13",
		subgroup: "",
		value: 100.2,
		sd: 1.015769786,
		n: 33,
	},
	{
		group: "13",
		subgroup: "",
		value: 101.84,
		sd: 1.015769786,
		n: 33,
	},
	{
		group: "13",
		subgroup: "",
		value: 100.34,
		sd: 1.015769786,
		n: 33,
	},
	{
		group: "13",
		subgroup: "",
		value: 100.92,
		sd: 1.015769786,
		n: 33,
	},
	{
		group: "13",
		subgroup: "",
		value: 98.18,
		sd: 1.015769786,
		n: 33,
	},
	{
		group: "13",
		subgroup: "",
		value: 100.3,
		sd: 1.015769786,
		n: 33,
	},
	{
		group: "13",
		subgroup: "",
		value: 100.39,
		sd: 1.015769786,
		n: 33,
	},
	{
		group: "13",
		subgroup: "",
		value: 100.79,
		sd: 1.015769786,
		n: 33,
	},
	{
		group: "13",
		subgroup: "",
		value: 100.2,
		sd: 1.015769786,
		n: 33,
	},
	{
		group: "13",
		subgroup: "",
		value: 97.99,
		sd: 1.015769786,
		n: 33,
	},
	{
		group: "13",
		subgroup: "",
		value: 98.28,
		sd: 1.015769786,
		n: 33,
	},
	{
		group: "13",
		subgroup: "",
		value: 100.1,
		sd: 1.015769786,
		n: 33,
	},
	{
		group: "13",
		subgroup: "",
		value: 100.1,
		sd: 1.015769786,
		n: 33,
	},
	{
		group: "13",
		subgroup: "",
		value: 100.29,
		sd: 1.015769786,
		n: 33,
	},
	{
		group: "13",
		subgroup: "",
		value: 100,
		sd: 1.015769786,
		n: 33,
	},
	{
		group: "19",
		subgroup: "",
		value: 100.87,
		sd: 1.283789453,
		n: 45,
	},
	{
		group: "19",
		subgroup: "",
		value: 100.57,
		sd: 1.283789453,
		n: 45,
	},
	{
		group: "19",
		subgroup: "",
		value: 101.87,
		sd: 1.283789453,
		n: 45,
	},
	{
		group: "19",
		subgroup: "",
		value: 101.03,
		sd: 1.283789453,
		n: 45,
	},
	{
		group: "19",
		subgroup: "",
		value: 103,
		sd: 1.283789453,
		n: 45,
	},
	{
		group: "19",
		subgroup: "",
		value: 102.13,
		sd: 1.283789453,
		n: 45,
	},
	{
		group: "19",
		subgroup: "",
		value: 100.93,
		sd: 1.283789453,
		n: 45,
	},
	{
		group: "19",
		subgroup: "",
		value: 100.1,
		sd: 1.283789453,
		n: 45,
	},
	{
		group: "19",
		subgroup: "",
		value: 100.61,
		sd: 1.283789453,
		n: 45,
	},
	{
		group: "19",
		subgroup: "",
		value: 100.1,
		sd: 1.283789453,
		n: 45,
	},
	{
		group: "19",
		subgroup: "",
		value: 100.92,
		sd: 1.283789453,
		n: 45,
	},
	{
		group: "19",
		subgroup: "",
		value: 104.24,
		sd: 1.283789453,
		n: 45,
	},
	{
		group: "19",
		subgroup: "",
		value: 103.9,
		sd: 1.283789453,
		n: 45,
	},
	{
		group: "19",
		subgroup: "",
		value: 100.1,
		sd: 1.283789453,
		n: 45,
	},
	{
		group: "19",
		subgroup: "",
		value: 100.72,
		sd: 1.283789453,
		n: 45,
	},
	{
		group: "19",
		subgroup: "",
		value: 100.62,
		sd: 1.283789453,
		n: 45,
	},
	{
		group: "19",
		subgroup: "",
		value: 100.15,
		sd: 1.283789453,
		n: 45,
	},
	{
		group: "19",
		subgroup: "",
		value: 100.41,
		sd: 1.283789453,
		n: 45,
	},
	{
		group: "19",
		subgroup: "",
		value: 100.77,
		sd: 1.682713857,
		n: 45,
	},
	{
		group: "19",
		subgroup: "",
		value: 101.34,
		sd: 1.682713857,
		n: 45,
	},
	{
		group: "19",
		subgroup: "",
		value: 100.62,
		sd: 1.682713857,
		n: 45,
	},
	{
		group: "19",
		subgroup: "",
		value: 100.57,
		sd: 1.682713857,
		n: 45,
	},
	{
		group: "19",
		subgroup: "",
		value: 99.17,
		sd: 1.682713857,
		n: 45,
	},
	{
		group: "19",
		subgroup: "",
		value: 101.51,
		sd: 1.682713857,
		n: 45,
	},
	{
		group: "19",
		subgroup: "",
		value: 101.62,
		sd: 1.682713857,
		n: 45,
	},
	{
		group: "19",
		subgroup: "",
		value: 100.41,
		sd: 1.682713857,
		n: 45,
	},
	{
		group: "19",
		subgroup: "",
		value: 97.93,
		sd: 1.682713857,
		n: 45,
	},
	{
		group: "19",
		subgroup: "",
		value: 100.36,
		sd: 1.682713857,
		n: 45,
	},
	{
		group: "19",
		subgroup: "",
		value: 100.31,
		sd: 1.682713857,
		n: 45,
	},
	{
		group: "19",
		subgroup: "",
		value: 100.05,
		sd: 1.682713857,
		n: 45,
	},
	{
		group: "19",
		subgroup: "",
		value: 101.5,
		sd: 1.682713857,
		n: 45,
	},
	{
		group: "19",
		subgroup: "",
		value: 102.03,
		sd: 1.682713857,
		n: 45,
	},
	{
		group: "19",
		subgroup: "",
		value: 97.31,
		sd: 1.682713857,
		n: 45,
	},
	{
		group: "19",
		subgroup: "",
		value: 97.9,
		sd: 1.682713857,
		n: 45,
	},
	{
		group: "19",
		subgroup: "",
		value: 100.31,
		sd: 1.682713857,
		n: 45,
	},
	{
		group: "19",
		subgroup: "",
		value: 101.05,
		sd: 1.682713857,
		n: 45,
	},
	{
		group: "19",
		subgroup: "",
		value: 100.72,
		sd: 1.682713857,
		n: 45,
	},
	{
		group: "19",
		subgroup: "",
		value: 100.94,
		sd: 1.682713857,
		n: 45,
	},
	{
		group: "19",
		subgroup: "",
		value: 100.1,
		sd: 1.682713857,
		n: 45,
	},
	{
		group: "19",
		subgroup: "",
		value: 97.93,
		sd: 1.682713857,
		n: 45,
	},
	{
		group: "19",
		subgroup: "",
		value: 100.52,
		sd: 1.682713857,
		n: 45,
	},
	{
		group: "19",
		subgroup: "",
		value: 104.94,
		sd: 1.682713857,
		n: 45,
	},
	{
		group: "19",
		subgroup: "",
		value: 100.15,
		sd: 1.682713857,
		n: 45,
	},
	{
		group: "19",
		subgroup: "",
		value: 104.31,
		sd: 1.682713857,
		n: 45,
	},
	{
		group: "19",
		subgroup: "",
		value: 100.62,
		sd: 1.682713857,
		n: 45,
	},
	{
		group: "20",
		subgroup: "",
		value: 99.7,
		sd: 1.643005954,
		n: 83,
	},
	{
		group: "20",
		subgroup: "",
		value: 96.12,
		sd: 1.643005954,
		n: 83,
	},
	{
		group: "20",
		subgroup: "",
		value: 96.43,
		sd: 1.643005954,
		n: 83,
	},
	{
		group: "20",
		subgroup: "",
		value: 99.65,
		sd: 1.643005954,
		n: 83,
	},
	{
		group: "20",
		subgroup: "",
		value: 100.3,
		sd: 1.643005954,
		n: 83,
	},
	{
		group: "20",
		subgroup: "",
		value: 100.86,
		sd: 1.643005954,
		n: 83,
	},
	{
		group: "20",
		subgroup: "",
		value: 100.35,
		sd: 1.643005954,
		n: 83,
	},
	{
		group: "20",
		subgroup: "",
		value: 95.96,
		sd: 1.643005954,
		n: 83,
	},
	{
		group: "20",
		subgroup: "",
		value: 96.68,
		sd: 1.643005954,
		n: 83,
	},
	{
		group: "20",
		subgroup: "",
		value: 95.72,
		sd: 1.643005954,
		n: 83,
	},
	{
		group: "20",
		subgroup: "",
		value: 103.34,
		sd: 1.643005954,
		n: 83,
	},
	{
		group: "20",
		subgroup: "",
		value: 100.6,
		sd: 1.643005954,
		n: 83,
	},
	{
		group: "20",
		subgroup: "",
		value: 100.35,
		sd: 1.643005954,
		n: 83,
	},
	{
		group: "20",
		subgroup: "",
		value: 100.71,
		sd: 1.643005954,
		n: 83,
	},
	{
		group: "20",
		subgroup: "",
		value: 101.07,
		sd: 1.643005954,
		n: 83,
	},
	{
		group: "20",
		subgroup: "",
		value: 101.42,
		sd: 1.643005954,
		n: 83,
	},
	{
		group: "20",
		subgroup: "",
		value: 100.61,
		sd: 1.643005954,
		n: 83,
	},
	{
		group: "20",
		subgroup: "",
		value: 100.36,
		sd: 1.643005954,
		n: 83,
	},
	{
		group: "20",
		subgroup: "",
		value: 101.03,
		sd: 1.643005954,
		n: 83,
	},
	{
		group: "20",
		subgroup: "",
		value: 100.88,
		sd: 1.643005954,
		n: 83,
	},
	{
		group: "20",
		subgroup: "",
		value: 100.82,
		sd: 1.643005954,
		n: 83,
	},
	{
		group: "20",
		subgroup: "",
		value: 100.72,
		sd: 1.643005954,
		n: 83,
	},
	{
		group: "20",
		subgroup: "",
		value: 99.95,
		sd: 1.643005954,
		n: 83,
	},
	{
		group: "20",
		subgroup: "",
		value: 100.6,
		sd: 1.643005954,
		n: 83,
	},
	{
		group: "20",
		subgroup: "",
		value: 100.2,
		sd: 1.643005954,
		n: 83,
	},
	{
		group: "20",
		subgroup: "",
		value: 100.86,
		sd: 1.643005954,
		n: 83,
	},
	{
		group: "20",
		subgroup: "",
		value: 100.61,
		sd: 1.643005954,
		n: 83,
	},
	{
		group: "20",
		subgroup: "",
		value: 100.05,
		sd: 1.643005954,
		n: 83,
	},
	{
		group: "20",
		subgroup: "",
		value: 100.8,
		sd: 1.643005954,
		n: 83,
	},
	{
		group: "20",
		subgroup: "",
		value: 101.52,
		sd: 1.643005954,
		n: 83,
	},
	{
		group: "20",
		subgroup: "",
		value: 100.92,
		sd: 1.643005954,
		n: 83,
	},
	{
		group: "20",
		subgroup: "",
		value: 100.25,
		sd: 1.643005954,
		n: 83,
	},
	{
		group: "20",
		subgroup: "",
		value: 100.15,
		sd: 1.643005954,
		n: 83,
	},
	{
		group: "20",
		subgroup: "",
		value: 100.25,
		sd: 1.643005954,
		n: 83,
	},
	{
		group: "20",
		subgroup: "",
		value: 100.39,
		sd: 1.643005954,
		n: 83,
	},
	{
		group: "20",
		subgroup: "",
		value: 100.68,
		sd: 1.643005954,
		n: 83,
	},
	{
		group: "20",
		subgroup: "",
		value: 100.58,
		sd: 1.643005954,
		n: 83,
	},
	{
		group: "20",
		subgroup: "",
		value: 100.31,
		sd: 1.643005954,
		n: 83,
	},
	{
		group: "20",
		subgroup: "",
		value: 100,
		sd: 1.65388166,
		n: 83,
	},
	{
		group: "20",
		subgroup: "",
		value: 100.83,
		sd: 1.65388166,
		n: 83,
	},
	{
		group: "20",
		subgroup: "",
		value: 100.05,
		sd: 1.65388166,
		n: 83,
	},
	{
		group: "20",
		subgroup: "",
		value: 101.02,
		sd: 1.65388166,
		n: 83,
	},
	{
		group: "20",
		subgroup: "",
		value: 97.44,
		sd: 1.65388166,
		n: 83,
	},
	{
		group: "20",
		subgroup: "",
		value: 100.31,
		sd: 1.65388166,
		n: 83,
	},
	{
		group: "20",
		subgroup: "",
		value: 100.16,
		sd: 1.65388166,
		n: 83,
	},
	{
		group: "20",
		subgroup: "",
		value: 100.15,
		sd: 1.65388166,
		n: 83,
	},
	{
		group: "20",
		subgroup: "",
		value: 100.41,
		sd: 1.65388166,
		n: 83,
	},
	{
		group: "20",
		subgroup: "",
		value: 101.26,
		sd: 1.65388166,
		n: 83,
	},
	{
		group: "20",
		subgroup: "",
		value: 103.7,
		sd: 1.65388166,
		n: 83,
	},
	{
		group: "20",
		subgroup: "",
		value: 101.52,
		sd: 1.65388166,
		n: 83,
	},
	{
		group: "20",
		subgroup: "",
		value: 105.02,
		sd: 1.65388166,
		n: 83,
	},
	{
		group: "20",
		subgroup: "",
		value: 103.03,
		sd: 1.65388166,
		n: 83,
	},
	{
		group: "20",
		subgroup: "",
		value: 100.3,
		sd: 1.65388166,
		n: 83,
	},
	{
		group: "20",
		subgroup: "",
		value: 102.22,
		sd: 1.65388166,
		n: 83,
	},
	{
		group: "20",
		subgroup: "",
		value: 99.07,
		sd: 1.65388166,
		n: 83,
	},
	{
		group: "20",
		subgroup: "",
		value: 100.93,
		sd: 1.65388166,
		n: 83,
	},
	{
		group: "20",
		subgroup: "",
		value: 102.79,
		sd: 1.65388166,
		n: 83,
	},
	{
		group: "20",
		subgroup: "",
		value: 100.88,
		sd: 1.65388166,
		n: 83,
	},
	{
		group: "20",
		subgroup: "",
		value: 98.14,
		sd: 1.65388166,
		n: 83,
	},
	{
		group: "20",
		subgroup: "",
		value: 97.69,
		sd: 1.65388166,
		n: 83,
	},
	{
		group: "20",
		subgroup: "",
		value: 100.4,
		sd: 1.65388166,
		n: 83,
	},
	{
		group: "20",
		subgroup: "",
		value: 100.5,
		sd: 1.65388166,
		n: 83,
	},
	{
		group: "20",
		subgroup: "",
		value: 100.53,
		sd: 1.65388166,
		n: 83,
	},
	{
		group: "20",
		subgroup: "",
		value: 100.37,
		sd: 1.65388166,
		n: 83,
	},
	{
		group: "20",
		subgroup: "",
		value: 103.34,
		sd: 1.65388166,
		n: 83,
	},
	{
		group: "20",
		subgroup: "",
		value: 100.4,
		sd: 1.65388166,
		n: 83,
	},
	{
		group: "20",
		subgroup: "",
		value: 102.73,
		sd: 1.65388166,
		n: 83,
	},
	{
		group: "20",
		subgroup: "",
		value: 101.95,
		sd: 1.65388166,
		n: 83,
	},
	{
		group: "20",
		subgroup: "",
		value: 99.24,
		sd: 1.65388166,
		n: 83,
	},
	{
		group: "20",
		subgroup: "",
		value: 103.16,
		sd: 1.65388166,
		n: 83,
	},
	{
		group: "20",
		subgroup: "",
		value: 101.01,
		sd: 1.65388166,
		n: 83,
	},
	{
		group: "20",
		subgroup: "",
		value: 97,
		sd: 1.65388166,
		n: 83,
	},
	{
		group: "20",
		subgroup: "",
		value: 100.57,
		sd: 1.65388166,
		n: 83,
	},
	{
		group: "20",
		subgroup: "",
		value: 100.62,
		sd: 1.65388166,
		n: 83,
	},
	{
		group: "20",
		subgroup: "",
		value: 100.2,
		sd: 1.65388166,
		n: 83,
	},
	{
		group: "20",
		subgroup: "",
		value: 100.1,
		sd: 1.65388166,
		n: 83,
	},
	{
		group: "20",
		subgroup: "",
		value: 97.88,
		sd: 1.65388166,
		n: 83,
	},
	{
		group: "20",
		subgroup: "",
		value: 101.37,
		sd: 1.65388166,
		n: 83,
	},
	{
		group: "20",
		subgroup: "",
		value: 100.15,
		sd: 1.65388166,
		n: 83,
	},
	{
		group: "20",
		subgroup: "",
		value: 100.45,
		sd: 1.65388166,
		n: 83,
	},
	{
		group: "20",
		subgroup: "",
		value: 99.11,
		sd: 1.65388166,
		n: 83,
	},
	{
		group: "20",
		subgroup: "",
		value: 101.99,
		sd: 1.65388166,
		n: 83,
	},
	{
		group: "20",
		subgroup: "",
		value: 100.49,
		sd: 1.65388166,
		n: 83,
	},
	{
		group: "21",
		subgroup: "",
		value: 99.4,
		sd: 1.040444937,
		n: 48,
	},
	{
		group: "21",
		subgroup: "",
		value: 102.54,
		sd: 1.040444937,
		n: 48,
	},
	{
		group: "21",
		subgroup: "",
		value: 100,
		sd: 1.040444937,
		n: 48,
	},
	{
		group: "21",
		subgroup: "",
		value: 104.43,
		sd: 1.040444937,
		n: 48,
	},
	{
		group: "21",
		subgroup: "",
		value: 100.53,
		sd: 1.040444937,
		n: 48,
	},
	{
		group: "21",
		subgroup: "",
		value: 100,
		sd: 1.040444937,
		n: 48,
	},
	{
		group: "21",
		subgroup: "",
		value: 100.5,
		sd: 1.040444937,
		n: 48,
	},
	{
		group: "21",
		subgroup: "",
		value: 100.4,
		sd: 1.040444937,
		n: 48,
	},
	{
		group: "21",
		subgroup: "",
		value: 100.4,
		sd: 1.040444937,
		n: 48,
	},
	{
		group: "21",
		subgroup: "",
		value: 101.59,
		sd: 1.040444937,
		n: 48,
	},
	{
		group: "21",
		subgroup: "",
		value: 101.13,
		sd: 1.040444937,
		n: 48,
	},
	{
		group: "21",
		subgroup: "",
		value: 100.44,
		sd: 1.040444937,
		n: 48,
	},
	{
		group: "21",
		subgroup: "",
		value: 101.19,
		sd: 1.040444937,
		n: 48,
	},
	{
		group: "21",
		subgroup: "",
		value: 100.24,
		sd: 1.040444937,
		n: 48,
	},
	{
		group: "21",
		subgroup: "",
		value: 100.58,
		sd: 1.040444937,
		n: 48,
	},
	{
		group: "21",
		subgroup: "",
		value: 101.98,
		sd: 1.040444937,
		n: 48,
	},
	{
		group: "21",
		subgroup: "",
		value: 100.35,
		sd: 1.040444937,
		n: 48,
	},
	{
		group: "21",
		subgroup: "",
		value: 100,
		sd: 1.040444937,
		n: 48,
	},
	{
		group: "21",
		subgroup: "",
		value: 101,
		sd: 1.040444937,
		n: 48,
	},
	{
		group: "21",
		subgroup: "",
		value: 101.97,
		sd: 1.040444937,
		n: 48,
	},
	{
		group: "21",
		subgroup: "",
		value: 101.3,
		sd: 1.040444937,
		n: 48,
	},
	{
		group: "21",
		subgroup: "",
		value: 100.99,
		sd: 1.040444937,
		n: 48,
	},
	{
		group: "21",
		subgroup: "",
		value: 100,
		sd: 1.040444937,
		n: 48,
	},
	{
		group: "21",
		subgroup: "",
		value: 100.45,
		sd: 1.040444937,
		n: 48,
	},
	{
		group: "21",
		subgroup: "",
		value: 100.45,
		sd: 1.040444937,
		n: 48,
	},
	{
		group: "21",
		subgroup: "",
		value: 102.31,
		sd: 1.040444937,
		n: 48,
	},
	{
		group: "21",
		subgroup: "",
		value: 102.24,
		sd: 1.040444937,
		n: 48,
	},
	{
		group: "21",
		subgroup: "",
		value: 105.85,
		sd: 1.040444937,
		n: 48,
	},
	{
		group: "21",
		subgroup: "",
		value: 103.67,
		sd: 1.040444937,
		n: 48,
	},
	{
		group: "21",
		subgroup: "",
		value: 100.8,
		sd: 1.040444937,
		n: 48,
	},
	{
		group: "21",
		subgroup: "",
		value: 101.45,
		sd: 1.040444937,
		n: 48,
	},
	{
		group: "21",
		subgroup: "",
		value: 103.37,
		sd: 1.040444937,
		n: 48,
	},
	{
		group: "21",
		subgroup: "",
		value: 100.94,
		sd: 1.040444937,
		n: 48,
	},
	{
		group: "21",
		subgroup: "",
		value: 101.22,
		sd: 1.040444937,
		n: 48,
	},
	{
		group: "21",
		subgroup: "",
		value: 100.4,
		sd: 1.040444937,
		n: 48,
	},
	{
		group: "21",
		subgroup: "",
		value: 100.93,
		sd: 1.040444937,
		n: 48,
	},
	{
		group: "21",
		subgroup: "",
		value: 100.5,
		sd: 1.040444937,
		n: 48,
	},
	{
		group: "21",
		subgroup: "",
		value: 99.4,
		sd: 1.040444937,
		n: 48,
	},
	{
		group: "21",
		subgroup: "",
		value: 100.1,
		sd: 1.040444937,
		n: 48,
	},
	{
		group: "21",
		subgroup: "",
		value: 98.12,
		sd: 1.040444937,
		n: 48,
	},
	{
		group: "21",
		subgroup: "",
		value: 98.62,
		sd: 1.040444937,
		n: 48,
	},
	{
		group: "21",
		subgroup: "",
		value: 100.51,
		sd: 1.040444937,
		n: 48,
	},
	{
		group: "21",
		subgroup: "",
		value: 100.2,
		sd: 1.040444937,
		n: 48,
	},
	{
		group: "21",
		subgroup: "",
		value: 101.18,
		sd: 1.040444937,
		n: 48,
	},
	{
		group: "21",
		subgroup: "",
		value: 101.42,
		sd: 1.040444937,
		n: 48,
	},
	{
		group: "21",
		subgroup: "",
		value: 100.89,
		sd: 1.040444937,
		n: 48,
	},
	{
		group: "21",
		subgroup: "",
		value: 100,
		sd: 1.040444937,
		n: 48,
	},
	{
		group: "21",
		subgroup: "",
		value: 100,
		sd: 1.040444937,
		n: 48,
	},
	{
		group: "23",
		subgroup: "",
		value: 98.94,
		sd: 1.183173378,
		n: 12,
	},
	{
		group: "23",
		subgroup: "",
		value: 101.11,
		sd: 1.183173378,
		n: 12,
	},
	{
		group: "23",
		subgroup: "",
		value: 100.35,
		sd: 1.183173378,
		n: 12,
	},
	{
		group: "23",
		subgroup: "",
		value: 101.06,
		sd: 1.183173378,
		n: 12,
	},
	{
		group: "23",
		subgroup: "",
		value: 100.5,
		sd: 1.183173378,
		n: 12,
	},
	{
		group: "23",
		subgroup: "",
		value: 102.43,
		sd: 1.183173378,
		n: 12,
	},
	{
		group: "23",
		subgroup: "",
		value: 100.8,
		sd: 1.183173378,
		n: 12,
	},
	{
		group: "23",
		subgroup: "",
		value: 101.21,
		sd: 1.183173378,
		n: 12,
	},
	{
		group: "23",
		subgroup: "",
		value: 101.86,
		sd: 1.183173378,
		n: 12,
	},
	{
		group: "23",
		subgroup: "",
		value: 101.46,
		sd: 1.183173378,
		n: 12,
	},
	{
		group: "23",
		subgroup: "",
		value: 103.57,
		sd: 1.183173378,
		n: 12,
	},
	{
		group: "23",
		subgroup: "",
		value: 102.38,
		sd: 1.183173378,
		n: 12,
	},
];
