// // import { Box, Grid, Typography, useTheme, Button } from "@mui/material";
// // import { DataGrid, GridToolbarContainer, GridToolbarExport, useGridApiRef } from "@mui/x-data-grid";
// // import { useState, useEffect } from "react";
// // import AccordionModel from "../../../../components/Accordion";
// // import { tokens } from "../../../../theme";
// // import {
// // 	preencherArrayDosValoresDeEntrada,
// // 	retornaArrayComValoresEQuantidadeDeAmostras,
// // 	montaArrayComUmaGranulometria,
// // } from "../../../../aux/functions/functions";
// // import NovoSistemaPavimentacao from "../../../../components/NovoSistemaPavimentacao";
// // import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
// // import axios from "axios";

// // const columns = [
// // 	{
// // 		field: "id",
// // 		headerName: "ID",
// // 		width: 15,
// // 	},
// // 	{
// // 		field: "trecho",
// // 		headerName: "Trecho",
// // 		type: "number",
// // 		width: 10,
// // 		editable: true,
// // 	},
// // 	{
// // 		field: "data",
// // 		headerName: "Data",
// // 		type: "date",
// // 		width: 150,
// // 		editable: true,
// // 	},
// // 	{
// // 		field: "valor",
// // 		headerName: "Valor",
// // 		type: "number",
// // 		width: 130,
// // 		editable: true,
// // 	},
// // ];

// // const rows = [];

// // const peneiras = ["Peneira ASTM 1 1/2” (38,1 mm", "Peneira ASTM 1” (25,4 mm)", "Peneira ASTM 1” (25,4 mm)"];

// // function CustomToolbar() {
// // 	return (
// // 		<GridToolbarContainer>
// // 			<GridToolbarExport />
// // 		</GridToolbarContainer>
// // 	);
// // }

// // function CustomNoRowsOverlay() {
// // 	return (
// // 		<>
// // 			<Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", mt: 2 }}>Sem linhas</Box>
// // 		</>
// // 	);
// // }

// // const Ligacao = (props, { getFromChild }) => {
// // 	const theme = useTheme("light");
// // 	const colors = tokens(theme.palette.mode);
// // 	const [rows, setRows] = useState([]);
// // 	const [exibeValores, setExibeValores] = useState();
// // 	const [nomeParametro, setNomeParametro] = useState();
// // 	const [valoresDaCamada, setValoresDaCamada] = useState([]);

// // 	async function fetchListaCamadas(idmedicao, camada) {
// // 		try {
// // 			await axios
// // 				.get(`${process.env.REACT_APP_URL_BASE}/camada`, {
// // 					params: {
// // 						id: idmedicao,
// // 						nomeCamada: camada,
// // 					},
// // 				})
// // 				.then((response) => {
// // 					//console.log(response.data);
// // 					setValoresDaCamada(response.data.dados);
// // 					//return response.data;
// // 				});
// // 		} catch (error) {
// // 			console.log("Erro ao apesquisa medição:", error);
// // 		}
// // 	}

// // 	useEffect(() => {
// // 		//fetchListaCamadas(props.medicao, "Camada de Ligação");
// // 	}, []);

// // 	const salvarInformacoes = () => {
// // 		console.log(rows.map((value) => value.valor));
// // 	};

// // 	const processRowUpdate = (newRow) => {
// // 		const updatedRow = { ...newRow, isNew: false };
// // 		setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
// // 		return updatedRow;
// // 	};

// // 	const verificaComponenteValores = (objeto) => {
// // 		setExibeValores(
// // 			<DataGrid
// // 				rows={retornaArrayComValoresEQuantidadeDeAmostras(objeto.valoresEntrada, objeto.numeroAmostras)}
// // 				columns={columns}
// // 				initialState={{
// // 					pagination: {
// // 						paginationModel: {
// // 							pageSize: 10,
// // 						},
// // 					},
// // 				}}
// // 				pageSizeOptions={[10]}
// // 				slots={{
// // 					toolbar: CustomToolbar,
// // 				}}
// // 				// checkboxSelection
// // 				//disableRowSelectionOnClick
// // 			/>
// // 		);
// // 	};

// // 	// return (
// // 	// 	<Box variant="h1" color={colors.grey[900]}>
// // 	// 		<Typography component={"span"} variant="h5" fontWeight="700" mb={1}>
// // 	// 			{props.dadosCamadaUnica[0].nomeSistemaPavimentacao}
// // 	// 		</Typography>

// // 	// 		<Grid container spacing={1}>
// // 	// 			<Grid item xs={12} lg={8}>
// // 	// 				{props.dadosCamadaUnica.map((value) => (
// // 	// 					<AccordionModel
// // 	// 						parametro={value.parametro}
// // 	// 						nomeSegmento={value.nomeSegmento}
// // 	// 						valorDeProjeto={value.valorProjeto}
// // 	// 						LI={value.limiteInferior}
// // 	// 						LS={value.limiteSuperior}
// // 	// 						quantidadeDeAmostras={value.quantidadeDeAmostras}
// // 	// 						handleVisible={verificaComponenteValores}
// // 	// 						valoresDeEntrada={preencherArrayDosValoresDeEntrada(value.valorEntrada.split(","), rows)}
// // 	// 					/>
// // 	// 				))}
// // 	// 			</Grid>
// // 	// 			<Grid item xs={12} lg={4}>
// // 	// 				<Box sx={{ width: "100%" }}>{exibeValores}</Box>
// // 	// 			</Grid>
// // 	// 		</Grid>
// // 	// 	</Box>
// // 	// );

// // 	if (props.dadosCamadaUnica.length === 0 || props.dadosCamadaUnica[0].nomeSistemaPavimentacao === null) {
// // 		return (
// // 			<NovoSistemaPavimentacao
// // 				medicao={props.medicao}
// // 				camada={2}
// // 				ensaio={props.ensaio}
// // 				idCamadaMedicao={props.dadosIdCamadaMedicaoIdCamada[1] || props.dadosIdCamadaMedicaoIdCamada}
// // 				efeitoInserirAtualizar={(e) => props.efeito(e)}
// // 			/>
// // 		);
// // 	} else {
// // 		return (
// // 			<Box variant="h1" color={colors.grey[900]}>
// // 				<Grid container spacing={1} display="flex" justifyContent="space-between" mb={1}>
// // 					<Grid item>
// // 						<Typography component={"span"} variant="h5" fontWeight="700" mb={1}>
// // 							{props.dadosCamadaUnica[0].nomeSistemaPavimentacao}
// // 						</Typography>
// // 					</Grid>
// // 					<Grid item>
// // 						<Button color="primary" variant="outlined" onClick={salvarInformacoes} startIcon={<AddCircleOutlineOutlinedIcon />}>
// // 							Salvar Informações
// // 						</Button>
// // 					</Grid>
// // 				</Grid>
// // 				<Grid container spacing={1}>
// // 					<Grid item xs={12} lg={8}>
// // 						<Box sx={{ width: "100%", maxHeight: "70vh", overflow: "auto" }} p={1}>
// // 							{montaArrayComUmaGranulometria(props.dadosCamadaUnica).map((value, index) => (
// // 								<AccordionModel
// // 									{...(value.parametro === "Granulometria"
// // 										? { dadosGranulometria: props.dadosCamadaUnica.filter((e) => e.parametro === "Granulometria") }
// // 										: "")}
// // 									key={index}
// // 									index={index}
// // 									sistemaPavimentacao={props.dadosCamadaUnica[0].nomeSistemaPavimentacao}
// // 									parametro={value.parametro}
// // 									nomeSegmento={value.nomeSegmento}
// // 									nomePeneira={value.nomePeneira}
// // 									valorDeProjeto={value.valorProjeto}
// // 									LI={value.limiteInferior}
// // 									LS={value.limiteSuperior}
// // 									quantidadeDeAmostras={value.quantidadeDeAmostras}
// // 									valoresDeEntrada={preencherArrayDosValoresDeEntrada(value.valorEntrada, rows)}
// // 									handleVisible={verificaComponenteValores}
// // 									fechaAccordion={() => setExibeValores(false)}
// // 									efeitoInserirAtualizar={(e) => props.efeito(e)}
// // 								/>
// // 							))}
// // 						</Box>
// // 					</Grid>
// // 					<Grid item xs={12} lg={4}>
// // 						<Box sx={{ width: "100%", height: 600 }} p={1}>
// // 							<Typography variant="h6" fontWeight="700">
// // 								{nomeParametro}
// // 							</Typography>
// // 							<DataGrid
// // 								rows={rows}
// // 								columns={columns}
// // 								slots={{
// // 									noRowsOverlay: CustomNoRowsOverlay,
// // 								}}
// // 								// initialState={{
// // 								// 	pagination: {
// // 								// 		paginationModel: {
// // 								// 			pageSize: 10,
// // 								// 		},
// // 								// 	},
// // 								// }}
// // 								//style={{ height: 700 }}
// // 								//pageSizeOptions={[10]}
// // 								processRowUpdate={processRowUpdate}
// // 								// checkboxSelection
// // 								//disableRowSelectionOnClick
// // 							/>
// // 						</Box>
// // 					</Grid>
// // 				</Grid>
// // 			</Box>
// // 		);
// // 	}
// // };

// import SaveIcon from "@mui/icons-material/Save";
// import { Box, Button, Typography, useTheme } from "@mui/material";
// import FormControl from "@mui/material/FormControl";
// import Grid from "@mui/material/Grid";
// import {
// 	DataGrid,
// 	GridToolbarContainer,
// 	GridToolbarExport,
// 	useGridApiRef,
// 	GridRowEditStopReasons,
// 	GridRowModes,
// 	GridCellEditStopReasons,
// } from "@mui/x-data-grid";
// import InputLabel from "@mui/material/InputLabel";
// import MenuItem from "@mui/material/MenuItem";
// import Select from "@mui/material/Select";
// import { useEffect, useState, useMemo, memo } from "react";
// import { tokens } from "../../../../theme";
// import AccordionModel from "../../../../components/Accordion";
// import {
// 	preencherArrayDosValoresDeEntrada,
// 	retornaArrayComValoresEQuantidadeDeAmostras,
// 	montaArrayComUmaGranulometria,
// 	retornaIdDaPeneira,
// 	hasEmptyOrZeroOrUndefined,
// 	hasNullOrEmpty,
// 	arrayTrechoDataValorEntrada,
// } from "../../../../aux/functions/functions";
// import NovoSistemaPavimentacao from "../../../../components/NovoSistemaPavimentacao";
// import axios from "axios";
// import Alert from "@mui/material/Alert";
// import Snackbar from "@mui/material/Snackbar";
// import { parametro } from "./../../../../data/sistemaPavimentacao/estrutura-material-parametro";
// import dayjs from "dayjs";
// const columns = [
// 	{
// 		field: "id",
// 		headerName: "ID",
// 		width: 60,
// 	},
// 	{
// 		field: "trecho",
// 		headerName: "Trecho",
// 		type: "number",
// 		width: 100,
// 		editable: true,
// 	},
// 	{
// 		field: "data",
// 		headerName: "Data",
// 		type: "date",
// 		width: 110,
// 		editable: true,
// 		valueGetter: (value) => value && new Date(dayjs(value)),
// 	},
// 	{
// 		field: "valor",
// 		headerName: "Valor",
// 		type: "number",
// 		width: 110,
// 		editable: true,
// 	},
// ];

// let rows = [
// 	// { id: 6, data: "", trecho: "", valor: "" },
// 	// { id: 7, data: "", trecho: "", valor: "" },
// 	// { id: 8, data: "", trecho: "", valor: "" },
// 	// { id: 9, data: "", trecho: "", valor: "" },
// 	// { id: 10, data: "", trecho: "", valor: "" },
// 	// { id: 11, data: "", trecho: "", valor: "" },
// 	// { id: 12, data: "", trecho: "", valor: "" },
// 	// { id: 13, data: "", trecho: "", valor: "" },
// 	// { id: 14, data: "", trecho: "", valor: "" },
// 	// { id: 15, data: "", trecho: "", valor: "" },
// ];

// //const peneiras = ["Peneira ASTM 1 1/2” (38,1 mm", "Peneira ASTM 1” (25,4 mm)", "Peneira ASTM 1” (25,4 mm)"];

// // function CustomToolbar() {
// // 	return (
// // 		<GridToolbarContainer>
// // 			<GridToolbarExport />
// // 		</GridToolbarContainer>
// // 	);
// // }

// function CustomNoRowsOverlay() {
// 	return (
// 		<>
// 			<Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", mt: 2 }}>Sem linhas</Box>
// 		</>
// 	);
// }

// // let teste = false;
// // let valoresExibbir = "";

// const Ligacao = (props) => {
// 	const theme = useTheme("light");
// 	const colors = tokens(theme.palette.mode);
// 	const [rows, setRows] = useState([]);
// 	const [exibeValores, setExibeValores] = useState();
// 	const [nomeParametro, setNomeParametro] = useState();
// 	const [valoresDaCamada, setValoresDaCamada] = useState([]);
// 	const [alertaSnackbar, setAlertaSnackbar] = useState();
// 	const [alertStatus, setAlertStatus] = useState("warning");
// 	const [atualiza, setAtualiza] = useState(false);
// 	const [idparametroValores, setIdparametroValores] = useState();

// 	async function fetchListaCamadas(idmedicao, camada) {
// 		try {
// 			await axios
// 				.get(`${process.env.REACT_APP_URL_BASE}/camada`, {
// 					params: {
// 						id: idmedicao,
// 						nomeCamada: camada,
// 					},
// 				})
// 				.then((response) => {
// 					//console.log(response.data);
// 					setValoresDaCamada(response.data.dados);
// 					//return response.data;
// 				});
// 		} catch (error) {
// 			console.log("Erro ao apesquisa medição:", error);
// 		}
// 	}

// 	function atualizaInfoCamada(value) {
// 		setAtualiza(value);
// 	}

// 	async function cadastraParametroValor(nomeSegmento, vp, li, ls, idPeneira, idCamadaMedicao, parametro) {
// 		try {
// 			await axios
// 				.post(`${process.env.REACT_APP_URL_BASE}/parametrovalor`, {
// 					nomeSegmento,
// 					vp,
// 					li,
// 					ls,
// 					idPeneira,
// 					idCamadaMedicao,
// 					parametro,
// 				})
// 				.then((response) => {
// 					//console.log(response.data);
// 					return true;
// 				});
// 		} catch (error) {
// 			console.log("Erro ao adicionar uma medição:", error);
// 			return false;
// 		}
// 	}

// 	async function cadastraValoresEntrada(idparametroValores, valoresEntrada) {
// 		try {
// 			await axios
// 				.post(`${process.env.REACT_APP_URL_BASE}/valoresEntrada`, {
// 					idparametroValores,
// 					valoresEntrada,
// 				})
// 				.then((response) => {
// 					console.log(response.data);
// 					return true;
// 				});
// 		} catch (error) {}
// 	}

// 	const salvarInformacoes = () => {
// 		//setRows([...rows, (rows.valor = 2)]);
// 		console.log(rows);
// 		if (rows.find((value) => hasEmptyOrZeroOrUndefined(value, ["data", "trecho"]))) {
// 			setAlertStatus("warning");
// 			setAlertaSnackbar("Preencher valores vazios ou zerados");
// 			handleClickSnackBar();
// 		} else {
// 			if (cadastraValoresEntrada(idparametroValores, rows)) {
// 				console.log(idparametroValores);
// 				setAlertStatus("success");
// 				setAlertaSnackbar("Salvo!");
// 				handleClickSnackBar();
// 			}
// 		}

// 		//console.log(rows.map((value) => value.valor));
// 	};

// 	const processRowUpdate = (newRow) => {
// 		const updatedRow = { ...newRow };
// 		//linhas = rows.find((value) => value.id === updatedRow.id);
// 		//linhas.push(updatedRow);
// 		//setRows(newRow);
// 		// setRows([...rows, (rows.valor = updatedRow.valor)]);
// 		//linhas = rows;
// 		// console.log(linhas);
// 		// console.log(newRow.id);

// 		rows[updatedRow.id - 1].data =
// 			updatedRow.data && updatedRow.data !== "" ? updatedRow.data.toLocaleDateString("en-CA") : "0000-00-00";
// 		rows[updatedRow.id - 1].trecho = updatedRow.trecho && updatedRow.trecho !== "" ? updatedRow.trecho : null;
// 		rows[updatedRow.id - 1].valor = updatedRow.valor && updatedRow.valor;
// 		//linhas[newRow.id].data = newRow.data;

// 		// setRows(
// 		// 	rows.map((row) =>
// 		// 		row.id === newRow.id
// 		// 			? {
// 		// 					id: updatedRow.id,
// 		// 					trecho: updatedRow.trecho,
// 		// 					data: updatedRow.data && updatedRow.data.toLocaleDateString("en-CA"),
// 		// 					valor: updatedRow.valor,
// 		// 			  }
// 		// 			: row
// 		// 	)
// 		// );
// 		return updatedRow;
// 	};

// 	const verificaComponenteValores = (objeto) => {
// 		const valoresEntrada = objeto?.valoresEntrada ?? "";
// 		const trechoEntrada = objeto?.trechoEntrada ?? "";
// 		const dataEntrada = objeto?.dataEntrada ?? "";
// 		const numeroAmostras = objeto?.numeroAmostras ?? "";
// 		//arrayTrechoDataValorEntrada(trechoEntrada, dataEntrada, valoresEntrada);

// 		//		console.log(arrayTrechoDataValorEntrada(trechoEntrada, dataEntrada, valoresEntrada, numeroAmostras));

// 		setRows(arrayTrechoDataValorEntrada(trechoEntrada, dataEntrada, valoresEntrada, numeroAmostras));

// 		setIdparametroValores(objeto?.idparametroValores);
// 	};

// 	const salvaOsDados = (objeto) => {
// 		console.log(objeto);
// 		//retornaIdDaPeneira(objeto.nomePeneira);
// 		if (hasNullOrEmpty(objeto, ["valoresEntrada"])) {
// 			setAlertStatus("warning");
// 			setAlertaSnackbar("Preencher campos vazios ou zerados");
// 			handleClickSnackBar();
// 		} else {
// 			let nomeSegmento = objeto.nomeSegmento;
// 			let vp = objeto.vp;
// 			let li = objeto.li;
// 			let ls = objeto.ls;
// 			let idPeneira = objeto.idPeneira;
// 			let idCamadaMedicao = props.dadosIdCamadaMedicaoIdCamada[1] || props.dadosIdCamadaMedicaoIdCamada;
// 			let param = parametro.findIndex((value) => value === objeto.parametro);
// 			let nomePeneira = objeto.nomePeneira || objeto.parametro;

// 			console.log(objeto);

// 			if (cadastraParametroValor(nomeSegmento, vp, li, ls, idPeneira, idCamadaMedicao, param)) {
// 				//setRows(retornaArrayComValoresEQuantidadeDeAmostras(objeto.valoresEntrada, objeto.numeroAmostras));
// 				setNomeParametro(objeto.parametro);
// 				setAlertStatus("success");
// 				setAlertaSnackbar(nomePeneira + " salvo!");
// 				handleClickSnackBar();
// 				//props.atualizaAccordionGeral(true);
// 				//props.efeito(true);
// 			} else {
// 				setAlertStatus("warning");
// 				setAlertaSnackbar("Erro, tente novamento mais tarde!");
// 				handleClickSnackBar();
// 			}
// 		}
// 	};

// 	const [openSnackBar, setOpenSnackBar] = useState(false);

// 	const handleClickSnackBar = () => {
// 		setOpenSnackBar(true);
// 	};

// 	const handleCloseSnackBar = (event, reason) => {
// 		if (reason === "clickaway") {
// 			return;
// 		}
// 		setOpenSnackBar(false);
// 	};

// 	useEffect(() => {
// 		fetchListaCamadas(props.medicao, "Camada de Ligação");
// 		setAtualiza(false);
// 	}, [props.medicao]);

// 	// const [expandedAll, setExpandedAll] = useState(false);

// 	// const [checked, setChecked] = useState(false);

// 	// const handleChangeSwitch = (event) => {
// 	// 	setChecked(event.target.checked);
// 	// 	expandedAll ? setExpandedAll(false) : setExpandedAll(true);
// 	// 	console.log(expandedAll);
// 	// };

// 	// const getCellValue = (rowId, field) => {
// 	// 	const cellValue = apiRef.current.getCellValues({ id: rowId, field });
// 	// 	return cellValue;
// 	// };

// 	// const camada = "Base";

// 	// const materiais = estruturaMaterial.filter((value) => value.estrutura === 2)[0].material;

// 	//const [exibeValores, setExibeValores] = useState(false);
// 	// const [exibeInfoCamada, setExibeInfoCamada] = useState();

// 	// const handleChange = (e) => {
// 	// 	setMaterial(e.target.value);
// 	// };

// 	// const handleCancelClick = (id) => () => {
// 	// 	setRowModesModel({
// 	// 		...rowModesModel,
// 	// 		[id]: { mode: GridRowModes.View, ignoreModifications: true },
// 	// 	});

// 	// 	const editedRow = rows.find((row) => row.id === id);
// 	// 	if (editedRow.isNew) {
// 	// 		setRows(rows.filter((row) => row.id !== id));
// 	// 	}
// 	// };

// 	// const handleRowEditStop = (params, event) => {
// 	// 	if (params.reason === GridRowEditStopReasons.rowFocusOut) {
// 	// 		event.defaultMuiPrevented = true;
// 	// 	}
// 	// };

// 	// const processRowUpdate = (newRow) => {
// 	// 	const updatedRow = { ...newRow, isNew: false };
// 	// 	setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
// 	// 	return updatedRow;
// 	// };

// 	// const handleRowModesModelChange = (newRowModesModel) => {
// 	// 	setRowModesModel(newRowModesModel);
// 	// };

// 	// const verificaComponenteValores = (objeto) => {
// 	// 	setRows(retornaArrayComValoresEQuantidadeDeAmostras(objeto.valoresEntrada, objeto.numeroAmostras));
// 	// 	setNomeParametro(objeto.parametro);
// 	// 	setNomePeneira(objeto.nomePeneira);
// 	// 	// setExibeValores(
// 	// 	// 	<DataGrid
// 	// 	// 		editMode="row"
// 	// 	// 		rows={rows}
// 	// 	// 		columns={columns}
// 	// 	// 		initialState={{
// 	// 	// 			pagination: {
// 	// 	// 				paginationModel: {
// 	// 	// 					pageSize: 20,
// 	// 	// 				},
// 	// 	// 			},
// 	// 	// 		}}
// 	// 	// 		pageSizeOptions={[20]}
// 	// 	// 		slots={{
// 	// 	// 			toolbar: CustomToolbar,
// 	// 	// 		}}
// 	// 	// 		rowModesModel={rowModesModel}
// 	// 	// 		onRowModesModelChange={handleRowModesModelChange}
// 	// 	// 		onRowEditStop={handleRowEditStop}
// 	// 	// 		processRowUpdate={processRowUpdate}
// 	// 	// 		// checkboxSelection
// 	// 	// 		//disableRowSelectionOnClick
// 	// 	// 	/>
// 	// 	// );
// 	// };
// 	// function atualizaInfoCamada(value) {
// 	// 	setAtualiza(value);
// 	// }

// 	// const salvarInformacoes = () => {
// 	// 	console.log(rows);
// 	// };

// 	if (props.dadosCamadaUnica.length === 0 || props.dadosCamadaUnica[0].nomeSistemaPavimentacao === null) {
// 		return (
// 			<NovoSistemaPavimentacao
// 				medicao={props.medicao}
// 				camada={3}
// 				ensaio={props.ensaio}
// 				idCamadaMedicao={props.dadosIdCamadaMedicaoIdCamada[1] || props.dadosIdCamadaMedicaoIdCamada}
// 				efeitoInserirAtualizar={(e) => props.efeito(e)}
// 			/>
// 		);
// 	} else {
// 		return (
// 			<Box variant="h1" color={colors.grey[900]}>
// 				<Grid container spacing={1} display="flex" justifyContent="space-between" mb={1}>
// 					<Grid item>
// 						<Typography component={"span"} variant="h5" fontWeight="700" mb={1}>
// 							{props.dadosCamadaUnica[0].nomeSistemaPavimentacao}
// 						</Typography>
// 					</Grid>
// 					<Grid item>
// 						<Button color="primary" variant="outlined" onClick={salvarInformacoes} startIcon={<SaveIcon />}>
// 							Salvar Informações
// 						</Button>
// 					</Grid>
// 				</Grid>
// 				<Grid container spacing={1}>
// 					<Grid item xs={12} lg={8}>
// 						<Box sx={{ width: "100%", maxHeight: "70vh", overflow: "auto" }} p={1}>
// 							{montaArrayComUmaGranulometria(valoresDaCamada).map((value, index) => (
// 								<AccordionModel
// 									{...(value.parametro === "Granulometria"
// 										? { dadosGranulometria: props.dadosCamadaUnica.filter((e) => e.parametro === "Granulometria") }
// 										: "")}
// 									key={index}
// 									index={index}
// 									sistemaPavimentacao={valoresDaCamada[0].nomeSistemaPavimentacao}
// 									parametro={value.parametro}
// 									nomeSegmento={value.nomeSegmento}
// 									nomePeneira={value.nomePeneira}
// 									valorDeProjeto={value.valorProjeto}
// 									LI={value.limiteInferior}
// 									LS={value.limiteSuperior}
// 									quantidadeDeAmostras={value.quantidadeDeAmostras}
// 									//valoresDeEntrada={preencherArrayDosValoresDeEntrada(value.valorEntrada, rows)}
// 									valoresDeEntrada={value.valorEntrada}
// 									trehoEntrada={value.trechoEntrada}
// 									dataEntrada={value.dataEntrada}
// 									handleVisible={verificaComponenteValores}
// 									handleSalva={salvaOsDados}
// 									fechaAccordion={() => setExibeValores(false)}
// 									efeitoInserirAtualizar={(e) => props.efeito(e)}
// 									atualizaInfoCamada={atualizaInfoCamada}
// 									idparametroValores={value.idparametroValores}
// 								/>
// 							))}
// 						</Box>
// 					</Grid>
// 					<Grid item xs={12} lg={4}>
// 						<Box sx={{ width: "100%", height: "70vh" }} p={1}>
// 							<Typography variant="h6" fontWeight="700">
// 								{nomeParametro}
// 							</Typography>
// 							<DataGrid
// 								rows={rows}
// 								columns={columns}
// 								slots={{
// 									noRowsOverlay: CustomNoRowsOverlay,
// 								}}
// 								// initialState={{
// 								// 	pagination: {
// 								// 		paginationModel: {
// 								// 			pageSize: 10,
// 								// 		},
// 								// 	},
// 								// }}
// 								//style={{ height: 700 }}
// 								//pageSizeOptions={[10]}
// 								processRowUpdate={processRowUpdate}
// 								// checkboxSelection
// 								//disableRowSelectionOnClick
// 							/>
// 						</Box>
// 					</Grid>
// 				</Grid>
// 				<Snackbar
// 					anchorOrigin={{ vertical: "top", horizontal: "center" }}
// 					open={openSnackBar}
// 					autoHideDuration={1500}
// 					onClose={handleCloseSnackBar}
// 				>
// 					<Alert onClose={handleCloseSnackBar} severity={alertStatus} sx={{ width: "100%" }}>
// 						{alertaSnackbar}
// 					</Alert>
// 				</Snackbar>
// 			</Box>
// 		);
// 	}
// };

// export default Ligacao;

import SaveIcon from "@mui/icons-material/Save";
import { Box, Button, Typography, useTheme } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import {
	DataGrid,
	GridToolbarContainer,
	GridToolbarExport,
	useGridApiRef,
	GridRowEditStopReasons,
	GridRowModes,
	GridCellEditStopReasons,
} from "@mui/x-data-grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useEffect, useState, useMemo, memo } from "react";
import { tokens } from "../../../../theme";
import AccordionModel from "../../../../components/Accordion";
import {
	preencherArrayDosValoresDeEntrada,
	retornaArrayComValoresEQuantidadeDeAmostras,
	montaArrayComUmaGranulometria,
	retornaIdDaPeneira,
	hasEmptyOrZeroOrUndefined,
	hasNullOrEmpty,
	arrayTrechoDataValorEntrada,
} from "../../../../aux/functions/functions";
import NovoSistemaPavimentacao from "../../../../components/NovoSistemaPavimentacao";
import axios from "axios";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { parametro, listaTodasAsPeneiras } from "./../../../../data/sistemaPavimentacao/estrutura-material-parametro";
import dayjs from "dayjs";
const columns = [
	{
		field: "id",
		headerName: "ID",
		width: 60,
	},
	{
		field: "trecho",
		headerName: "Trecho",
		type: "number",
		width: 100,
		editable: true,
	},
	{
		field: "data",
		headerName: "Data",
		type: "date",
		width: 110,
		editable: true,
		valueGetter: (value) => value && new Date(dayjs(value)),
	},
	{
		field: "valor",
		headerName: "Valor",
		type: "number",
		width: 110,
		editable: true,
	},
];

let rows = [
	// { id: 6, data: "", trecho: "", valor: "" },
	// { id: 7, data: "", trecho: "", valor: "" },
	// { id: 8, data: "", trecho: "", valor: "" },
	// { id: 9, data: "", trecho: "", valor: "" },
	// { id: 10, data: "", trecho: "", valor: "" },
	// { id: 11, data: "", trecho: "", valor: "" },
	// { id: 12, data: "", trecho: "", valor: "" },
	// { id: 13, data: "", trecho: "", valor: "" },
	// { id: 14, data: "", trecho: "", valor: "" },
	// { id: 15, data: "", trecho: "", valor: "" },
];

//const peneiras = ["Peneira ASTM 1 1/2” (38,1 mm", "Peneira ASTM 1” (25,4 mm)", "Peneira ASTM 1” (25,4 mm)"];

// function CustomToolbar() {
// 	return (
// 		<GridToolbarContainer>
// 			<GridToolbarExport />
// 		</GridToolbarContainer>
// 	);
// }

function CustomNoRowsOverlay() {
	return (
		<>
			<Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", mt: 2 }}>Sem linhas</Box>
		</>
	);
}

// let teste = false;
// let valoresExibbir = "";

const Ligacao = (props) => {
	const theme = useTheme("light");
	const colors = tokens(theme.palette.mode);
	const [rows, setRows] = useState([]);
	const [exibeValores, setExibeValores] = useState();
	const [nomeParametro, setNomeParametro] = useState();
	const [valoresDaCamada, setValoresDaCamada] = useState([]);
	const [alertaSnackbar, setAlertaSnackbar] = useState();
	const [alertStatus, setAlertStatus] = useState("warning");
	const [atualiza, setAtualiza] = useState(false);
	const [idparametroValores, setIdparametroValores] = useState();

	async function fetchListaCamadas(idmedicao, camada) {
		try {
			await axios
				.get(`${process.env.REACT_APP_URL_BASE}/camada`, {
					params: {
						id: idmedicao,
						nomeCamada: camada,
					},
				})
				.then((response) => {
					//console.log(response.data);
					setValoresDaCamada(response.data.dados);
					//return response.data;
				});
		} catch (error) {
			console.log("Erro ao apesquisa medição:", error);
		}
	}

	function atualizaInfoCamada(value) {
		setAtualiza(value);
	}

	async function cadastraParametroValor(nomeSegmento, vp, li, ls, idPeneira, idCamadaMedicao, parametro) {
		try {
			await axios
				.post(`${process.env.REACT_APP_URL_BASE}/parametrovalor`, {
					nomeSegmento,
					vp,
					li,
					ls,
					idPeneira,
					idCamadaMedicao,
					parametro,
				})
				.then((response) => {
					//console.log(response.data);
					return true;
				});
		} catch (error) {
			console.log("Erro ao adicionar uma medição:", error);
			return false;
		}
	}

	async function cadastraValoresEntrada(idparametroValores, valoresEntrada) {
		try {
			await axios
				.post(`${process.env.REACT_APP_URL_BASE}/valoresEntrada`, {
					idparametroValores,
					valoresEntrada,
				})
				.then((response) => {
					console.log(response.data);
					return true;
				});
		} catch (error) {}
	}

	const salvarInformacoes = () => {
		//setRows([...rows, (rows.valor = 2)]);
		console.log(rows);
		if (rows.find((value) => hasEmptyOrZeroOrUndefined(value, ["data", "trecho"])) || rows.length === 0) {
			setAlertStatus("warning");
			setAlertaSnackbar("Preencher valores vazios ou zerados");
			handleClickSnackBar();
		} else {
			if (cadastraValoresEntrada(idparametroValores, rows)) {
				console.log(idparametroValores);
				setAlertStatus("success");
				setAlertaSnackbar("Salvo!");
				handleClickSnackBar();
			}
		}

		//console.log(rows.map((value) => value.valor));
	};

	const processRowUpdate = (newRow) => {
		const updatedRow = { ...newRow };
		//linhas = rows.find((value) => value.id === updatedRow.id);
		//linhas.push(updatedRow);
		//setRows(newRow);
		// setRows([...rows, (rows.valor = updatedRow.valor)]);
		//linhas = rows;
		// console.log(linhas);
		// console.log(newRow.id);

		rows[updatedRow.id - 1].data =
			updatedRow.data && updatedRow.data !== "" ? updatedRow.data.toLocaleDateString("en-CA") : "0000-00-00";
		rows[updatedRow.id - 1].trecho = updatedRow.trecho && updatedRow.trecho !== "" ? updatedRow.trecho : null;
		rows[updatedRow.id - 1].valor = updatedRow.valor && updatedRow.valor;
		//linhas[newRow.id].data = newRow.data;

		// setRows(
		// 	rows.map((row) =>
		// 		row.id === newRow.id
		// 			? {
		// 					id: updatedRow.id,
		// 					trecho: updatedRow.trecho,
		// 					data: updatedRow.data && updatedRow.data.toLocaleDateString("en-CA"),
		// 					valor: updatedRow.valor,
		// 			  }
		// 			: row
		// 	)
		// );
		return updatedRow;
	};

	const verificaComponenteValores = (objeto) => {
		const valoresEntrada = objeto?.valoresEntrada ?? "";
		const trechoEntrada = objeto?.trechoEntrada ?? "";
		const dataEntrada = objeto?.dataEntrada ?? "";
		const numeroAmostras = objeto?.numeroAmostras ?? "";
		//arrayTrechoDataValorEntrada(trechoEntrada, dataEntrada, valoresEntrada);

		//		console.log(arrayTrechoDataValorEntrada(trechoEntrada, dataEntrada, valoresEntrada, numeroAmostras));

		if (objeto.idPeneira === 99) {
			console.log();
			setNomeParametro(objeto.parametro);
		} else {
			setNomeParametro(listaTodasAsPeneiras.find((value) => value.id === objeto.idPeneira).nome);
		}

		setRows(arrayTrechoDataValorEntrada(trechoEntrada, dataEntrada, valoresEntrada, numeroAmostras));

		setIdparametroValores(objeto?.idparametroValores);
	};

	const salvaOsDados = (objeto) => {
		console.log(objeto);
		//retornaIdDaPeneira(objeto.nomePeneira);
		if (hasNullOrEmpty(objeto, ["valoresEntrada"])) {
			setAlertStatus("warning");
			setAlertaSnackbar("Preencher campos vazios ou zerados");
			handleClickSnackBar();
		} else {
			let nomeSegmento = objeto.nomeSegmento;
			let vp = objeto.vp;
			let li = objeto.li;
			let ls = objeto.ls;
			let idPeneira = objeto.idPeneira;
			let idCamadaMedicao = props.dadosIdCamadaMedicaoIdCamada[1] || props.dadosIdCamadaMedicaoIdCamada;
			let param = parametro.findIndex((value) => value === objeto.parametro);
			let nomePeneira = objeto.nomePeneira || objeto.parametro;

			console.log(objeto);

			if (cadastraParametroValor(nomeSegmento, vp, li, ls, idPeneira, idCamadaMedicao, param)) {
				//setRows(retornaArrayComValoresEQuantidadeDeAmostras(objeto.valoresEntrada, objeto.numeroAmostras));
				setNomeParametro(objeto.parametro);
				setAlertStatus("success");
				setAlertaSnackbar(nomePeneira + " salvo!");
				handleClickSnackBar();
				//props.atualizaAccordionGeral(true);
				//props.efeito(true);
			} else {
				setAlertStatus("warning");
				setAlertaSnackbar("Erro, tente novamento mais tarde!");
				handleClickSnackBar();
			}
		}
	};

	const [openSnackBar, setOpenSnackBar] = useState(false);

	const handleClickSnackBar = () => {
		setOpenSnackBar(true);
	};

	const handleCloseSnackBar = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}
		setOpenSnackBar(false);
	};

	useEffect(() => {
		fetchListaCamadas(props.medicao, "Camada de Ligação");
		setAtualiza(false);
	}, [props.medicao]);

	if (props.dadosCamadaUnica.length === 0 || props.dadosCamadaUnica[0].nomeSistemaPavimentacao === null) {
		return (
			<NovoSistemaPavimentacao
				medicao={props.medicao}
				camada={2}
				ensaio={props.ensaio}
				idCamadaMedicao={props.dadosIdCamadaMedicaoIdCamada[1] || props.dadosIdCamadaMedicaoIdCamada}
				efeitoInserirAtualizar={(e) => props.efeito(e)}
			/>
		);
	} else {
		return (
			<Box variant="h1" color={colors.grey[900]}>
				<Grid container spacing={1} display="flex" justifyContent="space-between" mb={1}>
					<Grid item>
						<Typography component={"span"} variant="h5" fontWeight="700" mb={1}>
							{props.dadosCamadaUnica[0].nomeSistemaPavimentacao}
						</Typography>
					</Grid>
					<Grid item>
						<Button color="primary" variant="outlined" onClick={salvarInformacoes} startIcon={<SaveIcon />}>
							Salvar Informações
						</Button>
					</Grid>
				</Grid>
				<Grid container spacing={1}>
					<Grid item xs={12} lg={8}>
						<Box sx={{ width: "100%", maxHeight: "70vh", overflow: "auto" }} p={1}>
							{montaArrayComUmaGranulometria(valoresDaCamada).map((value, index) => (
								<AccordionModel
									{...(value.parametro === "Granulometria"
										? { dadosGranulometria: valoresDaCamada.filter((e) => e.parametro === "Granulometria") }
										: "")}
									key={index}
									index={index}
									sistemaPavimentacao={valoresDaCamada[0].nomeSistemaPavimentacao}
									parametro={value.parametro}
									nomeSegmento={value.nomeSegmento}
									nomePeneira={value.nomePeneira}
									valorDeProjeto={value.valorProjeto}
									LI={value.limiteInferior}
									LS={value.limiteSuperior}
									quantidadeDeAmostras={value.quantidadeDeAmostras}
									//valoresDeEntrada={preencherArrayDosValoresDeEntrada(value.valorEntrada, rows)}
									valoresDeEntrada={value.valorEntrada}
									trechoEntrada={value.trechoEntrada}
									dataEntrada={value.dataEntrada}
									handleVisible={verificaComponenteValores}
									handleSalva={salvaOsDados}
									fechaAccordion={() => setExibeValores(false)}
									efeitoInserirAtualizar={(e) => props.efeito(e)}
									atualizaInfoCamada={atualizaInfoCamada}
									idparametroValores={value.idparametroValores}
								/>
							))}
						</Box>
					</Grid>
					<Grid item xs={12} lg={4}>
						<Box sx={{ width: "100%", height: "70vh" }} p={1}>
							<Typography variant="h6" fontWeight="700">
								{nomeParametro}
							</Typography>
							<DataGrid
								rows={rows}
								columns={columns}
								slots={{
									noRowsOverlay: CustomNoRowsOverlay,
								}}
								// initialState={{
								// 	pagination: {
								// 		paginationModel: {
								// 			pageSize: 10,
								// 		},
								// 	},
								// }}
								//style={{ height: 700 }}
								//pageSizeOptions={[10]}
								processRowUpdate={processRowUpdate}
								// checkboxSelection
								//disableRowSelectionOnClick
							/>
						</Box>
					</Grid>
				</Grid>
				<Snackbar
					anchorOrigin={{ vertical: "top", horizontal: "center" }}
					open={openSnackBar}
					autoHideDuration={1500}
					onClose={handleCloseSnackBar}
				>
					<Alert onClose={handleCloseSnackBar} severity={alertStatus} sx={{ width: "100%" }}>
						{alertaSnackbar}
					</Alert>
				</Snackbar>
			</Box>
		);
	}
};

export default Ligacao;
