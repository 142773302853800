import { Box, Typography, useTheme, Grid, Button } from "@mui/material";
import { tokens } from "../theme";
import {
	estrutura,
	materialListagem,
	parametro,
	estruturaMaterial,
	materialParametro,
} from "../data/sistemaPavimentacao/estrutura-material-parametro";
import { useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import AddCircleOutlineOutlined from "@mui/icons-material/AddCircleOutlineOutlined";
import Alert from "@mui/material/Alert";
import axios from "axios";

const NovoSistemaPavimentacao = (props) => {
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);
	const [material, setMaterial] = useState("");
	const [mensagemErro, setMensagemErro] = useState("");
	const [mensagemErroMaterial, setMensagemErroMaterial] = useState(false);

	const [ack, setACK] = useState("");

	const handleChange = (e) => {
		setMaterial(e.target.value);
	};

	async function inseriEnsaioMedicaoSistPavimentacao(e) {
		e.preventDefault();

		if (material === "") {
			setMensagemErroMaterial(true);
			setMensagemErro("Selecionar algum material");
		} else {
			setMensagemErroMaterial(false);
			setMensagemErro("");
			try {
				const ensaio = props.ensaio;
				const camada = props.camada;
				const parametro = arrayParametros(material).parametro;
				const idCamamdaMedicao = props.idCamadaMedicao;

				const arrayIdCamadaMedicaoParametro = (arrayIdParametro, idCamadaMedicao) => {
					let a = [];
					arrayIdParametro.map((value) => a.push("(" + idCamadaMedicao + "," + value + ")"));
					return a;
				};

				// let arrayInseirParametros = []
				// parametro.map((value) => {
				// 	arrayInseirParametros.push('(' +  , + ')')
				// })

				axios
					.post(`${process.env.REACT_APP_URL_BASE}/sistemapavimentacao/`, {
						ensaio,
						camada,
						material,
						parametro,
						idCamamdaMedicao,
					})
					.then((response) => {
						response && props.efeitoInserirAtualizar(true);
					});

				//fetchCadastraCamadaMedicao();
			} catch (error) {
				console.log("Erro ao adicionar um material:", error);
			} finally {
			}
		}
	}

	const arrayDeMateriais = estruturaMaterial.find((value) => {
		if (value.estrutura === props.camada) return value.material;
		else return "";
	});

	const arrayParametros = (inputMaterial) =>
		materialParametro.find((value) => {
			if (value.material === inputMaterial) return value.parametro;
			else return "";
		});

	return (
		<Box m={1} variant="h6" color={colors.grey[900]}>
			<Typography component={"span"} variant="h4" fontWeight="700">
				Escolha o material
			</Typography>
			{mensagemErro ? (
				<Box my={1}>
					<Alert severity="error">{mensagemErro}</Alert>
				</Box>
			) : (
				""
			)}
			<form onSubmit={inseriEnsaioMedicaoSistPavimentacao}>
				<Grid container my={1} spacing={1}>
					<Grid item xs={12} lg={4}>
						<FormControl fullWidth size="small" {...(mensagemErroMaterial ? { error: "error" } : "")}>
							<InputLabel id="demo-simple-select-label">Material</InputLabel>
							<Select
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								value={material}
								label="Material"
								onChange={handleChange}
							>
								<MenuItem value=""></MenuItem>
								{arrayDeMateriais.material.map((value, index) => (
									<MenuItem value={value}>{materialListagem[value]} </MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid>
					<Grid item xs={12} lg={3}>
						<Button color="primary" variant="outlined" startIcon={<AddCircleOutlineOutlined />} size="large" type="submit">
							Adicionar material
						</Button>
					</Grid>
				</Grid>
			</form>
		</Box>
	);
};

export default NovoSistemaPavimentacao;
