import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Box, Button, Grid, IconButton, useTheme } from "@mui/material";
import Tab from "@mui/material/Tab";
import { useEffect, useState, useMemo, memo, useCallback } from "react";
import DialogCamadas from "../../../components/DialogCamadas";
import Header from "../../../components/Header";
import { tokens } from "../../../theme";
import CamadaSelecionada from "./camadas/index";
import { connect } from "react-redux";
import { alterarArrayCamadas } from "../../../store/actions/camadas";
import { alterarInfoMedicoes } from "../../../store/actions/medicoes";
import { alterarCamadaMedicaoParametro } from "../../../store/actions/camadaMedicaoParametro";
import axios from "axios";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";
import { Suspense } from "react";
import _ from "lodash";

function Loading() {
	return <h2>🌀 Carregando...</h2>;
}

function ordenaCamdas(arr) {
	var order = ["Camada de Rolamento", "Camada de Ligação", "Base", "Sub-base", "Reforço do Subleito", "Subleito"];
	_.sortBy(arr, function (obj) {
		return _.indexOf(order, obj.key);
	});
}

const Medicao = (props) => {
	const [obs, setObs] = useState([]);
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);
	let { state } = useLocation();
	const [value, setValue] = useState("1");
	const [open, setOpen] = useState(false);
	//const [dados, setDados] = useState("1");
	const [listaTodosParametros, setListaTodosParametros] = useState();
	const [arrayIdCamdaMedicaoIdCamda, setArrayIdCamadaMedicaoIdCamdada] = useState();
	const [efeitoINSATU, setEfeitoINSATU] = useState(false);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const atualizaTudo = (value) => {
		setEfeitoINSATU(value);
	};

	const salvarInformacoes = () => {};

	function onlyUnique(value, index, array) {
		return array.indexOf(value) === index;
	}

	useEffect(() => {
		props.alteraArrayCamada([]);
		//console.log(props.infoMedicao.map((value) => value.idcamadaMedicao));

		fetchListaCamadas();
		setEfeitoINSATU(false);
	}, [efeitoINSATU]);

	const fetchListaCamadas = async () => {
		try {
			const response = await axios.get(`${process.env.REACT_APP_URL_BASE}/parametros/${state.idMedicao}`);
			if (response.data.camadas[0] !== null) {
				props.alteraArrayCamada(response.data.camadas);
			} else {
				props.alteraArrayCamada([]);
			}
			props.getInfoDasMedicoes(response.data.dados);
		} catch (error) {
			console.log("Erro ao acessar a medição:", error);
		}
	};

	const medicaoData = Array.isArray(props.infoMedicao) ? props.infoMedicao : [];

	return (
		<>
			<Box mx={1} variant="h6" color={colors.grey[900]}>
				<Header
					title={state.nomeProjeto || ""}
					subtitle={state.nomeMedicao || ""}
					secondsubtitle={state.trechoMedicao || ""}
					thirdsubtitle={dayjs(state.dataMedicao).format("DD/MM/YYYY")}
				/>
				<Suspense fallback={<Loading />}>
					<Box sx={{ width: "100%", typography: "body1" }}>
						<TabContext value={value}>
							<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
								<Grid container spacing={1}>
									<Grid item xs={12} lg={10} m={0} p={0} display="flex" alignItems="center">
										<TabList
											onChange={handleChange}
											aria-label="lab API tabs example"
											textColor="primary"
											indicatorColor="secondary"
										>
											{props.camadasSelecionadas.map((value, index) => (
												<Tab label={value} value={(index + 1).toString()} key={index} />
											))}
										</TabList>
										<IconButton onClick={handleClickOpen}>
											<AddCircleOutlineOutlinedIcon />
										</IconButton>
									</Grid>
									{/* <Grid item xs={12} lg={2}>
										<Button
											color="primary"
											variant="outlined"
											startIcon={<AddCircleOutlineOutlinedIcon />}
											onClick={salvarInformacoes}
										>
											Salvar Informações
										</Button>
									</Grid> */}
								</Grid>
							</Box>
							{props.camadasSelecionadas.map((value, index) => (
								<TabPanel value={(index + 1).toString()} key={index}>
									<CamadaSelecionada
										camada={value}
										dados={props.infoMedicao}
										idmedicao={state.idMedicao}
										infoMedicao
										idensaio={state.idEnsaio}
										arrayDados={obs}
										efeitoAtualizar={atualizaTudo}
										//arrayDados={props.idCamadaIdParametro || obs}
									/>
								</TabPanel>
							))}
						</TabContext>
					</Box>
				</Suspense>
			</Box>
			{/* {console.log("ICP: ", props.idCamadaIdParametro)}
			{console.log("Dados: ", props.infoMedicao)} */}
			<DialogCamadas
				open={open}
				onClose={handleClose}
				camadas={["Camada de Rolamento", "Camada de Ligação", "Base", "Sub-base", "Reforço do Subleito", "Subleito"]}
				idensaio={state.idEnsaio}
				cmp={(e) => setObs(e)}
				efeitoAtualizar={atualizaTudo}
				arrayIdCamadaMedicao={medicaoData
					.map((value) => value.idcamadaMedicao)
					.filter((value, index, array) => array.indexOf(value) === index)}
			/>
		</>
	);
};

function mapStateToProps(state) {
	return {
		camadasSelecionadas: state.camadasSelecionadas,
		infoMedicao: state.infoMedicao,
		idCamadaIdParametro: state.camadaMedicaoParametro,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		alteraArrayCamada(array) {
			dispatch(alterarArrayCamadas(array));
		},
		getInfoDasMedicoes(array) {
			dispatch(alterarInfoMedicoes(array));
		},
		alterarIdCamadaParametro(array) {
			dispatch(alterarCamadaMedicaoParametro(array));
		},
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Medicao);
