import { INFO_PROJETO_ALTERADA } from "../actions/actionTypes";

const initialState = { nomeProjeto: "", dataInicialPrevia: null, dataFinalPrevia: null, valorContrato: 0, valorAditivo: 0 };

function infoProjetoReducer(state = initialState, action) {
	switch (action.type) {
		case INFO_PROJETO_ALTERADA:
			state = action.payload;
			// state = {
			// 	nome: action.payload.nome,
			// 	dataInicialPrevia: action.payload.dataInicialPrevia,
			// 	dataFinalPrevia: action.payload.dataFinalPrevia,
			// 	valorContrato: action.payload.valorContrato,
			// 	valorAditivo: action.payload.valorAditivo,
			// };
			return state;
		default:
			return state;
	}
}

export default infoProjetoReducer;
