import { Box, Chip } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

const MultipleSelectCheckmarksPeneiras = (props) => {
	const [nomePeneiras, setNomePeneiras] = useState(props.valuesSelect);
	const todasAsPeneiras = props.values.map((e) => e.nome);

	const handleChange = (event) => {
		const {
			target: { value },
		} = event;
		setNomePeneiras(typeof value === "string" ? value.split(",") : value);
	};

	useEffect(() => {
		props.valoresPeneira(nomePeneiras);
	}, [nomePeneiras]);

	if (typeof props.getFromChild === "function") {
		props.getFromChild(nomePeneiras);
	}

	return (
		<>
			<FormControl size="small">
				<InputLabel id="multi-select-camadas">{props.name}</InputLabel>
				<Select
					labelId="multi-select-camadas"
					id="multi-select-camadas"
					multiple
					value={nomePeneiras}
					onChange={handleChange}
					input={<OutlinedInput label={props.name} />}
					width="100%"
					sx={{
						width: props.width,
						minHeight: props.height,
					}}
					renderValue={(selected) => (
						<Box
							sx={{
								display: "flex",
								margin: 0,
								padding: 0,
								gap: 1,
								flexWrap: "wrap",
							}}
						>
							{selected.map((value) => (
								<Chip key={value} label={value} size="small" />
							))}
						</Box>
					)}
				>
					{todasAsPeneiras.map((name, index) => (
						<MenuItem key={index} value={name}>
							{name}
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</>
	);
};

function mapStateToProps(state) {
	return { camadasSelecionadas: state.camadasSelecionadas };
}

export default connect(mapStateToProps)(MultipleSelectCheckmarksPeneiras);
