// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html,
body,
#root,
.app,
.content {
	height: 100vh;
	width: 100vw;
	font-family: "Source Sans Pro", sans-serif;
	overflow: auto;
	margin: 0px;
}

.app {
	display: flex;
	position: relative;
}

::-webkit-scrollbar {
	width: 10px;
}

Track ::-webkit-scrollbar-track {
	background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #555;
}

.leaflet-container {
	height: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAEA;;;;;CAKC,aAAa;CACb,YAAY;CACZ,0CAA0C;CAC1C,cAAc;CACd,WAAW;AACZ;;AAEA;CACC,aAAa;CACb,kBAAkB;AACnB;;AAEA;CACC,WAAW;AACZ;;AAEA;CACC,mBAAmB;AACpB;;AAEA,WAAW;AACX;CACC,gBAAgB;AACjB;;AAEA,oBAAoB;AACpB;CACC,gBAAgB;AACjB;;AAEA;CACC,YAAY;AACb","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap\");\n\nhtml,\nbody,\n#root,\n.app,\n.content {\n\theight: 100vh;\n\twidth: 100vw;\n\tfont-family: \"Source Sans Pro\", sans-serif;\n\toverflow: auto;\n\tmargin: 0px;\n}\n\n.app {\n\tdisplay: flex;\n\tposition: relative;\n}\n\n::-webkit-scrollbar {\n\twidth: 10px;\n}\n\nTrack ::-webkit-scrollbar-track {\n\tbackground: #e0e0e0;\n}\n\n/* Handle */\n::-webkit-scrollbar-thumb {\n\tbackground: #888;\n}\n\n/* Handle on hover */\n::-webkit-scrollbar-thumb:hover {\n\tbackground: #555;\n}\n\n.leaflet-container {\n\theight: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
