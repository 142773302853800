import { INFO_MEDICAOPARAMETRO } from "./actionTypes";

function alterarCamadaMedicaoParametro(infoCamadaParametro) {
	return {
		type: INFO_MEDICAOPARAMETRO,
		payload: infoCamadaParametro,
	};
}

export { alterarCamadaMedicaoParametro };
