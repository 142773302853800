export const estrutura = ["", "Camada de Rolamento", "Camada de Ligação", "Base", "Sub-base", "Reforço do Subleito", "Subleito"];
export const materialListagem = [
	"",
	"Concreto Betuminoso Usinado A Quente (CBUQ) - Faixa A",
	"Concreto Betuminoso Usinado A Quente (CBUQ) - Faixa B",
	"Concreto Betuminoso Usinado A Quente (CBUQ) - Faixa C",
	"Microrrevestimento Asfáltico À Frio - Faixa I",
	"Microrrevestimento Asfáltico À Frio - Faixa II",
	"Microrrevestimento Asfáltico À Frio - Faixa III",
	"Tratamento Superficial Por Penetração - TSS - Faixa A",
	"Tratamento Superficial Por Penetração - TSS - Faixa B",
	"Tratamento Superficial Por Penetração - TSD - Faixa A",
	"Tratamento Superficial Por Penetração - TSD - Faixa B",
	"Tratamento Superficial Por Penetração - TSD - Faixa C",
	"Tratamento Superficial Por Penetração - TST - Faixa A",
	"Tratamento Superficial Por Penetração - TST - Faixa B",
	"Tratamento Superficial Por Penetração - TST - Faixa C",
	"Solo-Cimento",
	"Solo I",
	"Solo II",
	"Camadas Granulares",
	"Fundação",
];

export const parametro = [
	"",
	"Granulometria",
	"Teor de Betume",
	"Grau de Compactação",
	"Volume de Vazios",
	"Condição de Conforto",
	"Condição de Segurança Microtextura",
	"Condição de Segurança Macrotextura",
	"Conforto QI",
	"Segurança VDR",
	"Taxa de Aplicação da Mistura Asfáltica (kg/m²)",
	"Taxa de Aplicação de Ligante",
	"Taxa de Aplicação de Agregado",
	"Taxa de Emulsão Asfáltica (l/m²)",
	"Comforto QI (Irregularidade Longitudinal)",
	"Expansão",
	"Teor de Umidade",
	"Índice de Suporte Califórnia (CBR)",
	"Teor de Cimento",
	"Resistência à Compressão Simples",
	"Ligante Asfáltico Residual",
];

export const listaTodasAsPeneiras = [
	{ id: 1, nome: "Peneira ASTM 2” (50,8 mm)" },
	{ id: 2, nome: "Peneira ASTM 1 1/2” (38,1 mm)" },
	{ id: 3, nome: "Peneira ASTM 1” (25,4 mm)" },
	{ id: 4, nome: 'Peneira ASTM 3/4" (19,1 mm)' },
	{ id: 5, nome: 'Peneira ASTM 1/2" (12,7 mm)' },
	{ id: 6, nome: "Peneira ASTM 3/8” (9,5 mm)" },
	{ id: 7, nome: 'Peneira ASTM 1/4" (6,3 mm)' },
	{ id: 8, nome: "Peneira ASTM N° 4 (4,75 mm)" },
	{ id: 9, nome: "Peneira ASTM N° 8 (2,36 mm)" },
	{ id: 10, nome: "Peneira ASTM N° 10 (2,0 mm)" },
	{ id: 11, nome: "Peneira ASTM N° 16 (1,18 mm)" },
	{ id: 12, nome: "Peneira ASTM N° 30 (0,6 mm)" },
	{ id: 13, nome: "Peneira ASTM N° 40 (0,425 mm)" },
	{ id: 14, nome: "Peneira ASTM N° 50 (0,33 mm)" },
	{ id: 15, nome: "Peneira ASTM N° 80 (0,18 mm)" },
	{ id: 16, nome: "Peneira ASTM N° 100 (0,15 mm)" },
	{ id: 17, nome: "Peneira ASTM N° 200 (0,075 mm)" },
];

export const estruturaMaterial = [
	{ estrutura: 1, material: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14] },
	{ estrutura: 2, material: [1, 2] },
	{ estrutura: 3, material: [15, 16] },
	{ estrutura: 4, material: [16] },
	{ estrutura: 5, material: [17] },
	{ estrutura: 6, material: [18] },
];

export const materialParametro = [
	{ material: 1, parametro: [1, 6, 5, 12, 13, 14] },
	{ material: 2, parametro: [1, 6, 5, 12, 13, 14] },
	{ material: 3, parametro: [1, 6, 5, 12, 13, 14] },
	{ material: 4, parametro: [1, 8, 9, 10, 11, 12, 20] },
	{ material: 5, parametro: [1, 8, 9, 10, 11, 12, 20] },
	{ material: 6, parametro: [1, 8, 9, 10, 11, 12, 20] },
	{ material: 7, parametro: [1, 6, 7, 12, 13, 14] },
	{ material: 8, parametro: [1, 6, 7, 12, 13, 14] },
	{ material: 9, parametro: [1, 6, 7, 12, 13, 14] },
	{ material: 10, parametro: [1, 6, 7, 12, 13, 14] },
	{ material: 11, parametro: [1, 6, 7, 12, 13, 14] },
	{ material: 12, parametro: [1, 6, 7, 12, 13, 14] },
	{ material: 13, parametro: [1, 6, 7, 12, 13, 14] },
	{ material: 14, parametro: [1, 6, 7, 12, 13, 14] },
	{ material: 15, parametro: [1, 3, 16, 18, 19] },
	{ material: 16, parametro: [1, 3, 16, 18, 19] },
	{ material: 17, parametro: [3, 15, 16, 17] },
	{ material: 18, parametro: [3, 15, 16, 17] },
];
