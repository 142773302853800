import { useState } from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import FilledInput from "@mui/material/FilledInput";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Button, Container, Grid, Typography, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import { tokens } from "./../../theme";
import Logo from "../../assets/logo2.png";

async function loginUser(credentials) {
	return fetch(`${process.env.REACT_APP_URL_BASE}/login`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify(credentials),
	}).then((data) => data.json());
}

function Login({ setToken }) {
	const theme = useTheme("light");
	const colors = tokens(theme.palette.mode);
	const [name, setName] = useState("");
	const [password, setPassword] = useState("");

	const [showPassword, setShowPassword] = useState(false);

	const handleClickShowPassword = () => setShowPassword((show) => !show);

	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

	const handleMouseUpPassword = (event) => {
		event.preventDefault();
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		const token = await loginUser({
			name,
			password,
		});
		setToken(token);
	};

	return (
		<>
			<div
				style={{
					position: "relative",
					background: "linear-gradient(90deg, rgba(2,0,0,0.8) 10%, rgba(30, 82, 69, 0.8) 80%, rgba(255,255,255,.3) 100%)",
					height: "100vh",
				}}
			>
				<Container sx={{ height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
					<Box
						p={3}
						sx={{ border: 1, width: "30%", backgroundColor: "white", borderRadius: 3 }}
						variant="h1"
						color={colors.grey[900]}
					>
						<Grid container spacing={3}>
							<Grid item xl={12} display="flex" justifyContent="center">
								<Box
									component="img"
									sx={{
										height: 100,
										width: 300,
										maxHeight: { xs: 50, md: 100 },
										maxWidth: { xs: 100, md: 200 },
									}}
									alt="The house from the offer."
									src={Logo}
								/>
								<Typography variant="h3"></Typography>
							</Grid>
							<Grid item xl={12} display="flex" justifyContent="center">
								<TextField
									id="outlined-controlled"
									label="E-mail"
									value={name}
									onChange={(event) => {
										setName(event.target.value);
									}}
									sx={{ width: "100%" }}
								/>
							</Grid>
							<Grid item xl={12} display="flex" justifyContent="center">
								<FormControl variant="outlined" sx={{ width: "100%" }}>
									<InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
									<OutlinedInput
										id="outlined-adornment-password"
										type={showPassword ? "text" : "password"}
										endAdornment={
											<InputAdornment position="end">
												<IconButton
													aria-label={showPassword ? "hide the password" : "display the password"}
													onClick={handleClickShowPassword}
													onMouseDown={handleMouseDownPassword}
													onMouseUp={handleMouseUpPassword}
													edge="end"
												>
													{showPassword ? <VisibilityOff /> : <Visibility />}
												</IconButton>
											</InputAdornment>
										}
										label="Password"
										onChange={(event) => {
											setPassword(event.target.value);
										}}
									/>
								</FormControl>
							</Grid>
							<Grid item xl={12} display="flex" justifyContent="center">
								<Button color="secondary" variant="outlined" onClick={handleSubmit}>
									Entrar
								</Button>
							</Grid>
						</Grid>
					</Box>
				</Container>
			</div>
		</>
	);
}

Login.propTypes = {
	setToken: PropTypes.func.isRequired,
};

export default Login;
