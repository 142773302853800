import { CssBaseline, ThemeProvider } from "@mui/material";
import { useState } from "react";
import { Route, Routes } from "react-router-dom";
import Bar from "./scenes/bar";
import Calendar from "./scenes/calendar/calendar";
import Dashboard from "./scenes/dashboard";
import FAQ from "./scenes/faq";
import Form from "./scenes/form";
import Geography from "./scenes/geography";
import Sidebar from "./scenes/global/Sidebar";
import Topbar from "./scenes/global/Topbar";
import Invoices from "./scenes/invoices";
import Line from "./scenes/line";
import Medicoes from "./scenes/medicoes";
import Medicao from "./scenes/medicoes/medicao";
import Pie from "./scenes/pie";
import Projetos from "./scenes/projetos";
import ProjetoCE060 from "./scenes/projetos/projeto-CE-060";
import { useMode } from "./theme";
import { QueryClient, QueryClientProvider, useQuery } from "@tanstack/react-query";
import { StrictMode } from "react";
import Login from "./scenes/global/Login";
import useToken from "./auth/useToken";

const queryClient = new QueryClient();

function App() {
	const [theme] = useMode();
	const [isSidebar, setIsSidebar] = useState(true);
	const { token, setToken } = useToken();
	//const [token, setToken] = useState();

	if (!token) {
		return <Login setToken={setToken} />;
	}

	return (
		// <ColorModeContext.Provider value={colorMode}>
		//<QueryClientProvider client={queryClient}>
		// <StrictMode>

		<ThemeProvider theme={theme}>
			<CssBaseline />
			<div className="app">
				<Sidebar isSidebar={isSidebar} />
				<main className="content">
					<Topbar setIsSidebar={setIsSidebar} />
					<Routes>
						<Route path="/" element={<Dashboard />} />
						<Route path="/projetos" element={<Projetos />} />
						<Route path="/medicoes" element={<Medicoes />} />
						<Route path="/medicoes/medicao" element={<Medicao />} />
						<Route path="/invoices" element={<Invoices />} />
						<Route path="/form" element={<Form />} />
						<Route path="/bar" element={<Bar />} />
						<Route path="/pie" element={<Pie />} />
						<Route path="/line" element={<Line />} />
						<Route path="/faq" element={<FAQ />} />
						<Route path="/calendar" element={<Calendar />} />
						<Route path="/geography" element={<Geography />} />
						<Route path="/projetos/projeto-CE-060" element={<ProjetoCE060 />} />
					</Routes>
				</main>
			</div>
		</ThemeProvider>
		//</StrictMode>
		//</QueryClientProvider>
		//</ColorModeContext.Provider>
	);
}

export default App;
